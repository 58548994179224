import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { first, tap, exhaustMap, map, switchMap } from 'rxjs/operators';
import { Token } from 'src/app/entities/Access/Token';
import { User } from 'src/app/entities/User';
import { EditorRegistrationPayload, MasterRegistrationPayload, RestUser } from 'src/app/provider/rest/rest.user';
import { UserRepository } from 'src/app/repositories/user.repository';
import { StorageService } from 'src/app/services/local-persistance/storage.service';
import { MasterOnly, Unauthorized } from 'src/app/services/security/exceptions/security.error';
import { RoleAccessLevel } from 'src/app/services/security/role.accesslevel';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';
import { AbstractStateManager } from './abstract.state.manager';

/**
 * just a helper class to persist name in db
 */
export class AuthenticatedUser { }

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedUserManager extends AbstractStateManager<User>{

  private syncInterval = 3600; // once per hour

  constructor(
    protected readonly platform: Platform,
    private readonly remoteUserProvider: RestUser
  ) {
    super(platform);
  }

  static setUpdatedRefreshTokens(user: User, accessToken: Token, refreshToken?: Token) {
    let currentUser: User;
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(currUSer => {
        currentUser = currUSer;
        if (currUSer.uuid === user.uuid) {
          user.accessToken = accessToken;
          user.refreshToken = refreshToken ?? currentUser.refreshToken;
          return of(user);
        }
        return throwError(new Error('tried updating tokens of user other than authenticated User'));
      }),
      switchMap(newUser => {
        if (AppStateStore.dbReady.getValue()) {
          accessToken.save()
            .then(aT => {
              newUser.accessToken = aT;
              if (isDefined(refreshToken)) {
                return refreshToken.save();
              } else {
                return Promise.resolve(currentUser.refreshToken);
              }
            })
            .then(rT => {
              newUser.refreshToken = rT;
              newUser.save();
            });
        }
        AppStateStore.user.next(newUser);
        return from(
          StorageService.storeUser({ dId: newUser.dId, accessToken: newUser.accessToken, refreshToken: newUser.refreshToken })
        ).pipe(
          switchMap(() => of(newUser))
        );
      })
    );
  }

  /**
   * creates a Masteraccount, stores it in Storage (on web) or Db (on mobile) and returns User Stream
   * Guarantees first value to be a user
   *
   * @returns first Observable<User>
   * @throws HttpErrors
   * @throws TimeoutError if unable to store user to database after 5 seconds
   */
  createNewMasterAccount(payload: MasterRegistrationPayload) {
    return this.remoteUserProvider.createMasterAccount(payload).pipe(
      switchMap(userObj => this.setUserInBehaviorSubject([userObj])),
      switchMap(() => AppStateStore.user.pipe(first(user => isDefined(user))))
    );
  }

  /**
   * creates a EditorAccount, stores it in Storage (on web) or Db (on mobile) and returns User Stream
   * Guarantees first value to be a user
   *
   * @returns first Observable<User>
   * @throws HttpErrors
   * @throws TimeoutError if unable to store user to database after 5 seconds
   */
  createNewEditorAccount(payload: EditorRegistrationPayload, registrationToken: string) {
    return this.remoteUserProvider.createEditorAccount(payload, registrationToken).pipe(
      switchMap(userObj => this.setUserInBehaviorSubject([userObj])),
      switchMap(() => AppStateStore.user.pipe(first(user => isDefined(user))))
    );
  }


  /**
   * User login via http. Stores user in db in background.
   * Note: User might not be persisted yet when first value emits.
   *
   * @param usernameORemail accepts username or email
   * @param password
   * @throws HttpErrors | MasterOnly Error
   * @throws TimeoutError if unable to store user to database after 5 seconds
   */
  login(usernameORemail: string, password: string): Observable<User> {
    return this.remoteUserProvider.login(usernameORemail, password).pipe(
      switchMap((loginUser: User) => {
        if (this.platform.is('capacitor')) {
          if (
            !isDefined(loginUser.roles) ||
            !(loginUser.roles.includes(RoleAccessLevel.master) || loginUser.roles.includes(RoleAccessLevel.admin))) {
            return throwError(new MasterOnly('Unauthorized', 'You need master privileges to use the app'));
          }
        }

        return this.getPendingUpdateFor(User, loginUser).pipe(
          switchMap(update => isDefined(update)
            ? this.pushAndPullonLogin(update, loginUser)
            //usering server persist here would skip persisting user with tokens
            : UserRepository.persistLocalData(loginUser, this.platform.is('capacitor'))
          ),
          switchMap(user => this.setUserInBehaviorSubject([user])),
          tap(updatedUsers => this.notifySyncSuccess(AuthenticatedUser, updatedUsers, 'down')),
          map(updatedUsers => updatedUsers[0])
        );
      })
    );
  }

  /**
   * tries to get user from user subject (memory) once and returns it immediately if it has an refresh token.
   * If in memory undefined, get user from either Storage (web) or database (mobile).
   * On web, it will try to refresh the user as we do not store user data in storage
   * Emits only once, then dies
   *
   * @returns Observable<User>
   */
  setAuthenticatedUserIfExists(): Observable<User> {
    return AppStateStore.user.pipe(
      first(),
      switchMap(existingUser => {
        if (isDefined(existingUser) && isDefined(existingUser.refreshToken)) {
          if (existingUser.refreshToken.expires - Math.floor(Date.now() / 1000) <= 0) {
            return throwError(new Unauthorized('Unauthorized', 'please login'));
          }
          return of(existingUser);
        } else {
          return UserRepository.findOneAuthenticatedUserDbOrStorage(this.platform.is('capacitor'));
        }
      }),
      switchMap(user => this.setUserInBehaviorSubject([user])),
      switchMap(users => {
        if (this.platform.is('capacitor')) {
          return of(users[0]);
        } else {
          return AppStateStore.user.pipe(
            first(u => isDefined(u) && isDefined(u.refreshToken)),
            switchMap(() => this.pull()),
            switchMap(updatedUser => this.setUserInBehaviorSubject([updatedUser])),
            map(res => res.length > 0 ? res.pop() : null)
          );
        }
      })
    );
  }





  setNewUser(user: User) {
    AppStateStore.user.next(user);
    return of(user);
  }

  /**
   * DB: sets accessToken & refresh Token in usertable to null.
   * Storage: deletes user.
   * Memory: sets user subject to null.
   * returns when done with the first 2.
   */
  logout() {
    return AppStateStore.user.pipe(
      first(),
      exhaustMap(user => isDefined(user) ? forkJoin({
        db: AppStateStore.dbReady.pipe(
          first(),
          switchMap(ready => {
            if (ready) {
              return from(Token.deleteAllTokens());
            } else {
              return of('no db access');
            }
          })
        ),
        storage: StorageService.deleteUser()
      }) : null),
      tap(() => {
        AppStateStore.user.next(null);
        AppStateStore.report.next(null);
        AppStateStore.editors.next(null);
        AppStateStore.accountRoles.next(null);
      }),
    );
  }


  /**
   * updates authenticatedUser if updates occured since last sync.
   * returns user wrapped in array.
   * notifies sync success or failure
   * !!! on mobile ignores erros other than RefreshTokenExpired !!!
   */
  executeSync(): Observable<User[]> {
    return this.pushAndPullAll();
  }


  /**
   * tries to update User directly.
   * !!! on mobile ignores erros other than RefreshTokenExpired !!!
   */
  update(user: User) {
    return UserRepository.persistLocalData(user, this.platform.is('capacitor')).pipe(
      switchMap(alteredUser => this.setUserInBehaviorSubject([alteredUser])),
      map(users => users[0]),
      exhaustMap(alteredUser => this.platform.is('capacitor')
        ? this.pushAndPullAll().pipe(map(res => Array.isArray(res) ? res.pop() : res))
        : this.pushAndPull(alteredUser).pipe(
          switchMap(u => this.setUserInBehaviorSubject([u])),
          map(users => users[0]))
      )
    );
  }


  /**
   * updates authenticatedUser if updates occured since last sync.
   * returns user or undefined, if error happened
   * notifies sync success or failure
   * !!! on mobile ignores erros other than RefreshTokenExpired !!!
   */

  refresh() {
    return this.pushAndPullAll().pipe(map(res => Array.isArray(res) ? res.pop() : res));
  }

  /**
   * updates authenticatedUser if updates occured since last sync.
   * returns user wrapped in array.
   */
  protected commit(): Observable<User | User[]> {
    return AppStateStore.user.pipe(
      first(u => isDefined(u) && isDefined(u.refreshToken)),
      switchMap(u => this.getPendingUpdateFor(User, u)),
      exhaustMap(update => {
        if(isDefined(update)){
         return this.pushAndPull(update).pipe(
            switchMap(serverEntity => {
              if(serverEntity){
                this.notifySyncSuccess(AuthenticatedUser, [serverEntity], 'up');
                return of(serverEntity);
              }else{
                const error = new Error('expected single user from server, but none was returned');
                this.notifySyncFailure(AuthenticatedUser, error, 'up');
                return throwError( error);
              }
            }),
          );
        }else{
          return of([]).pipe(tap(()=>this.notifySyncSuccess(AuthenticatedUser, [], 'up')));
        }
      }),
    );
  }

  /**
   * updates authenticatedUser if updates occured since last sync.
   * returns user wrapped in array.
   * notifies sync success or failure
   * !!! on mobile ignores erros other than RefreshTokenExpired !!!
   * TODO: HANDLE SYNC HERE
   */
  protected pushAndPullAll(): Observable<User[]> {
    return this.commit().pipe(
      switchMap(() => this.pull()),
      switchMap(updatedUser => this.setUserInBehaviorSubject([updatedUser])),
      tap(updated => this.notifySyncSuccess(AuthenticatedUser, updated, 'down')),
      tap(updated => this.notifySyncSuccess(AuthenticatedUser, updated, 'bidirectional')),
      this.handleErrorsPlatformDependent(AuthenticatedUser,'bidirectional', [])
      );
  }



  /**
   * Gets current user | waits for him to have set an refreshToken
   * pulls fresh user data from server | merges server response with logindata from current user |
   * stores merged response | sets user in user subject | returns updated user.
   * Emits only once
   *
   * @throws TimeoutError (mobile only) if unable to store user to database after 5 seconds
   * @throws HttpErrors
   * @returnds Updated User
   */
  protected pull(): Observable<User> {
    return AppStateStore.user.pipe(
      first(user => isDefined(user) && isDefined(user.refreshToken)),
      switchMap(user => this.remoteUserProvider.getAuthenticatedUser(user))
    );
  }

  /**
   * Gets current user | waits for him to have set an refreshToken
   * pulls fresh user data from server | merges server response with logindata from current user |
   * stores merged response | sets user in user subject | returns updated user.
   * Emits only once
   *
   * @throws TimeoutError (mobile only) if unable to store user to database after 5 seconds
   * @throws HttpErrors
   * @returns [User] returns authenticated user wrapped in array
   */
  protected pullAll(): Observable<User[]> {
    return this.pull().pipe(map(user => [user]));
  }


  /**
   * takes provided entity and check against the authenticated user. If provided entity is not current authenticated user, throws an error.
   * Pushes provided entity to server and stores result in db.
   *
   * @throws if param is not authenticated user
   */
  protected pushAndPull(entity: User): Observable<User> {
    return AppStateStore.user.pipe(
      first(u => isDefined(u) && isDefined(u.refreshToken)),
      tap(u => {
        if (u.uuid !== entity.uuid) {
          throw Error('provided other than authenticated user in authenticated user manager');
        }
      }),
      switchMap(currentUser => this.remoteUserProvider.updateUser(entity, currentUser))
    );
  }

  /**
   * ! USE ONLY ON LOGIN !
   * Makes http request to push updated user to server. Requires a loginUser to extract accessToken and to merge Data
   * If successfull, persists user server response to db or storage
   *
   * @throws HttpErrors
   * @throws Timeout Error (mobile ony) if db takes longer than 5000ms. This will complete the observable.
   */
  protected pushAndPullonLogin(update: User, loginUser: User) {
    return this.remoteUserProvider.updateUser(update, loginUser).pipe(
      tap(updatedUser => this.notifySyncSuccess(AuthenticatedUser, [updatedUser], 'up')),
    );
  }

  protected getSyncInterval(): number {
    return this.syncInterval;
  }

  /**
   * sets user in store if:
   *  - current user is null and new user has a refresh token
   *  - there is a current user and new user has the same identity.
   */
  private setUserInBehaviorSubject(users: User[]) {
    const existingUser = AppStateStore.user.getValue();
    // console.log('TRYING TO SET AUTHENTICATED USER: ', users);
    if (!isDefined(existingUser) && users.length > 0) {
      const authenticatedUser = users.find(u => isDefined(u.refreshToken));
      if (isDefined(authenticatedUser)) {
        // console.log('SETTING AUTHENTICATED USER: ', authenticatedUser);
        AppStateStore.user.next(authenticatedUser);
      }
      return of(users);
    }

    const index = users.findIndex(u => u.uuid === existingUser.uuid);
    if (index >= 0) {
      const newUser = users[index];
      newUser.accessToken = newUser.accessToken ?? existingUser.accessToken;
      newUser.refreshToken = newUser.refreshToken ?? existingUser.refreshToken;
      newUser.account = newUser.account ?? existingUser.account;
      // console.log('UPDATING AUTHENTICATED USER: ', newUser);
      AppStateStore.user.next(newUser);
    }

    return of(users);
  }

}

