import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AccountRole } from 'src/app/entities/Access/AccountRole';
import { AbstractQuestion } from 'src/app/entities/Questions/AbstractQuestion';
import { AbstractStateTransition } from 'src/app/entities/State/AbstractStateTransition';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';
import { AccountRoleStateQuery } from './account.role.state.query';
import { EditorStateQuery } from './editor.state.query';
import { MetaStateQuery } from './meta.state.query';
import { QuestionStateQuery } from './question.state.query';


export class AccountRoleViewModel{
  role: AccountRole;
  delegatedQuestions: AbstractQuestion[];
  delegatedQuestionStates?: Map<string, Map<string, AbstractStateTransition>>;
}

export class AccountRoleViewModelQuery{

 static compareFn(a: AccountRole, b: AccountRole){

    if(a.user === b.user || isDefined(a.user) === isDefined(b.user)){
      return a.createdAt - b.createdAt;
    }

    return !isDefined(a.user) && isDefined(b.user) ? 1 : -1;

  }

  static getRolesWithUser(){
    return combineLatest([AccountRoleStateQuery.getRolesStream(), EditorStateQuery.getAllUsersStream()]).pipe(
      map( ([roles, users])=> roles.map( role => {
        if(isDefined(role.user)){
           const enrichedUser = users.find(u => u.uuid === role.user.uuid);
           if(isDefined(enrichedUser)){
             role.user = enrichedUser;
           }
        }
        return role;
      }).sort(AccountRoleViewModelQuery.compareFn) )
    );
  }

  static getRolesOfUser(userUuid: string){
    return combineLatest([AccountRoleStateQuery.getUserRolesStream(userUuid), EditorStateQuery.getAllUsersStream()]).pipe(
      map( ([roles, users])=> roles.map( role => {
        if(isDefined(role.user)){
           const enrichedUser = users.find(u => u.uuid === role.user.uuid);
           if(isDefined(enrichedUser)){
             role.user = enrichedUser;
           }
        }
        return role;
      }).sort(AccountRoleViewModelQuery.compareFn) )
    );
  }

  static getAccountRoleViewModelStream(){
    return combineLatest([
      QuestionStateQuery.getDelegatedQuestionsStream(),
      AccountRoleViewModelQuery.getRolesWithUser()
    ]).pipe(
        map(([qs, roles]) => {
          const roleMap: Map<string, AccountRoleViewModel> = new Map();
          roles.forEach(r => {
            const vm = new AccountRoleViewModel();
            vm.role = r;
            const delegatedQuestions = qs.filter(q => q.accountRole?.uuid === r.uuid);
            vm.delegatedQuestions = delegatedQuestions;
            roleMap.set(r.uuid, vm);
          });
          return roleMap;
        }));
  }

  static getAccountRoleViewModelStreamForUuid(roleUuid: string){
    return AccountRoleViewModelQuery.getAccountRoleViewModelStream().pipe(
      map(vm => vm.has(roleUuid) ? vm.get(roleUuid) : null ),
      filter( vm => isDefined(vm))
    );
  }

  static getAccountRoleViewModelStreamForUuidWithTransitions(roleUuid: string){
    return combineLatest([
      AccountRoleViewModelQuery.getAccountRoleViewModelStreamForUuid(roleUuid),
      AppStateStore.questionTransitions
    ]).pipe(
      map(([vm, states]) => {
        if(!isDefined(states) || vm.delegatedQuestions.length < 1){
          return vm;
        }
        const stateMap: Map<string, Map<string, AbstractStateTransition>> = new Map();
        vm.delegatedQuestions.forEach(q => {
          if(states.has(q.uuid)){
            stateMap.set(q.uuid, states.get(q.uuid));
          }
        });
        vm.delegatedQuestionStates = stateMap;
        return vm;
      })
    );
  }



}
