import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { classToClass, classToPlain, classToPlainFromExist, plainToClass } from 'class-transformer';
import { concat, Observable, of } from 'rxjs';
import { first, map, switchMap, toArray } from 'rxjs/operators';
import { AccountRole } from 'src/app/entities/Access/AccountRole';
import { AccountRoleRepository } from 'src/app/repositories/account.role.repository';
import { DelegateRestService } from './delegate.rest.service';

@Injectable({
  providedIn: 'root'
})
export class RestAccountRoles{

  constructor(
    protected readonly rest: DelegateRestService,
    protected readonly platform: Platform
  ){}


  getRoles(): Observable<AccountRole[]>{
    return this.rest.get<AccountRole[]>('/restapi/roles', {auth:'oauth2'}).pipe(
      first(),
      map(roles => plainToClass(AccountRole, roles,{groups: ['showAccountRole', 'showUser']})),
      switchMap( roles => {
        if (roles.length > 0) {
          const observables: Observable<AccountRole>[] = [];
          roles.forEach(r => observables.push(AccountRoleRepository.persistRemoteDate(r, this.platform.is('capacitor'))));
          return concat(...observables).pipe(toArray());
        } else {
          return of(roles);
        }
      })
    );
  }

  getRole(dId: number): Observable<AccountRole>{
    return this.rest.get<AccountRole>(`/restapi/role/${dId}`, {auth:'oauth2'}).pipe(
      first(),
      map(role => plainToClass(AccountRole, role,{groups: ['showAccountRole', 'showUser']})),
      switchMap(role => AccountRoleRepository.persistRemoteDate(role, this.platform.is('capacitor')))
    );
  }

  getRoleByUuid(uuid: string): Observable<AccountRole>{
    return this.rest.get<AccountRole>(`/restapi/role/uuid/${uuid}`, {auth:'oauth2'}).pipe(
      first(),
      map(role => plainToClass(AccountRole, role,{groups: ['showAccountRole', 'showUser']})),
      switchMap(role => AccountRoleRepository.persistRemoteDate(role, this.platform.is('capacitor')))
    );
  }


  updateRole(entity: AccountRole): Observable<AccountRole>{
    return this.rest.post<AccountRole>(
      '/restapi/role/update',
      {...classToPlain(entity, {groups: ['showAccountRole']})},
      {auth: 'oauth2'}).pipe(
      first(),
      map(role => plainToClass(AccountRole, role,{groups: ['showAccountRole', 'showUser']})),
      switchMap( accountRole => AccountRoleRepository.persistRemoteDate(accountRole, this.platform.is('capacitor')))
    );
  }

  refreshRoleRegistration(entity: AccountRole){
    return this.rest.post<AccountRole>(`/restapi/role/link/${entity.dId}`,{}, {auth:'oauth2'}).pipe(
      first(),
      map(role => plainToClass(AccountRole, role,{groups: ['showAccountRole', 'showUser']})),
      switchMap( role => AccountRoleRepository.persistRemoteDate(role, this.platform.is('capacitor')))
    );
  }



}
