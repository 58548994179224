import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppOnlyGuard } from './services/security/app.only.guard';
import { BrowserOnlyGuard } from './services/security/browser.only.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/browser/tasks',
    pathMatch: 'full'
  },
  {
    path: 'app',
    canLoad: [AppOnlyGuard],
    loadChildren: () => import('./modules/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'browser',
    canLoad: [BrowserOnlyGuard],
    loadChildren: () => import('./modules/sidenav/sidenav.module').then( m => m.SidenavPageModule)
  },
  {
    path:'authenticate',
    loadChildren: () => import('./modules/authenticate/authenticate.module').then( m => m.AuthenticateModule)
  },{
    path: 'onboarding',
    loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

