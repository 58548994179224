import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { classToPlain, plainToClass } from 'class-transformer';
import { Observable, forkJoin, of } from 'rxjs';
import { exhaustMap, first, map, switchMap } from 'rxjs/operators';
import { Meta } from 'src/app/entities/Questions/Meta';
import { ZWHReport } from 'src/app/entities/ZWHReport';
import { MetaRepository } from 'src/app/repositories/meta.repository';
import { ZWHReportRepository } from 'src/app/repositories/zwhreport.repository';
import { DelegateRestService } from './delegate.rest.service';

@Injectable({
  providedIn: 'root'
})
export class RestReport{

  constructor(
    protected readonly rest: DelegateRestService,
    protected readonly platform: Platform
  ){}

  getMetaReport(){
    return this.rest.get<Meta[]>('/restapi/report/metaschema', {auth: 'oauth2'}).pipe(
      first(),
      map(scheme => plainToClass(Meta, scheme)),
      switchMap(metaObjects => {
        if (metaObjects.length > 0) {
          const observables: Observable<Meta>[] = [];
          metaObjects.forEach(u => observables.push(MetaRepository.persistRemoteData(u, this.platform.is('capacitor'))));
          return forkJoin(observables);
        } else {
          return of(metaObjects);
        }
      }),
    );
  }

  getReportWithId(dId: number){
    return this.rest.get<ZWHReport>(`/restapi/report/${dId}`, { auth: 'oauth2' }).pipe(
      first(),
      map(zwhReport => plainToClass(ZWHReport, zwhReport,{groups: ['showZwhReport']})),
      exhaustMap(zwhReport => ZWHReportRepository.persistRemoteData(zwhReport, this.platform.is('capacitor'))),
    );
  }

  getAllReports(){
    return this.rest.get<ZWHReport[]>('/restapi/reports', { auth: 'oauth2' }).pipe(
      first(),
      map(reports => plainToClass(ZWHReport, reports,{groups: ['showZwhReport']})),
      switchMap((reports: ZWHReport[]) => {
        if (reports.length > 0) {
          const observables: Observable<ZWHReport>[] = [];
          reports.forEach(r => observables.push(ZWHReportRepository.persistRemoteData(r, this.platform.is('capacitor'))));
          return forkJoin(observables);
        } else {
          return of(reports);
        }
      })
    );
  }

  updateReport(entity: ZWHReport){
    return this.rest.post<ZWHReport>(
      '/restapi/report/update',
      {...classToPlain(entity, {groups: ['showZwhReport'], exposeUnsetFields: false})}, { auth: 'oauth2' }).pipe(
      first(),
      map(zwhReport => plainToClass(ZWHReport, zwhReport,{groups: ['showZwhReport']})),
      switchMap(zwhReport => ZWHReportRepository.persistRemoteData(zwhReport, this.platform.is('capacitor'))),
    );
  }


  getCurrentDrupalReportYear(){
    return this.rest.get<number>('/restapi/report/drupal/term', { auth: 'oauth2' })
    .pipe(
      first(),
      map(year => year ?? null)
    );
  }





}
