import { HttpStatusCode } from '@angular/common/http';

export interface DelegateErrorResponse{
  error: string;
  message: string;
  detail?: string;
  title?: string;
}

export class HttpError extends Error {
  constructor(message: string) {
      super(message);
      Object.setPrototypeOf(this, HttpError.prototype);
      this.name = this.constructor.name;
  }

  dump() {
      return { message: this.message, stack: this.stack };
  }
}


export class HttpRequestError extends HttpError {
  constructor(readonly statuscode: number,
              message: string,
              readonly responseBody?: DelegateErrorResponse) {
    super(message);
    Object.setPrototypeOf(this, HttpRequestError.prototype);
  }
}


/**
 * Indicates an 400 Failure from a http request.
 */
export class BadRequestError extends HttpRequestError {
  constructor(message: string, responseBody?: DelegateErrorResponse) {
    super(HttpStatusCode.BadRequest, message, responseBody);
    Object.setPrototypeOf(this, BadRequestError.prototype);
  }
}

/**
 * Indicates an 401 or 403 Authentication Failure from a http request.
 */
export class AuthenticateError extends HttpRequestError {
  constructor(readonly statuscode: number, message: string, responseBody?: DelegateErrorResponse) {
    super(statuscode, message, responseBody);
    Object.setPrototypeOf(this, AuthenticateError.prototype);
  }
}

export class ForbiddenError extends AuthenticateError{
  constructor(message: string, responseBody?: DelegateErrorResponse) {
    super(HttpStatusCode.Forbidden, message, responseBody);
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export class UnauthorizedError extends AuthenticateError{
  constructor(message: string, responseBody?: DelegateErrorResponse) {
    super(HttpStatusCode.Unauthorized, message, responseBody);
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

/**
 * Indicates a 404 Not Found failure from a http request.
 */
export class NotFoundError extends HttpRequestError {

  constructor(message: string, responseBody?: DelegateErrorResponse) {
    super(HttpStatusCode.NotFound, message, responseBody);
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}


/**
 * Indicates a 409 Conflict with a unique row
 */
 export class ConflictError extends HttpRequestError {

  constructor(message: string, responseBody?: DelegateErrorResponse) {
    super(HttpStatusCode.Conflict, message, responseBody);
    Object.setPrototypeOf(this, ConflictError.prototype);
  }
}

/**
 * Indicates a 422 where a write operation was not permitted
 */
export class UnprocessableEntityError extends HttpRequestError {

  constructor(message: string, responseBody?: DelegateErrorResponse) {
    super(HttpStatusCode.UnprocessableEntity, message, responseBody);
    Object.setPrototypeOf(this, UnprocessableEntityError.prototype);
  }
}

/**
 * Indicates a 502 503 504 (Drupal) Proxy Error
 */
 export class BadGatewayError extends HttpRequestError {
  constructor(readonly statuscode: number, message: string, responseBody?: DelegateErrorResponse) {
    super(HttpStatusCode.UnprocessableEntity, message, responseBody);
    Object.setPrototypeOf(this, BadGatewayError.prototype);
  }
}


