import { Exclude, Expose, Type } from 'class-transformer';
import { BeforeInsert, BeforeUpdate, Column, Entity, ManyToOne, PrimaryGeneratedColumn} from 'typeorm';
import { isDefined } from '../util/util.function';
import { Business } from './Taxonomy/Business';
import { EmployeeCount } from './Taxonomy/EmployeeCount';
import { Headquarters } from './Taxonomy/Headquarters';
import { IndustryBranch } from './Taxonomy/IndustryBranch';
import { LegalForm } from './Taxonomy/LegalForm';
import { Trade } from './Taxonomy/Trade';
import { TreeEntity } from './TreeEntity';

@Entity({name: 'account'})
export class Account extends TreeEntity{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Column({ nullable: false, unique: true})
  uuid: string;

  @Column({ nullable: true})
  tick: number;

  @Expose({name: 'id',toClassOnly:true})
  @Column()
  dId: number;

  @Column({nullable: true})
  companyName: string;

  @Type(() => LegalForm)
  @ManyToOne(type => LegalForm, {nullable: true, cascade: true, eager: true})
  legalForm: LegalForm;

  @Type(() => Headquarters)
  @ManyToOne(type => Headquarters, {nullable: true, cascade: true, eager: true})
  headquarters: Headquarters;

  @Type(() => EmployeeCount)
  @ManyToOne(type => EmployeeCount, {nullable: true, cascade: true, eager: true})
  employeeCount: EmployeeCount;

  @Type(() => IndustryBranch)
  @ManyToOne(type => IndustryBranch, {nullable: true, cascade: true, eager: true})
  industryBranch: IndustryBranch;

  @Type(() => Business)
  @ManyToOne(type => Business, {nullable: true, cascade: true, eager: true})
  business: Business;

  @Type(() => Trade)
  @ManyToOne(type => Trade, {nullable: true, cascade: true, eager: true})
  trade: Trade;

  @Column({nullable: true})
  telefon: string;

  @Column({nullable: true})
  website: string;

  @Column({nullable: true})
  contact: string;

  @Column({nullable: true})
  companyDescription: string;

  @Column({nullable: true})
  furtherInformation: string;

  @Column({nullable: true})
  createdAt: number;

  @Column({nullable: true})
  updatedAt: number;


  @Expose({ name: 'id', toPlainOnly: true })
  getRemoteId(){
    return this.dId;
  }

  @BeforeInsert()
  setDates(){
    if(!isDefined(this.updatedAt)){
      this.updatedAt = Math.round(Date.now()/1000);
    }
    if(!isDefined(this.createdAt)){
      this.createdAt = Math.round(Date.now()/1000);
    }
  }


  @BeforeUpdate()
  setUpdated(){
    this.updatedAt = Math.round(Date.now()/1000);
  }



}



