import { HttpHeaders, HttpParams } from '@angular/common/http';
import { isDefined } from 'src/app/util/util.function';
import { AngularRequestOptions } from './angular.requestoptions';
import { DelegateRequestOptions } from './delegate.requestoptions';

export const toAngularOptions = (delegateOptions: DelegateRequestOptions): AngularRequestOptions => {

  let headers: HttpHeaders = new HttpHeaders();
  if(isDefined(delegateOptions) && isDefined(delegateOptions.headers)){
    delegateOptions.headers.forEach(it => {
      headers = headers.set(it[0], it[1]);
    });
  }


  let params: HttpParams = new HttpParams();
  if(isDefined(delegateOptions) && isDefined(delegateOptions.urlParams)){
    delegateOptions.urlParams.forEach(it => {
      params = params.set(it[0], it[1]);
    });
  }


  return {
    headers,
    params,
    responseType: 'json',
    observe: 'body',
    withCredentials: false,
    reportProgress: false
  } as AngularRequestOptions;
};
