import { Type } from 'class-transformer';
import { Business } from 'src/app/entities/Taxonomy/Business';
import { EmployeeCount } from 'src/app/entities/Taxonomy/EmployeeCount';
import { Headquarters } from 'src/app/entities/Taxonomy/Headquarters';
import { IndustryBranch } from 'src/app/entities/Taxonomy/IndustryBranch';
import { LegalForm } from 'src/app/entities/Taxonomy/LegalForm';
import { Material } from 'src/app/entities/Taxonomy/Material';
import { Trade } from 'src/app/entities/Taxonomy/Trade';
import { Unit } from 'src/app/entities/Taxonomy/Unit';
import { EnergySource } from './EnergySource';
import { WaterSource } from './WaterSource';



export class TaxonomyContainer {

  @Type(() => Business)
  business: Business[];

  @Type(() => EmployeeCount)
  employeeCount: EmployeeCount[];

  @Type(() => Headquarters)
  headquarters: Headquarters[];

  @Type(() => IndustryBranch)
  industryBranch: IndustryBranch[];

  @Type(() => LegalForm)
  legalForm: LegalForm[];

  @Type(() => Trade)
  trade: Trade[];

  @Type(() => Material)
  material: Material[];

  @Type(() => Unit)
  unit: Unit[];

  @Type(() => EnergySource)
  energySource: EnergySource[];

  @Type(() => WaterSource)
  waterSource: WaterSource[];

}
