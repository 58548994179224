import { from } from 'rxjs';
import {BaseEntity, Column, Entity,  LessThan,  PrimaryGeneratedColumn} from 'typeorm';

@Entity({name: 'token'})
export class Token extends BaseEntity {

  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  token: string;

  @Column()
  expires: number;

  static async deleteExpiredTokens(){
   await Token.delete({ expires: LessThan(Math.round(Date.now()/1000))});
  }

  static async deleteAllTokens(){
    const tokenresult = await Token.delete({});
    return tokenresult ?? [];
   }

   static deleteTable(){
    return from(Token.delete({})) ;

   }
}
