import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { AbstractQuestion } from 'src/app/entities/Questions/AbstractQuestion';
import { QuestionCategory, questionCategories } from 'src/app/services/questions/questionCategories';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';
import { AccountRoleViewModelQuery } from './account.role.view.model.query';
import { MetaStateQuery } from './meta.state.query';
import { ReportStateQuery } from './report.state.query';







export class QuestionStateQuery{

  static getRawQuestionStream(){
    return AppStateStore.editors;
  }

  static getQuestionsStream(type?: QuestionCategory): Observable<AbstractQuestion[]>{
    return AppStateStore.questions.pipe(
      filter(q => isDefined(q)),
      map(qs => isDefined(type) ? qs.filter(q => {
        if(isDefined(q.meta)){
          return q.deleted === false  && q.meta.category === questionCategories[type];
        }else{
          return false;
        }
      }) : qs.filter(q => q.deleted === false) )
    );
  }


  static getDelegatedQuestionsStream(): Observable<AbstractQuestion[]>{
    return AppStateStore.questions.pipe(
      filter(q => isDefined(q)),
      map(qs => qs.filter(q => {
        if(isDefined(q.meta)){
          return q.deleted === false  && isDefined(q.accountRole);
        }else{
          return false;
        }
      }))
    );
  }

  static getDelegatedQuestionsStreamWithAccountRole(): Observable<AbstractQuestion[]>{
    return combineLatest([QuestionStateQuery.getDelegatedQuestionsStream(), AccountRoleViewModelQuery.getRolesWithUser()]).pipe(
      map(([questions, roles]) => questions.map(q => {
          if(isDefined(q.accountRole)){
           const roleWithUser = roles.find(r => r.uuid === q.accountRole?.uuid);
           if(roleWithUser){
             q.accountRole = roleWithUser;
           }
          }
          return q;
        }))
    );
  }

  static getQuestionsStreamWithMetaMap(): Observable<Map<string, AbstractQuestion>>{
   return QuestionStateQuery.getMetaEnrichedQuestionStream().pipe(
      map(([metas, questions]) => {
        const questionmap: Map<string,AbstractQuestion> = new Map();
        questions.forEach(q => {
          const meta = metas.find(m => m.quid === q.meta.quid);
          if(isDefined(meta)){
            q.meta = meta;
          }
          questionmap.set(q.uuid, q);
        });
        return questionmap;
      })
    );
  }

  static getDelegatedQuestionsStreamWithAccountRoleAndMeta(): Observable<AbstractQuestion[]>{
    return combineLatest([
      QuestionStateQuery.getDelegatedQuestionsStream(),
      AccountRoleViewModelQuery.getRolesWithUser(),
      MetaStateQuery.getMetaStreamForType()
    ]).pipe(
      map(([questions, roles, metas]) => questions.map(q => {
          if(isDefined(q.accountRole)){
           const roleWithUser = roles.find(r => r.uuid === q.accountRole?.uuid);
           if(roleWithUser){
             q.accountRole = roleWithUser;
           }
          }
          if(isDefined(q.meta)){
            const meta = metas.find(m => m.quid === q.meta.quid);
            if(meta){
              q.meta = meta;
            }
          }
          return q;
        }))
    );
  }


  static getQuestionsStreamWithAccountRole(type?: QuestionCategory){
    return combineLatest([QuestionStateQuery.getQuestionsStream(type), AccountRoleViewModelQuery.getRolesWithUser()]).pipe(
      map(([questions, roles]) => questions.map(q => {
          if(isDefined(q.accountRole)){
           const roleWithUser = roles.find(r => r.uuid === q.accountRole?.uuid);
           if(roleWithUser){
             q.accountRole = roleWithUser;
           }
          }
          return q;
        }))
    );
  }

  static qetQuestions(type?: QuestionCategory){
    return AppStateStore.questions.pipe(
      first(q => isDefined(q)),
      map(qs => isDefined(type) ? qs.filter(q => {
        if(isDefined(q.meta)){
          return q.meta.category === questionCategories[type];
        }else{
          return false;
        }
      }) : qs )
    );
  }

  static getMetaEnrichedQuestionStream(type?: QuestionCategory){
    return combineLatest([MetaStateQuery.getMetaStreamForType(type),QuestionStateQuery.getQuestionsStream(type)]);
  }





}
