/* eslint-disable @typescript-eslint/naming-convention */
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EMPTY, forkJoin, Observable, of, pipe, queue, throwError } from 'rxjs';
import { catchError, exhaustMap, first, map, switchMap, tap, timeout } from 'rxjs/operators';
import { AbstractQuestion } from 'src/app/entities/Questions/AbstractQuestion';
import { AbstractStateTransition } from 'src/app/entities/State/AbstractStateTransition';
import { DelegationStateTransition } from 'src/app/entities/State/DelegationStateTransition';
import { EntityRelation, SyncEntry } from 'src/app/entities/Sync/SyncEntry';
import { RestDelegations } from 'src/app/provider/rest/rest.delegations';
import { RestState } from 'src/app/provider/rest/rest.state';
import { QuestionRepository } from 'src/app/repositories/question.repository';
import { TransitionRepository } from 'src/app/repositories/transition.repository';
import { RefreshTokenExpired } from 'src/app/services/security/exceptions/security.error';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';
import { Activity, News, NewStatesQuery } from '../query/news.state.query';
import { TransitionStateQuery } from '../query/transition.state.query';
import { AbstractStateManager } from './abstract.state.manager';

@Injectable({
  providedIn: 'root'
})
export class TransitionStateManager extends AbstractStateManager<AbstractStateTransition>{



  static syncingRelations: Record<string, EntityRelation[]> = {
    DelegationStateTransition: [
        {
          propertyname: 'accountRole',
          alias: 'accountRole'
        }
    ]
 };

 static messageTypeMap = {
  DelegationStateTransition,
};



private syncInterval = 300; // once every 5 minutes;


constructor(
  private readonly restDelegations: RestDelegations,
  private readonly restStates: RestState,
  protected readonly platform: Platform
){
  super(platform);
}


static getUniqueClassObjects(){
  return [...new Set(Object.values(TransitionStateManager.messageTypeMap))];
}


  getStatesForQuestion(q: AbstractQuestion): Observable<AbstractStateTransition[]>{
    return this.platform.is('capacitor')
    ? this.geStatesForQuestionMobile(q).pipe(map(stateMap => Array.from(stateMap.values())))
    : this.getStateForQuestionWeb(q).pipe(map(stateMap => Array.from(stateMap.values())));
  }

  getStatesOfTeam(): Observable<News[]>{
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() =>this.platform.is('capacitor') ? this.loadTeamStatesFromDb().pipe(
        this.mergeStateWithStore(),
        tap(states => this.setStatesInStore(states)),
      ): of([])),
      tap(() => {
        this.restStates.getTransitionsTeam().pipe(
          first(),
          this.mergeStateWithStore(),
          tap(states => this.setStatesInStore(states)),
          this.handleErrorsPlatformDependent(AbstractStateTransition,'down',[])
        ).subscribe();
      }),
      switchMap( () => NewStatesQuery.getNewsStateQuery())
    );
  }

  getStatesLoggedInUser(): Observable<Activity[]>{
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      tap(() => {
        this.restStates.getTransitionsTeamMember().pipe(
          first(),
          this.mergeStateWithStore(),
          tap(states => this.setStatesInStore(states)),
          this.handleErrorsPlatformDependent(AbstractStateTransition,'down',[])
        ).subscribe();
      }),
      switchMap( () => NewStatesQuery.getMyNewsStateQuery())
    );
  }

  refreshStatesForQuestion(q: AbstractQuestion){
    return this.platform.is('capacitor') ? this.refreshOnMobile(q) : this.refreshOnWeb(q);
  }

  refreshOnWeb(q: AbstractQuestion){
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() => this.pullStatesForQuestion(q.dId).pipe(
          first(),
          this.mergeStateWithStore(),
          tap(states => this.setStatesInStore(states))
      )),
      first()
    );
  }

  refreshOnMobile(q: AbstractQuestion){
    let dbQuestion: AbstractQuestion; //loading db question, because question might not have been commited yet
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() =>  QuestionRepository.getDbInstance(q)),
      tap( dbInstance => dbQuestion = dbInstance),
      switchMap(() => this.loadStatesFromDb(q)),
      // tap(states => console.log('TREE refresh loaded states: ', states)),
      this.mergeStateWithStore(),
      tap(states => this.setStatesInStore(states)),
      tap(() => {
        if(isDefined(dbQuestion.dId)){
          this.pullStatesForQuestion(dbQuestion.dId).pipe(
            this.mergeStateWithStore(),
            tap(states => this.setStatesInStore(states)),
            this.handleErrorsPlatformDependent(AbstractStateTransition, 'down', new  Map<string, Map<string, AbstractStateTransition>>()),
            first()
          ).subscribe();
        }
      }),
    );
  }

  getStateForQuestionWeb(q: AbstractQuestion) {
    if(!isDefined(q.uuid) && !isDefined(q.dId)){
      return of(new Map<string, AbstractStateTransition>());
    }
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() => AppStateStore.questionTransitions),
      first(),
      tap(stateMap => {
        // if(!stateMap.has(q.uuid)){
          this.pullStatesForQuestion(q.dId).pipe(
            first(),
            this.mergeStateWithStore(),
            tap(states => this.setStatesInStore(states))
            ).subscribe();
        // }
      }),
      switchMap(() => TransitionStateQuery.getTransitionsForUuidWithAccountRole(q.uuid)),
    );
  }



  geStatesForQuestionMobile(q: AbstractQuestion): Observable<Map<string, AbstractStateTransition>>{
    let dbStates: Map<string, Map<string, AbstractStateTransition>>;
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() => AppStateStore.questionTransitions),
      first(),
      tap(stateMap => {
          this.loadStatesFromDb(q).pipe(
            first(),
            this.mergeStateWithStore(),
            tap(states => this.setStatesInStore(states)),
            switchMap(states =>  isDefined(q.dId)
            ? this.pullStatesForQuestion(q.dId).pipe(
              first(),
              this.mergeStateWithStore(),
              tap(remoteStates => this.setStatesInStore(remoteStates)),
            )
            : of(states)),
            catchError(e => {
              if(this.platform.is('capacitor')){
                if(e instanceof RefreshTokenExpired){
                  return throwError(e);
                }
                return EMPTY;
              }else{
                return throwError(e);
              }
            }),
            ).subscribe();
      }),
      switchMap(() => TransitionStateQuery.getTransitionsForUuidWithAccountRole(q.uuid)),
     );
  }

  loadStatesFromDb(q: AbstractQuestion): Observable<AbstractStateTransition[]>{
    return TransitionRepository.getStatesForQuestion(q.uuid);
  }

  loadTeamStatesFromDb(){
    return AppStateStore.editors.pipe(
      first(es => isDefined(es)),
      map(users => users.map( u => u.id)),
      switchMap( userIDs => userIDs.length > 0 ? TransitionRepository.getTeamStates(userIDs): of(new Array<AbstractStateTransition>()))
    );
  }

  sendMesage(messages: AbstractStateTransition[]){
    return TransitionRepository.persistLocalData(messages, this.platform.is('capacitor')).pipe(
      switchMap(persistantMessages => this.platform.is('capacitor')
      ? this.sendMessageOnMobile(persistantMessages)
      : this.sendMessageOnWeb(persistantMessages))
  );

  }


  executeSync(): Observable<AbstractStateTransition[]> {
    return this.pushAndPullAll();
  }

  protected sendMessageOnWeb(messages: AbstractStateTransition[]): Observable<AbstractStateTransition[]>{
    let serverMessages: AbstractStateTransition[];
    return this.pushAndPullMany(messages).pipe(
      tap(serverMgs => serverMessages = serverMgs),
      this.mergeStateWithStore(),
      switchMap( merged => this.setStatesInStore(merged)),
      map(() => serverMessages)
    );
  }

  protected sendMessageOnMobile(messages: AbstractStateTransition[]){
    return of(messages).pipe(
      this.mergeStateWithStore(),      //merge with current state
      switchMap(merged=> this.setStatesInStore(merged)),
      tap(() =>{
        this.commit().pipe(
          this.mergeStateWithStore(),
          switchMap(merged=> this.setStatesInStore(merged)),
          this.handleErrorsPlatformDependent(AbstractStateTransition,'up',[])
        ).subscribe();
      })
    );
  }

  protected pullStatesForQuestion(dId: number){
    return this.restStates.getStatesForQuestion(dId);
  }

  protected pull(dId: number): Observable<AbstractStateTransition> {
    throw new Error('Method not implemented.');

  }
  protected pullAll(): Observable<AbstractStateTransition[]> {
    throw new Error('Method not implemented.');
  }
  protected pushAndPull(entity: AbstractStateTransition): Observable<AbstractStateTransition> {
    throw new Error('Method not implemented.');
  }

  protected pushAndPullMany(messages: AbstractStateTransition[]){
    const observables: Observable<AbstractStateTransition[]>[] = [];
    const set = new Set();
    messages.forEach(m => set.add(m.constructor.name));
    Array.from(set).forEach(s => {
     const arrayWithSameType =  messages.filter( m => m.constructor.name === s);
     if(arrayWithSameType.length > 0){
      switch(s){
        case DelegationStateTransition.name:
            observables.push(
              this.restDelegations.updateDelegations(arrayWithSameType as DelegationStateTransition[])
            );
            break;
        default: break;
      }
     }
    });
    return forkJoin(observables).pipe(
       map(arar => arar.flat(2)));
  }

  protected pushAndPullAll(): Observable<AbstractStateTransition[]> {
    return this.commit().pipe(
      this.handleErrorsPlatformDependent(AbstractStateTransition, 'up', [])
    );
  }

  protected commit(entity?: AbstractStateTransition): Observable<AbstractStateTransition | AbstractStateTransition[]> {
    let updateMap: Map<string, AbstractStateTransition[]>;
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      exhaustMap(() => this.getAllPendingUpdates()),
      tap(messageMap => updateMap = messageMap ),
      switchMap((messageMap: Map<string, AbstractStateTransition[]>) => {
        const observables: Observable<AbstractStateTransition[]>[] = [];
        if (isDefined(messageMap) && messageMap.size > 0) {
          messageMap.forEach((messages,_) =>{
            observables.push(this.pushAndPullMany(messages));
          });
        }
        return observables.length > 0 ? forkJoin(observables) :[[]];
      }),

      map(arar => {
        if(isDefined(entity)){
          const entityAr = arar.find(ar => ar.length > 0 && ar[0].constructor.name === entity.constructor.name);
          if(entityAr && entityAr.length > 0){
            return entityAr.find(e => e.id === entity.id);
          }
        }
        return arar.flat(2);
      }),
      switchMap( (updates: AbstractStateTransition[]) => {
        let expectedSize = 0;
        updateMap.forEach((value, key) => expectedSize = expectedSize + value.length);
        if(updates.length === expectedSize){
          this.notifySyncSuccess(AbstractStateTransition, Array.isArray(updates) ? updates : [updates], 'up');
        }else{
          const error = new Error('TREE expected response count to be same size as given updates');
          this.notifySyncFailure(AbstractStateTransition, error, 'up');
          return throwError( error);
        }
          return of(updates);
      })
    );
  }



  protected flattenMap(updateMap: Map<string, AbstractStateTransition[]>){
    let array: AbstractStateTransition[] = [];
    updateMap.forEach((messages: AbstractStateTransition[],_) =>{
     array = array.concat(messages);
    });
    return array;
  }


  /**
   * @returns Map<constructor.name, AbstractStateTransition[]>
   */
  protected getAllPendingUpdates(){
    if (this.platform.is('capacitor')) {
      return AppStateStore.dbReady.pipe(
        first(ready => ready),
        map(() => TransitionStateManager.getUniqueClassObjects()),
        switchMap(questionClasses => {
          const observables: Observable<AbstractStateTransition[]>[] = [];
          questionClasses.forEach(qclass => {
            observables.push(this.getPendingUpdates(qclass));
          });
          return forkJoin(observables);
        }),
        map(arar => {
          const messageMap: Map<string, AbstractStateTransition[]> = new Map();
          arar.forEach(ar => {
            if(ar.length > 0){
              messageMap.set(ar[0].constructor.name, ar);
            }
          });
          return messageMap;
        }),
      );
    } else {
      return of(new Map<string, AbstractStateTransition[]>());
    }
  }

  protected getPendingUpdates(ctor: new (...args: any[]) => AbstractStateTransition): Observable<AbstractStateTransition[]>{
    if (this.platform.is('capacitor')) {
      return AppStateStore.dbReady.pipe(
        first(ready => ready),
        exhaustMap(_ => SyncEntry.getUnsentMessages(ctor, AppStateStore.user.getValue(), this.getSyncRelationsForAbstactMessage(ctor))),
        timeout(5000)
      );
    } else {
      return of([]);
    }
  }

  protected getSyncRelationsForAbstactMessage(ctor: new (...args: any[]) => AbstractStateTransition): EntityRelation[] | null {
    const relations: EntityRelation[] = TransitionStateManager.syncingRelations[ctor.name];
    return relations ?? null;
  }

  protected getSyncInterval(): number {
    return this.syncInterval;
  }

  private mergeStateWithStore(){
    let newTransitions: AbstractStateTransition[];
    return pipe(
      switchMap((transitions: AbstractStateTransition[]) => {
        newTransitions = transitions;
        return AppStateStore.questionTransitions;
      }),
      first(),
      map(existingQuestionStates => {
        if(!isDefined(existingQuestionStates)){
          existingQuestionStates = new Map();
        }
        newTransitions.forEach( nTransition => {
          let values: Map<string, AbstractStateTransition> = new Map();
          if(existingQuestionStates.has(nTransition.question)){
             const test = existingQuestionStates.get(nTransition.question);
             values = test;
          }
          values.set(nTransition.createdAt+'|'+nTransition.constructor.name, nTransition);
          existingQuestionStates.set(nTransition.question, values);
        });
      return existingQuestionStates;
      }),
    );
  }


  private setStatesInStore(states: Map<string, Map<string, AbstractStateTransition>>){
    AppStateStore.questionTransitions.next(states);
    return of(states);
  }




}

