import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { forkJoin, Observable, of } from 'rxjs';
import { exhaustMap, first, map, switchMap, tap } from 'rxjs/operators';
import { User } from 'src/app/entities/User';
import { ZWHReport } from 'src/app/entities/ZWHReport';
import { RestReport } from 'src/app/provider/rest/rest.report';
import { ZWHReportRepository } from 'src/app/repositories/zwhreport.repository';
import { StorageService } from 'src/app/services/local-persistance/storage.service';
import { isDefined } from 'src/app/util/util.function';
import { getCustomRepository } from 'typeorm';
import { AppStateStore } from '../app.state.store';
import { AbstractStateManager } from './abstract.state.manager';

@Injectable({
  providedIn: 'root'
})
export class ReportManager extends AbstractStateManager<ZWHReport>{

  private syncInterval = 300; // every 5 minutes

  constructor(
    protected readonly platform: Platform,
    protected readonly restReport: RestReport
  ){
    super(platform);
  }

  setReport(r: ZWHReport){
    return this.setReportImMemoryIfNotDeleted(r);
 }



/**
 * creates a Report, stores in Db (on mobile) and returns report
 * !!! on mobile ignores erros with regards to http calls other than RefreshTokenExpired !!!
 *
 * @returns Observable<ZWHReport>
 * @throws HttpErrors (web only)
 * @throws TimeoutError if unable to store user to database after 5 seconds
 */
    createNewReport(year: number) {
    const r = new ZWHReport();
    r.year = year;
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      tap(u =>  r.account = u.account),
      switchMap(() => ZWHReportRepository.persistLocalData(r, this.platform.is('capacitor'))),
      switchMap(rep => this.platform.is('capacitor') ? this.createNewReportMobile(rep) : this.createNewReportWeb(rep))
    );
  }

  /**
   * set deleted property of report to true
   * sets memory report to null
   * commits pending updates and current update else returns param
   * if nomore report selected, check if any exists where not deleted and set one with latest update
   * then return the persistant updated report
   *
   * @param ZWHReport
   * @returns ZWHReport
   */
   setReportDeleted(r: ZWHReport) {
    r.deleted = true;
    const existingReport = AppStateStore.report.getValue();
    if (isDefined(existingReport) && existingReport.uuid === r.uuid) {
      AppStateStore.report.next(null);
      StorageService.deleteReportingYear(AppStateStore.user.getValue()).subscribe();
    }
    return ZWHReportRepository.persistLocalData(r, this.platform.is('capacitor')).pipe(
      tap(persistantReport => {
        const selected = AppStateStore.report.getValue();
          if(this.platform.is('capacitor') && !isDefined(selected)){
            this.getRepository().pipe(
              switchMap( repository => repository.findSelectedReportOrReturnLatestUpdated(AppStateStore.user.getValue())),
              switchMap(rep => this.setFirstReportOrUpdateCurrentInBehaviorSubject([rep])),
              switchMap(() => this.commit()),
              this.handleErrorsPlatformDependent(ZWHReport,'up',persistantReport),
              first()
            ).subscribe();
          }else if(!this.platform.is('capacitor')){
            if(!isDefined(selected)){
              this.pushAndPull(persistantReport).pipe(
                switchMap(() => this.findLastUpdatedReportRemotely().pipe(
                  switchMap(rep => this.setFirstReportOrUpdateCurrentInBehaviorSubject([rep])))
                ),
                first()
              ).subscribe();
            }else{
              this.pushAndPull(persistantReport).pipe(first()).subscribe();
            }

          }
      })
    );
  }

  /**
   *
   * gets all Reports from db if on mobile, else get them from remoteDb
   * !!! on mobile ignores erros other than RefreshTokenExpired !!!
   */
  getAllReports(){
    return this.platform.is('capacitor') && AppStateStore.dbReady.getValue()
      ? this.getRepository().pipe( switchMap( repository => repository.findAllReportsOfUser(AppStateStore.user.getValue())))
      : this.pushAndPullAll();
  }


  executeSync(): Observable<ZWHReport[]> {
    return this.pushAndPullAll();
  }



  /**
   * looks for a selected report.
   * returns first it finds in memory, db or remoteDb.
   * Sets selected report in memory, if didn't exist before
   * emits once.
   * !!! on mobile ignores erros other than RefreshTokenExpired !!!
   *
   * @returns ZwhReport | null
   */
   setSelectedReportOrSetLastUpdatedIfExists() {
    return AppStateStore.report.pipe(
      first(),
      exhaustMap(r => {
        if(isDefined(r)){
          return of(r);
        }
        return !this.platform.is('capacitor')
        ? of(null)
        : this.getRepository().pipe(
          switchMap(repository => repository.findSelectedReportOrReturnLatestUpdated(AppStateStore.user.getValue()))
          );
      }),
      exhaustMap(r => isDefined(r)
        ? of(r).pipe(
          switchMap(rep => this.setFirstReportOrUpdateCurrentInBehaviorSubject([rep])),
          map(reps => reps[0])
        )
        : this.findLastUpdatedReportRemotely().pipe(
          switchMap(rep => this.setFirstReportOrUpdateCurrentInBehaviorSubject([rep])),
          map(reps => reps[0])
        )
      )
    );
  }

/**
 *
 * gets all reports and returns the one with the latest updated entry.
 * !!! on mobile ignores erros other than RefreshTokenExpired !!!
 */
  protected findLastUpdatedReportRemotely(): Observable<ZWHReport> {
    return this.pushAndPullAll().pipe(
      map(allReports => allReports.filter(r => r.deleted === false)),
      map(reports =>
        reports.length > 0 ? reports.reduce((prev, current) => prev.updatedAt > current.updatedAt ? prev : current) : null
      )
    );
  }


  /**
   * !!! on mobile ignores erros other than RefreshTokenExpired !!!
   */
  protected createNewReportMobile(r: ZWHReport){

    if(isDefined(r.uuid)){
      this.setReportImMemoryIfNotDeleted(r);
    }

    return of(r).pipe(
      tap(rep => {
        this.commit(rep).pipe(
          this.handleErrorsPlatformDependent(ZWHReport, 'single', rep),
          first()
        ).subscribe();
      })
    );
  }

  protected createNewReportWeb(r: ZWHReport){
    return this.pushAndPull(r).pipe(
    switchMap(rep => this.setReportImMemoryIfNotDeleted(rep))
    );
  }

  /**
   * Gets an entity from the server and persists entity in in db
   * updates memory if relevant report is already selected
   *
   * @param dId — serverside id
   * @throws HttpErrors
   */
  protected pull(dId: number): Observable<ZWHReport> {
    return this.restReport.getReportWithId(dId);
  }

  /**
   * Gets all entities from the server and persists entities concurrently in db
   *
   * @throws HttpErros
   */
  protected pullAll(): Observable<ZWHReport[]> {
    return this.restReport.getAllReports();
  }

  /**
   * updates single entity by first committing pending changes, then persisting server response
   *
   * @throws HttpErrors
   */
  protected pushAndPull(entity: ZWHReport): Observable<ZWHReport> {
    return this.restReport.updateReport(entity);
  }

  /**
   * Pushes all pending changes to the server, then gets all entities from server and persists entities concurrently in db.
   * Needs to notify sync success or failure (e.g. by calling commit)
   * !!! on mobile ignores erros other than RefreshTokenExpired !!!
   */
  protected pushAndPullAll(): Observable<ZWHReport[]> {
    return this.commit().pipe(
      switchMap(() => this.pullAll()),
      switchMap( reports => this.setFirstReportOrUpdateCurrentInBehaviorSubject(reports)),
      tap(reports => this.notifySyncSuccess(ZWHReport, reports, 'down')),
      tap(updated => this.notifySyncSuccess(ZWHReport, updated, 'bidirectional')),
      this.handleErrorsPlatformDependent(ZWHReport, 'bidirectional',[]),
    );
  }
/**
 * updates all entities with changes since last sync (Mobile only).
 * If entity param is provided returns that entity else returns array of updated entities.
 * Needs to notify sync success or failure
 */
  protected commit(entity?: ZWHReport): Observable<ZWHReport | ZWHReport[]> {
    let user: User;
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      tap(u => user = u),
      exhaustMap(() => this.getPendingUpdates(ZWHReport)),
      map(updates => updates.filter(r => r.accountId === user.accountId)),
      exhaustMap(updates => {
        if (isDefined(updates) && Array.isArray(updates) && updates.length > 0) {
          const observables: Observable<ZWHReport>[] = [];
          updates.forEach(r => {
            observables.push(this.pushAndPull(r));
          });
          return forkJoin(observables).pipe(
            switchMap(reports => this.setFirstReportOrUpdateCurrentInBehaviorSubject(reports)),
            this.verifySuccess(ZWHReport, updates),
            map(reports => {
              if (isDefined(entity)) {
                const index = reports.findIndex(r => r.uuid === entity.uuid);
                if (isDefined(index) && index >= 0) {
                  return reports[index];
                }
              }
              return entity ?? reports;
            })
          );
        } else {
          return of(entity ?? []).pipe(tap(()=>this.notifySyncSuccess(ZWHReport, [], 'up')));
        }
      })
    );
  }


  protected getSyncInterval(): number {
    return this.syncInterval;
  }



/**
 * sets report in memory if not deleted.
 * If already set in memory, updates current value
 *
 * @returns ZWHReport[] original params( i.e. ZWHReport[])
 */
  private setFirstReportOrUpdateCurrentInBehaviorSubject(rs: ZWHReport[]) {
    const existingReport = AppStateStore.report.getValue();

    // if no report is defined, set the report with the latest updated value
    if (!isDefined(existingReport)) {
      const rep = rs.length > 0 ? rs.reduce((prev, current) => prev.updatedAt > current.updatedAt ? prev : current) : null;
      return this.setReportImMemoryIfNotDeleted(rep).pipe(switchMap(() => of(rs)));
    }

    // if report is defined, set the report with new values
    const inReportsIndex = rs.findIndex(r => r.uuid === existingReport.uuid);
    if (inReportsIndex >= 0) {
      return this.setReportImMemoryIfNotDeleted(rs[inReportsIndex]).pipe(switchMap(() => of(rs)));
    }

    // default, just return passed in values
    return of(rs);
  }

  /**
   * sets report if not deleted
   *
   * @returns ZWHReport
   */
  private setReportImMemoryIfNotDeleted(r: ZWHReport): Observable<ZWHReport> {
    if (isDefined(r) && r.deleted === false) {
      AppStateStore.report.next(r);
      return StorageService.setReportUuid(r.uuid, AppStateStore.user.getValue()).pipe(map(()=>r));
    }
    return of(r);
  }


  private getRepository(){
    return AppStateStore.dbReady.pipe(first(ready => ready), map(() => getCustomRepository(ZWHReportRepository)));
  }
}
