import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EMPTY, forkJoin, Observable, of, pipe, throwError } from 'rxjs';
import { catchError, exhaustMap, first, map, switchMap, tap } from 'rxjs/operators';
import { AccountRole } from 'src/app/entities/Access/AccountRole';
import { RestAccountRoles } from 'src/app/provider/rest/rest.account.roles';
import { AccountRoleRepository } from 'src/app/repositories/account.role.repository';
import { RefreshTokenExpired } from 'src/app/services/security/exceptions/security.error';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';
import { AccountRoleViewModelQuery } from '../query/account.role.view.model.query';
import { AbstractStateManager } from './abstract.state.manager';

@Injectable({
  providedIn: 'root'
})
export class AccountRoleManager extends AbstractStateManager<AccountRole>{

  private syncInterval = 300; // every 5 minutes

  constructor(
    private readonly restAccountRole: RestAccountRoles,
    protected readonly platform: Platform
  ){
    super(platform);
  }

  autoSetAccountRoles(){
    let accountId: number;
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      tap(u => accountId = u.accountId),
      exhaustMap(u => AppStateStore.accountRoles.pipe(first())),
      switchMap(roles => isDefined(roles) ? of(roles)
      : this.platform.is('capacitor')
        ? AccountRoleRepository.getAllAccountRoles(accountId).pipe(switchMap(rs => this.setAccountRolesInBehaviorSubject(rs)))
        : this.pushAndPullAll().pipe(switchMap(rs => this.setAccountRolesInBehaviorSubject(rs)))
      )
    );
  }

  /**
   * @param id on mobile, dId on web
   */
  getAccountRoleViewModel(uuid: string) {
    return this.platform.is('capacitor') ? this.getRoleMobile(uuid): this.getRoleWeb(uuid);
  }

  getRoleWeb(uuid: string){
    if(!isDefined(uuid)){
      return EMPTY;
    }
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(()=> AppStateStore.accountRoles.pipe(first(rs => isDefined(rs)))),
      map( roles => roles.find(r => r.uuid === uuid)),
      tap( role => {
        if(!isDefined(role)){
          this.pullByUuid(uuid).pipe(
            first(),
            this.mergeWithRoleObject(),
            tap(roles =>this.setAccountRolesInBehaviorSubject(roles)),
          ).subscribe();
        }
      }),
      switchMap(() => AccountRoleViewModelQuery.getAccountRoleViewModelStreamForUuid(uuid))
    );

  }

  getRoleMobile(uuid: string){
   return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(()=> AppStateStore.accountRoles.pipe(first(rs => isDefined(rs)))),
      map( roles => roles.find(r => r.uuid === uuid)),
      tap( role => {
        if(!isDefined(role)){
          AccountRoleRepository.findByUuid(uuid).pipe(
            first(),
            this.mergeWithRoleObject(),
            tap(roles =>this.setAccountRolesInBehaviorSubject(roles)),
            map( roles => roles.find(r => r.uuid === uuid)),
            switchMap( r => isDefined(r) ? of(r) : this.pullByUuid(uuid).pipe(
              first(),
              this.mergeWithRoleObject(),
              tap(roles =>this.setAccountRolesInBehaviorSubject(roles)),
              catchError(e => {
                if(this.platform.is('capacitor')){
                  if(e instanceof RefreshTokenExpired){
                    return throwError(e);
                  }
                  return EMPTY;
                }else{
                  return throwError(e);
                }
              })
            ))
          ).subscribe();
        }
      }),
      switchMap(() => AccountRoleViewModelQuery.getAccountRoleViewModelStreamForUuid(uuid))
    );
  }

  update(accountRole: AccountRole) {
    let serverRole: AccountRole;
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      map( u => {
        accountRole.account = u.account;
        return accountRole;
      }),
      exhaustMap(role => AccountRoleRepository.persistLocalData(role, this.platform.is('capacitor'))),
      exhaustMap(persistantRole => this.platform.is('capacitor')
      ? of(persistantRole).pipe(
        this.mergeWithRoleObject(),
        tap(roles =>this.setAccountRolesInBehaviorSubject(roles)),
        switchMap(() => this.commit(persistantRole).pipe(
          this.handleErrorsPlatformDependent(AccountRole, 'up',persistantRole))
          )
        )
      : this.pushAndPull(persistantRole)
      ),
      tap(role => serverRole = role),
      this.mergeWithRoleObject(),
      switchMap(roles =>this.setAccountRolesInBehaviorSubject(roles)),
      map(() => serverRole)
    );
  }

  refreshRegistrationLink(accountRole: AccountRole){
    let serverRole: AccountRole;
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() => this.platform.is('capacitor')
      ? this.commit().pipe(
        switchMap(() => this.restAccountRole.refreshRoleRegistration(accountRole))
      )
      : this.restAccountRole.refreshRoleRegistration(accountRole)
      ),
      tap((serverR: AccountRole) => serverRole = serverR),
      switchMap(() => AppStateStore.accountRoles),
      first(),
      switchMap(roles => {
        const index = roles.findIndex( r => r.uuid === serverRole.uuid);
        if(index > -1){
          roles[index] = serverRole;
        }else{
          roles.push(serverRole);
        }
        return this.setAccountRolesInBehaviorSubject(roles);
      }),
      map(() => serverRole)
    );
  }

  executeSync(): Observable<AccountRole[]> {
    return this.pushAndPullAll();
  }

  protected pull(dId: number): Observable<AccountRole> {
    return this.restAccountRole.getRole(dId);
  }

  protected pullByUuid(uuid: string): Observable<AccountRole>{
    return this.restAccountRole.getRoleByUuid(uuid);
  }

  protected pullAll(): Observable<AccountRole[]> {
    return this.restAccountRole.getRoles();
  }
  protected pushAndPull(entity: AccountRole): Observable<AccountRole> {
    return this.restAccountRole.updateRole(entity);
  }
  protected pushAndPullAll(): Observable<AccountRole[]> {
    return this.commit().pipe(
      switchMap(() => this.pullAll()),
      switchMap( roles => this.setAccountRolesInBehaviorSubject(roles)),
      tap(updated => this.notifySyncSuccess(AccountRole, updated, 'down')),
      tap(updated => this.notifySyncSuccess(AccountRole, updated, 'bidirectional')),
      this.handleErrorsPlatformDependent(AccountRole, 'bidirectional',[]),
      );
  }

  protected commit(entity?: AccountRole): Observable<AccountRole | AccountRole[]> {
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() => this.getPendingUpdates(AccountRole)),
      switchMap(accountRoleUpdates => {
        if (isDefined(accountRoleUpdates) && Array.isArray(accountRoleUpdates) && accountRoleUpdates.length > 0) {
          const observables: Observable<AccountRole>[] = [];
          accountRoleUpdates.forEach(aR => {
            observables.push(this.pushAndPull(aR));
          });

          return forkJoin(observables).pipe(
            this.verifySuccess(AccountRole, accountRoleUpdates),
            map(updatedAccountRoles => {
              if (isDefined(entity)) {
                const index = updatedAccountRoles.findIndex(aR => aR.uuid === entity.uuid);
                if (isDefined(index) && index >= 0) {
                  return updatedAccountRoles[index];
                }
              }
              return entity ?? updatedAccountRoles;
            })
          );
        }else{
          return of(entity ?? []).pipe(tap(() => this.notifySyncSuccess(AccountRole, [], 'up')));
        }
      })
    );
  }

  protected getSyncInterval(): number {
    return this.syncInterval;
  }


  private setAccountRolesInBehaviorSubject(roles: AccountRole[]) {
    if (isDefined(roles)) {
      AppStateStore.accountRoles.next(roles);
    }
    return of(roles);
  }

  private mergeWithRoleObject(){
    let accountRole: AccountRole;
    return pipe(
      switchMap((role: AccountRole) => {
        accountRole = role;
        return AppStateStore.accountRoles;
      }),
      first(),
      map(roles => {
        const resultRoles: Map<string, AccountRole> = new Map();
        roles.forEach( r => resultRoles.set(r.uuid, r));
        resultRoles.set(accountRole?.uuid, accountRole);
        return Array.from(resultRoles.values());
      }),
    );
  }




}
