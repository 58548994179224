
import { Inject, Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { Token } from 'src/app/entities/Access/Token';
import { User } from 'src/app/entities/User';
import { isDefined } from 'src/app/util/util.function';
import { DelegateHttpInterface, DELEGATE_HTTP_INTERFACE } from '../http/delegate.http.interface';
import { TokenRefreshResponse } from '../payload/tokenrefresh.response';
import { TokenManagerInterface } from './token.manager.interface';
import { environment } from 'src/environments/environment';
import { AuthenticateError } from '../exceptions/HttpError';
import { RefreshTokenExpired } from 'src/app/services/security/exceptions/security.error';
import { UserStateQuery } from 'src/app/store/query/user.state.query';
import { AuthenticatedUserManager } from 'src/app/store/manager/authenticated.user.manager';


@Injectable({
  providedIn: 'root'
})
export class TokenManagerImpl implements TokenManagerInterface {


  private accessTokenSubject = new BehaviorSubject<Token>(null);
  private refreshInProcess = false;


  constructor(
    @Inject(DELEGATE_HTTP_INTERFACE) private readonly http: DelegateHttpInterface,
    ) { }



  getAccessToken(): Observable<string | null> {
    return UserStateQuery.getRawUserStream().pipe(
      take(1),
      switchMap(user => {
        if (!isDefined(user)) {
          return throwError(
            new AuthenticateError(401 ,'No User', { error: 'No authenticated user', message: 'no authenticated user could be found' })
          );
        }
        if (!isDefined(user.accessToken)) {
          return throwError(
            new AuthenticateError(401, 'No Token', { error: 'User not authenticated', message: 'no authenticated user could be found' })
          );
        }

        let token: string = null;
        const remainingSec = user.accessToken.expires - Math.floor(Date.now() / 1000);
        token = remainingSec > 0 ? user.accessToken.token : null;


        /**
         * if expired, refresh
         * TODO: consider throwing custom error on timeout of refresh
         */
        if (!isDefined(token)) {
          if(this.refreshInProcess){
            //subscribe, see last value. If expired or null wait for next value
            return this.accessTokenSubject.pipe(
              filter(aT => isDefined(aT) && (aT.expires - Math.floor(Date.now() / 1000) > 0)),
              take(1),
              timeout(5000),
              map(t => t.token)
            );
          }else{
            this.refreshInProcess = true;
            return this.http.post<TokenRefreshResponse>(
                `${environment.remoteBaseUrl}/auth/refresh`,
                JSON.stringify({ refreshToken: user.refreshToken.token }),
                { auth: 'noAuth' })
                .pipe(
                  tap((tokens: TokenRefreshResponse) =>{
                    this.accessTokenSubject.next(plainToClass(Token, tokens.accessToken));
                    this.updateUsersAuthenticationTokens(tokens,user).subscribe();
                  }),
                  map((tokens: TokenRefreshResponse) => tokens.accessToken.token),
                  tap(t => this.refreshInProcess = false)
                );
          }

        } else {
          return of(token);
        }

      }),
      catchError( (err, obs) => {
        this.refreshInProcess = false;
        if( err instanceof AuthenticateError){
          return throwError(new RefreshTokenExpired('your session expired', 'please login again'));
        }
        return throwError(err);
      }),
    );
  }

  updateUsersAuthenticationTokens(tokens: TokenRefreshResponse, user: User){
    const accessToken = plainToClass(Token, tokens.accessToken);
    const refreshToken = isDefined(tokens.refreshToken) ? plainToClass(Token, tokens.refreshToken) : undefined;
    return AuthenticatedUserManager.setUpdatedRefreshTokens(user, accessToken, refreshToken).pipe(first());
  }


}
