import { Exclude, Expose, Type } from 'class-transformer';
import { BeforeInsert, BeforeUpdate, Column, Entity, ManyToOne, PrimaryGeneratedColumn} from 'typeorm';
import { TreeEntity } from '../TreeEntity';
import { Account} from '../Account';
import { User} from '../User';
import { isDefined } from 'src/app/util/util.function';
import {v4 as uuidv4} from 'uuid';


@Entity({name: 'accountRole'})
export class AccountRole extends TreeEntity {

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

/**
 *
 * @var delegate_api id
 */
  @Expose({ name: 'id', toClassOnly: true })
  @Column({ unique: true , nullable: true})
  dId: number;

  @Column({ nullable: true})
  tick: number;

  @Column({ nullable: false, unique: true})
  uuid: string;

  @Column()
  name: string;

  @Expose( {groups: ['showAccountRole']})
  @Type(() => Account)
  @ManyToOne(type => Account, {onDelete: 'CASCADE', nullable: false})
  account: Account;

  @Expose( {groups: ['showAccountRole']})
  @Type(() => User)
  @ManyToOne(type => User, {onDelete: 'SET NULL', nullable: true, eager: true})
  user: User;

  @Expose( {groups: ['showAccountRole']})
  @Exclude({ toClassOnly: true })
  @Column({nullable: true})
  userId: number;

  @Expose( {groups: ['showAccountRole']})
  @Column({nullable: true})
  totalQuestions: number;

  @Expose( {groups: ['showAccountRole']})
  @Column({nullable: true})
  registrationLink: string;

  @Expose( {groups: ['showAccountRole']})
  @Column({nullable: true})
  expirationTime: number;

  @Expose( {groups: ['showAccountRole']})
  @Column()
  createdAt: number;

  @Expose( {groups: ['showAccountRole']})
  @Column()
  updatedAt: number;


  @Expose( {groups: ['showAccountRole']})
  @Column({default: false})
  deleted: boolean;

  @Expose({ name: 'id', toPlainOnly: true })
  getRemoteId(){
    return this.dId;
  }


  @BeforeInsert()
  setDates() {
    if (!isDefined(this.updatedAt)) {
      this.updatedAt = Math.round(Date.now() / 1000);
    }
    if (!isDefined(this.createdAt)) {
      this.createdAt = Math.round(Date.now() / 1000);
    }
  }

  @BeforeInsert()
  setUuid(){
    if (!isDefined(this.uuid)) {
      this.uuid = uuidv4();
    }
  }

  @BeforeUpdate()
  setUpdated(){
    this.updatedAt = Math.round(Date.now()/1000);
  }
}
