import { Observable } from 'rxjs';
import { first, filter } from 'rxjs/operators';
import { User } from 'src/app/entities/User';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';


export class UserStateQuery{

  static getRawUserStream(){
    return AppStateStore.user;
  }

  static getAuthenticatedUser(): Observable<User>{
    return AppStateStore.user.pipe(first(u => isDefined(u)));
  }

  static getAuthenticatedUserStream(): Observable<User>{
    return AppStateStore.user.pipe(filter(u => isDefined(u)));
  }


}
