import { Observable, from, throwError } from 'rxjs';
import { switchMap, first, map, timeout, catchError } from 'rxjs/operators';
import { EntityRepository, Repository } from 'typeorm';
import { Token } from '../entities/Access/Token';
import { User } from '../entities/User';
import { StorageService, StorageUser } from '../services/local-persistance/storage.service';
import { Unauthorized } from '../services/security/exceptions/security.error';
import { RoleAccessLevel } from '../services/security/role.accesslevel';
import { DBStateQuery } from '../store/query/db.state.query';
import { isDefined } from '../util/util.function';

@EntityRepository()
export class UserRepository extends Repository<User> {

  /**
   * only executes when db becomes ready
   * returns a single User from Database if he exists and has a valid refreshToken
   * emits once, then dies
   *
   * @returns Observable<User>
   * @throws Unauthorized Error (if no logged in User is found). This will complete the observable.
   * @throws Timeout Error (if takes longer than 2000ms). This will complete the observable.
   */
  static getOneLoggedInUserFromDb(): Observable<User>{
    return DBStateQuery.firstReady().pipe(
      switchMap(() => from(User.createQueryBuilder('user')
          .leftJoinAndSelect('user.refreshToken', 'refreshToken')
          .leftJoinAndSelect('user.accessToken', 'accessToken')
          .leftJoinAndSelect('user.account', 'account')
          .leftJoinAndSelect('account.legalForm', 'legalForm')
          .leftJoinAndSelect('account.headquarters', 'headquarters')
          .leftJoinAndSelect('headquarters.name', 'headquartername')
          .leftJoinAndSelect('account.employeeCount', 'employeeCount')
          .leftJoinAndSelect('account.industryBranch', 'industryBranch')
          .leftJoinAndSelect('industryBranch.name', 'industrybranchname')
          .leftJoinAndSelect('account.business', 'business')
          .leftJoinAndSelect('business.name', 'businessname')
          .leftJoinAndSelect('account.trade', 'trade')
          .leftJoinAndSelect('trade.name', 'tradename')
          .where('user.refreshToken is not null')
          .andWhere('refreshToken.expires > :currentTime',{currentTime: Math.floor(Date.now() / 1000)})
          .getOne()).pipe(
            first(),
            map( user => {
              if(isDefined(user)){
                return user;
              }else{
                throw new Unauthorized('Unauthorized', 'please login');
              }
              }
            )
      ))).pipe(first(),timeout(2000), catchError(err => throwError(err)));
  }




  static getAllEditors(accountId: number){
    const roles = ['ROLE_EDITOR'];
    return DBStateQuery.firstReady().pipe(
      switchMap(() => from(
        User.createQueryBuilder('user')
        .where('user.accountId = :accountId', {accountId})
        .getMany()
      )),
      first(),
      map(users => users.filter(u => u.roles.includes(RoleAccessLevel.editor)))
    );
  }

  /**
   * Gets user from either Storage (web) or database (mobile) once.
   * emits once, then completes
   * MOBILE:
   * If request takes too long (e.g. because database takes too long to initialize)
   * or there is no authenticated user observable, erros out (ie. dies).
   * WEB:
   * Gets user with valid refresh token from Storage or throws unauthorized
   *
   * @returns User with a valid accessToken
   * @throws Unauthorized Error (if no logged in User is found)
   * @throws TimeoutError (only mobile) (if takes longer than 1000ms). This will complete the observable.
   */
   static findOneAuthenticatedUserDbOrStorage(inDb: boolean): Observable<User> {
      if (inDb) {
        return DBStateQuery.firstReady().pipe( switchMap(() => UserRepository.getOneLoggedInUserFromDb()));
      } else {
        return StorageService.getLoggedInUser()
          .pipe(
            map((user: StorageUser) => {
              const newInstance = new User();
              newInstance.dId = user.dId;
              newInstance.uuid = user.uuid;
              newInstance.accessToken = user.accessToken;
              newInstance.refreshToken = user.refreshToken;
              return newInstance;
            })
          );
      }
    }


  /**
   * @var inDb: true -> tries to save to the database once it becomes ready.
   * If it does not become ready or request takes too long, throws timeoutError.
   * Emits only once
   * @var inDb: false -> saves to local storage
   * @returns Observable<T> emits only once
   * @throws Timeout Error (if takes longer than 5000ms). This will complete the observable.
   */
  static persistRemoteData(user: User, inDB: boolean) {
    if(inDB){
      return  DBStateQuery.firstReady().pipe(
          switchMap( () =>
            from(Token.deleteExpiredTokens()
            .then(() => User.saveRemoteTree(user)
            ))
            .pipe(first(), timeout(5000))
          )
      );
    }else{
     return from(StorageService.storeUser( new StorageUser().map(user)).then(_=> user)).pipe(first());
    }
  }

  static persistLocalData(user: User, inDB: boolean) {
    if(inDB){
      return  DBStateQuery.firstReady().pipe(
          switchMap( () =>
            from(Token.deleteExpiredTokens()
            .then(() => User.saveTree(user)
            ))
            .pipe(first(), timeout(5000))
          )
      );
    }else{
     return from(StorageService.storeUser( new StorageUser().map(user)).then(_=> user)).pipe(first());
    }
  }



}
