import { Exclude, Expose } from 'class-transformer';
import {BeforeInsert, Column, Entity,  PrimaryGeneratedColumn} from 'typeorm';
import { TreeEntity } from './TreeEntity';
import {v4 as uuidv4} from 'uuid';
import { isDefined } from '../util/util.function';

@Entity({name: 'translation'})
export class Translation extends TreeEntity {

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Column({ nullable: false, unique: true})
  uuid: string;

/**
 * @var delegate_api id
 */
  @Expose({name: 'id',toClassOnly:true})
  @Column({unique: true,  nullable: true})
  dId: number;

  @Column({ nullable: true})
  tick: number;

  @Column({nullable: true})
  de: string;

  @Column({nullable: true})
  en: string;


  @Expose({ name: 'id', toPlainOnly: true })
  getRemoteId(){
    return this.dId;
  }

  @BeforeInsert()
  setUuid(){
    if (!isDefined(this.uuid)) {
      this.uuid = uuidv4();
    }
  }


}
