/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { plainToClass } from 'class-transformer';
import { first, map, switchMap } from 'rxjs/operators';
import { DelegationStateTransition } from 'src/app/entities/State/DelegationStateTransition';
import { ProgressStateTransition } from 'src/app/entities/State/ProgressStateTransition';
import { TransitionRepository } from 'src/app/repositories/transition.repository';
import { DelegateRestService } from './delegate.rest.service';
import { StateResponse } from './payload/state.response';

@Injectable({
  providedIn: 'root'
})
export class RestState{

  constructor(
    protected readonly rest: DelegateRestService,
    protected readonly platform: Platform
  ){}

  getStatesForQuestion(dId: number){
    return this.rest.get<StateResponse>(`/restapi/state/question/${dId}`, {auth: 'oauth2'}).pipe(
      first(),
      map(stateResponse => [
        ...plainToClass(DelegationStateTransition, stateResponse.delegationStates, {groups: ['showState','showAccountRole', 'showUser']}) || [],
        ...plainToClass(ProgressStateTransition, stateResponse.progressStates, {groups: ['showState','showUser']}) || []
      ]),
      switchMap(states => TransitionRepository.persistRemoteData(states, this.platform.is('capacitor')))
    );
  }

  getTransitionsTeam(){
    return this.rest.get<StateResponse>(`/restapi/state/team`, {auth: 'oauth2'}).pipe(
      first(),
      map(stateResponse => [
        ...plainToClass(DelegationStateTransition, stateResponse.delegationStates, {groups: ['showState','showAccountRole', 'showUser']}) || [],
        ...plainToClass(ProgressStateTransition, stateResponse.progressStates, {groups: ['showState','showUser']}) || []
      ]),
      switchMap(states => TransitionRepository.persistRemoteData(states, this.platform.is('capacitor')))
    );
  }

  getTransitionsTeamMember(){
    return this.rest.get<StateResponse>(`/restapi/states/editor`, {auth: 'oauth2'}).pipe(
      first(),
      map(stateResponse => [
        ...plainToClass(DelegationStateTransition, stateResponse.delegationStates, {groups: ['showState','showAccountRole', 'showUser']}) || [],
        ...plainToClass(ProgressStateTransition, stateResponse.progressStates, {groups: ['showState','showUser']}) || []
      ]),
      switchMap(states => TransitionRepository.persistRemoteData(states, this.platform.is('capacitor')))
    );
  }


}
