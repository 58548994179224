/* eslint-disable max-len */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DelegateHttpInterface } from './delegate.http.interface';
import { DelegateRequestOptions } from './request/delegate.requestoptions';
import { catchError, retryWhen, tap, timeout } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {  BadGatewayError, BadRequestError, ConflictError, ForbiddenError, NotFoundError, UnauthorizedError, UnprocessableEntityError } from '../exceptions/HttpError';
import { toAngularOptions } from './request/to.angular.options';
import { StatusCodes } from 'http-status-codes';
import { isDefined } from 'src/app/util/util.function';

@Injectable({
  providedIn: 'root'
})
export class DelegateHttpImpl implements DelegateHttpInterface {

  private static readonly retryCount: number = 1;

  constructor(private readonly http: HttpClient) { }

  get<T>(path: string, delegateOptions: DelegateRequestOptions): Observable<T> {
    return this.http.get<T>(path, toAngularOptions(delegateOptions)).pipe(
      // tap((req) => console.log(`new get request to: ${path}`)),
      retryWhen(errors => {
        let retries = 0;
        return errors.
          pipe(
            tap((err: HttpErrorResponse) => {

              if(isDefined(err.error)){
                console.warn(
                  `get request to ${err.url} failed:
                 ${err.error.error ?? err.status} -
                 ${err.error.message ?? err.statusText}`
                 );
              }else{
                console.warn(   `get request to ${err.url} failed: ${err.statusText}`);
              }

              this.handleError(err);
              if (retries === DelegateHttpImpl.retryCount) {
                throw err;
              }
              retries++;
            })
          );
      }
      ),
      timeout(30000),
      catchError( err => throwError(err))
    );
  }
  post<T>(path: string, body?: string, delegateOptions?: DelegateRequestOptions): Observable<T> {
    return this.http.post<T>(path, body, toAngularOptions(delegateOptions)).pipe(
      // tap((req) => console.log(`new post request to: ${path}`)),
      retryWhen(errors => {
        let retries = 0;
        return errors.
          pipe(
            tap((err: HttpErrorResponse) => {
              if(isDefined(err.error)){
                console.warn(
                  `post request to ${err.url} failed:
                 ${err.error.error ?? err.status} -
                 ${err.error.message ?? err.statusText}`
                 );
              }else{
                console.warn(`get request to ${err.url} failed: ${err.statusText}`);
              }
              this.handleError(err);
              if (retries === DelegateHttpImpl.retryCount) {
                throw err;
              }
              retries++;
            })
          );
      }
      ),
      timeout(30000),
      catchError( err => throwError(err))
    );
  }

  /**
   * all handled errors fail immidiately
   */
  handleError(err: HttpErrorResponse) {
    switch (true) {
      case err.status === StatusCodes.BAD_REQUEST:
        throw new BadRequestError(err.error.message, err.error);
      case err.status === StatusCodes.CONFLICT:
        throw new ConflictError(err.error.message, err.error);
      case err.status === StatusCodes.UNPROCESSABLE_ENTITY:
        throw new UnprocessableEntityError(err.error.message, err.error);
      case err.status === StatusCodes.UNAUTHORIZED :
        throw new UnauthorizedError(err.error.message, err.error);
      case err.status === StatusCodes.FORBIDDEN:
        throw new ForbiddenError(err.error.message, err.error);
      case err.status === StatusCodes.NOT_FOUND:
        throw new NotFoundError(err.message, {error: 'not found', message: err.message});
      case err.status === StatusCodes.BAD_GATEWAY ||
           err.status === StatusCodes.GATEWAY_TIMEOUT ||
           err.status === StatusCodes.SERVICE_UNAVAILABLE:
      throw new BadGatewayError(err.status, err.error.message, err.error);
    }
  }

}
