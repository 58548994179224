import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TimeoutError } from 'rxjs';
import { ConnectionNotFoundError } from 'typeorm';
import { ForbiddenError, HttpError, HttpRequestError, UnauthorizedError } from './provider/rest/exceptions/HttpError';
import { AuthenticationService } from './services/security/authentication.service';
import { SecurityError, Unauthorized } from './services/security/exceptions/security.error';
import { SynchronizationService } from './services/synchronization.service';
import { AppStateStore } from './store/app.state.store';
import { isDefined } from './util/util.function';

interface AlertEntry {
  alert: HTMLIonAlertElement;
  title: string;
  message: string;
  cnt: number;
}



@Injectable({
  providedIn: 'root'
})
export class AppErrorHandler implements ErrorHandler{


  private displayedAlerts: Array<AlertEntry> = [];


  constructor(
    private readonly alertCtr: AlertController,
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly translator: TranslateService,
    private readonly snychronizationService: SynchronizationService,
    private readonly authenticationService: AuthenticationService,
    private readonly platform: Platform
) {}

  static unwrapError(error: any){
    if (error.hasOwnProperty('rejection')) {
      return error.rejection;
    }
    return error;
  }

  handleError(error: any): void {

    error = AppErrorHandler.unwrapError(error);

    if(error instanceof ConnectionNotFoundError){
      this.displayAlert('No Database Access', 'No Database Access');
      return;
    }

    if(error instanceof UnauthorizedError){
      this.ngZone.run(()=>this.authenticationService.logout().toPromise().then(() => {
        this.snychronizationService.stopSync();
        this.displayAlert(error.responseBody.error, error.responseBody.message);
      }));
      return;
    }


    if(error instanceof ForbiddenError){
      this.displayAlert(
        error.responseBody.error ?? error.responseBody.title ?? 'unknown error',
      error.responseBody.message ?? error.responseBody.detail
      );
      return;
    }

    if(error instanceof HttpRequestError){
      this.displayAlert(error.responseBody.error, error.responseBody.message);
      return;
    }

    if(error instanceof HttpError){
      this.displayAlert('Network Error', error.message);
      return;
    }

    if(error instanceof HttpErrorResponse){
      if(error.status === 0){
        this.displayAlert(
          this.translator.instant('error.network.title'),
          this.translator.instant('error.network.message')
          );
        return;
      }

      if(error.status === 500){
        this.displayAlert(
          this.translator.instant('error.internal_server_error.title'),
          this.translator.instant('error.internal_server_error.message')
          );
        return;
      }

      if(isDefined(error.error)){
        this.displayAlert(error.error.error, error.error.message);
      }else{
        this.displayAlert('Http Error', error.statusText);
      }
      return;
    }


    if(error instanceof Unauthorized){
      this.ngZone.run(async ()=>{
        AppStateStore.reset();
        this.snychronizationService.stopSync();
        this.router.navigate(['/authenticate']);
      });
      return;
    }

    if(error instanceof SecurityError){
      if(AppStateStore.user.getValue()){
        this.ngZone.run(()=>this.authenticationService.logout().toPromise().then(() => {
          this.snychronizationService.stopSync();
          this.displayAlert(error.title, error.message);
        }));

      }

      return;
    }

    if(error instanceof TimeoutError){
      this.displayAlert(this.translator.instant('error.network_timout.title'),this.translator.instant('error.network_timout.message'));
      return;
    }

    if(isDefined(error.message) && error.message.includes('Cannot match any routes')){
      if(this.platform.is('capacitor')){
        this.router.navigate(['/app/start']);
      }else{
        this.router.navigate(['/browser/tasks']);
      }
      return;
    }

    console.warn('Ooops', error);

    return;


  }

  private async displayAlert(title: string, message: string) {
    const alertEntry: AlertEntry = this.displayedAlerts.filter(e => e.title === title && e.message === message)[0];
    if(alertEntry === undefined) {
     const alert = await this.alertCtr.create({
            header: title,
            message,
            buttons: [
                {
                    text: 'Ok'
                }
            ]
        });

      this.displayedAlerts.push({alert, title, message, cnt: 1});
      await alert.present();
      alert.onDidDismiss().then(() => this.displayedAlerts = this.displayedAlerts.filter(e => e.title !== title && e.message !== message));

    } else {
        alertEntry.cnt++;
        alertEntry.alert.header = `${alertEntry.title} (${alertEntry.cnt})`;
    }
}
}
