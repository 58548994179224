import { Expose, Transform, Type } from 'class-transformer';
import { isDefined } from 'src/app/util/util.function';
import {BeforeInsert, Column, ManyToOne} from 'typeorm';
import { TreeEntity } from '../TreeEntity';
import { User } from '../User';



export abstract class AbstractStateTransition extends TreeEntity {

   /**
    * @var delegate_api id
    */
  @Expose({ name: 'id', toClassOnly: true})
  @Column({ unique: true , nullable: true})
  dId: number;

  @Column({ nullable: true})
  tick: number;

  @Transform( ({ value }) => value.uuid, { toClassOnly: true } )
  @Expose( {name: 'question',groups: ['showState']})
  @Column({nullable: false})
  question: string;

  @Expose({toClassOnly: true})
  @Column({nullable: true})
  questionTitle: string;

  @Expose( {groups: ['showState']})
  @Type(() => User)
  @ManyToOne(type => User, {onDelete: 'CASCADE', eager: true})
  user: User;

  @Expose( {groups: ['showState']})
  @Column({nullable: false})
  toState: string;

  @Expose( {groups: ['showState']})
  @Column()
  createdAt: number;


  @Expose({ name: 'id', toPlainOnly: true })
  getRemoteId(){
    return this.dId;
  }

  @BeforeInsert()
  setDates(){
    if(!isDefined(this.createdAt)){
      this.createdAt = Math.round(Date.now()/1000);
    }
  }

  @Expose({ name: 'question', groups: ['showState'], toPlainOnly: true })
  getQuestion() {
    return {
      uuid: this.question
    };
  }




}
