import { Exclude, Expose, Type } from 'class-transformer';
import { BeforeInsert, BeforeUpdate, Column, Entity, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { isDefined } from '../util/util.function';
import { TreeEntity } from './TreeEntity';
import {v4 as uuidv4} from 'uuid';
import { Account } from './Account';



@Entity()
export class ZWHReport extends TreeEntity{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Column({ nullable: true})
  tick: number;

  /**
   * @var delegate_api id
   */
  @Expose({ name: 'id', toClassOnly: true })
  @Column({ nullable: true, unique: true })
  dId: number;

  @Column({ nullable: false, unique: true})
  uuid: string;

  @Expose( {groups: ['showZwhReport']})
  @Type(() => Account)
  @ManyToOne(() => Account, {onDelete: 'CASCADE'})
  account: Account;

  @Exclude({ toPlainOnly: true })
  @Column()
  accountId: number;

  @Expose( {groups: ['showZwhReport']})
  @Column()
  year: number;

  @Expose( {groups: ['showZwhReport']})
  @Column()
  createdAt: number;

  @Expose( {groups: ['showZwhReport']})
  @Column()
  updatedAt: number;

  @Expose( {groups: ['showZwhReport']})
  @Column({default: false})
  deleted: boolean;


  @Expose({ name: 'id', toPlainOnly: true })
  getRemoteId(){
    return this.dId;
  }

  @BeforeInsert()
  setDates() {
    if (!isDefined(this.updatedAt)) {
      this.updatedAt = Math.round(Date.now() / 1000);
    }
    if (!isDefined(this.createdAt)) {
      this.createdAt = Math.round(Date.now() / 1000);
    }
  }

  @BeforeInsert()
  setUuid(){
    if (!isDefined(this.uuid)) {
      this.uuid = uuidv4();
    }
  }

  @BeforeUpdate()
  setUpdated(){
    this.updatedAt = Math.round(Date.now()/1000);
  }

}
