import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DelegateHttpImpl } from './provider/rest/http/delegate.http.impl';
import { DELEGATE_HTTP_INTERFACE } from './provider/rest/http/delegate.http.interface';
import { TOKEN_MANAGER_INTERFACE } from './provider/rest/tokenmanager/token.manager.interface';
import { TokenManagerImpl } from './provider/rest/tokenmanager/token.manager.impl';
import { AppErrorHandler } from './app.error.handler';
import { AuthGuard } from './services/security/auth.guard';
import { MasterOnlyGuard } from './services/security/master.only.guard';
import { AppOnlyGuard } from './services/security/app.only.guard';
import { SQLiteService } from './services/local-persistance/sqlite.service';
import { StorageService } from './services/local-persistance/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot(
      {
        loader:{
          provide: TranslateLoader,
          useFactory:(http: HttpClient) => new TranslateHttpLoader(http, '../assets/i18n/','.json'),
          deps: [HttpClient]
        }
      }
    )
  ],
  providers: [
    AuthGuard,
    MasterOnlyGuard,
    AppOnlyGuard,
    SQLiteService,
    StorageService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: DELEGATE_HTTP_INTERFACE,
      useClass: DelegateHttpImpl
    },
    {
      provide: TOKEN_MANAGER_INTERFACE,
      useClass: TokenManagerImpl
    }

  ],
  bootstrap: [AppComponent],
  exports:[
    TranslateModule
  ]
})
export class AppModule {}
