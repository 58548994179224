import { from, of } from 'rxjs';
import { exhaustMap, first, map, switchMap, timeout } from 'rxjs/operators';
import { EntityRepository, Repository } from 'typeorm';
import { AccountRole } from '../entities/Access/AccountRole';
import { DBStateQuery } from '../store/query/db.state.query';
import { UserStateQuery } from '../store/query/user.state.query';

@EntityRepository()
export class AccountRoleRepository extends Repository<AccountRole> {



  /**
   * @var inDb: true -> tries to save to the database once it becomes ready.
   * If it does not become ready or request takes too long, throws timeoutError.
   * Emits only once
   * @var inDb: false -> saves to local storage
   * @returns Observable<T> emits only once
   * @throws Timeout Error (if takes longer than 5000ms). This will complete the observable.
   */
     static persistRemoteDate(entity: AccountRole, inDB: boolean) {
      if(inDB){
        return  DBStateQuery.firstReady().pipe( exhaustMap(_=> from(AccountRole.saveRemoteTree(entity)).pipe(first(), timeout(5000))));
      }else{
        return of(entity);
      }
    }


    static persistLocalData(entity: AccountRole, inDB: boolean) {
      if(inDB){
        return  DBStateQuery.firstReady().pipe( exhaustMap(_=> from(AccountRole.saveTree(entity)).pipe(first(), timeout(5000))));
      }else{
        return of(entity);
      }
    }



  static findById(accountRoleId: number){
    return DBStateQuery.firstReady().pipe(
      switchMap(() => UserStateQuery.getAuthenticatedUser()),
      switchMap(user => from( AccountRole.createQueryBuilder('role')
        .leftJoinAndSelect('role.user', 'user')
        .where('role.accountId = :accountId', { accountId: user.accountId})
        .andWhere('role.id = :accountRoleId',{ accountRoleId})
        .andWhere('role.deleted = 0')
        .getOne()
      ))
      );
  }

  static findByUuid(uuid: string){
    return DBStateQuery.firstReady().pipe(
      switchMap(() => UserStateQuery.getAuthenticatedUser()),
      switchMap(user => from( AccountRole.createQueryBuilder('role')
        .leftJoinAndSelect('role.user', 'user')
        .where('role.accountId = :accountId', { accountId: user.accountId})
        .andWhere('role.uuid = :uuid',{ uuid})
        .andWhere('role.deleted = 0')
        .getOne()
      ))
      );
  }

  static getAllAccountRoles(accountId: number){
    return DBStateQuery.firstReady().pipe(
      switchMap(() => from(
        AccountRole.createQueryBuilder('role')
        .leftJoinAndSelect('role.user', 'user')
        .where('role.accountId = :accountId', {accountId})
        .andWhere('role.deleted = 0')
        .getMany()
      )),
      first()
    );
  }
}
