import { from, Observable, of } from 'rxjs';
import { exhaustMap, switchMap, first, map, timeout } from 'rxjs/operators';
import { EntityRepository, Repository, IsNull, Not } from 'typeorm';
import { User } from '../entities/User';
import { ZWHReport } from '../entities/ZWHReport';
import { StorageService } from '../services/local-persistance/storage.service';
import { DBStateQuery } from '../store/query/db.state.query';
import { UserStateQuery } from '../store/query/user.state.query';
import { isDefined } from '../util/util.function';

@EntityRepository(ZWHReport)
export class ZWHReportRepository extends Repository<ZWHReport> {

  /**
   * @var inDb: true -> tries to save to the database once it becomes ready.
   * If it does not become ready or request takes too long, throws timeoutError.
   * Emits only once
   * @var inDb: false -> saves to local storage
   * @returns Observable<T> emits only once
   * @throws Timeout Error (if takes longer than 5000ms). This will complete the observable.
   */
  static persistRemoteData(report: ZWHReport, inDB: boolean) {
    if (inDB) {
      return DBStateQuery.firstReady().pipe(
        exhaustMap(() => from(ZWHReport.saveRemoteTree(report)).pipe(first(), timeout(5000)))
      );
    } else {
      return of(report);
    }
  }

  static persistLocalData(entity: ZWHReport, inDB: boolean) {
    if (inDB) {
      return DBStateQuery.firstReady().pipe(exhaustMap(_ => from(ZWHReport.saveTree(entity)).pipe(first(), timeout(5000))));
    } else {
      return of(entity);
    }
  }


  /**
   * looks for a current report user default.
   * If set, looks for an entity with respective uuid in Db.
   * If one entity is found, it will be returned, otherwise it will look for any
   * existing report and get the one with the latest updates
   *
   * @Returns ZWHReport | null
   */
  findSelectedReportOrReturnLatestUpdated(user: User): Observable<ZWHReport> {
    return DBStateQuery.firstReady().pipe(
      switchMap(() => StorageService.getReportUuid(UserStateQuery.getRawUserStream().getValue()).pipe(
        exhaustMap(uuid =>
          isDefined(uuid)
            ? this.findOneByUuid(uuid, user).pipe(
              exhaustMap(r =>
                isDefined(r)
                  ? of(r)
                  : this.findAllReportsOfUser(user))
            )
            : this.findAllReportsOfUser(user)),
        map(rps =>
          Array.isArray(rps) && rps.length > 0
            ? rps.reduce((prev, current) => prev.updatedAt > current.updatedAt ? prev : current)
            : Array.isArray(rps) ? null : isDefined(rps) ? rps : null
        )
      ))
    );
  }




  findOneByUuid(uuid: string, user: User) {
    return DBStateQuery.firstReady().pipe(
      switchMap(() => {
        const relations = this.metadata.relations.map(m => m.propertyName);
        return from(this.findOne({ where: { uuid, account: user.account, deleted: false }, relations }));
      })
    );
  }


  findAllReportsOfUser(user: User) {
    return DBStateQuery.firstReady().pipe(
      switchMap(() => {
        const relations = this.metadata.relations.map(m => m.propertyName);
        return from(this.find({ where: { account: user.account, deleted: false }, relations }));
      })
    );
  }

  findAllReportIdsWhereDidNotNull(user: User) {
    return DBStateQuery.firstReady().pipe(
      switchMap(() => from(this.find({ select: ['id'], where: { account: user.account, dId: Not(IsNull()) }}))),
      map(reps => reps.map(r => r.id))
    );
  }




}
