import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DelegateRequestOptions } from './request/delegate.requestoptions';


export const DELEGATE_HTTP_INTERFACE: InjectionToken<DelegateHttpInterface> = new InjectionToken('InjectionToken für HttpAbstraktion');

export interface DelegateHttpInterface{
  get<T>(path: string, options: DelegateRequestOptions): Observable<T>;
  post<T>(path: string, body: string, options: DelegateRequestOptions): Observable<T>;
}
