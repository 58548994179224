import { from, of } from 'rxjs';
import { exhaustMap, first, switchMap, timeout } from 'rxjs/operators';
import { EntityRepository, Repository } from 'typeorm';
import { Account } from '../entities/Account';
import { DBStateQuery } from '../store/query/db.state.query';

@EntityRepository()
export class AccountRepository extends Repository<Account> {

  /**
   * @var inDb: true -> tries to save to the database once it becomes ready.
   * If it does not become ready or request takes too long, throws timeoutError.
   * Emits only once
   * @var inDb: false -> saves to local storage
   * @returns Observable<T> emits only once
   * @throws Timeout Error (if takes longer than 5000ms). This will complete the observable.
   */
     static persistRemoteData(entity: Account, inDB: boolean) {
      if(inDB){
        return  DBStateQuery.firstReady().pipe( exhaustMap(_=> from(Account.saveRemoteTree(entity)).pipe(first(), timeout(5000))));
      }else{
        return of(entity);
      }
    }

    static persistLocalData(entity: Account, inDB: boolean) {
      if(inDB){
        return  DBStateQuery.firstReady().pipe( exhaustMap(_=> from(Account.saveTree(entity)).pipe(first(), timeout(5000))));
      }else{
        return of(entity);
      }
    }

    static deleteAccount(entity: Account) {
      return DBStateQuery.firstReady().pipe(
        switchMap(() => Account.remove(entity))
      );
    }
}
