import { Exclude, Expose, Type } from 'class-transformer';
import { Column, Entity, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { EnergySource } from '../../Taxonomy/EnergySource';
import { Unit } from '../../Taxonomy/Unit';
import { AbstractQuestion } from '../AbstractQuestion';

@Entity()
export class QuestionTransportConsumption extends AbstractQuestion{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true, default: 0})
  consumed: number;

  @Expose( {groups: ['showQuestion']})
  @Type(()=>EnergySource)
  @ManyToOne(()=>EnergySource, {nullable: false, cascade:['insert','update'], eager: true})
  source: EnergySource;

  @Expose( {groups: ['showQuestion']})
  @Type(()=>Unit)
  @ManyToOne(()=>Unit, {nullable: true, eager: true})
  unit: Unit;

  constructor(){
    super();
  }
}
