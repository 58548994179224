import { Exclude, Expose, Type } from 'class-transformer';
import { isDefined } from 'src/app/util/util.function';
import { BeforeInsert, Column, Entity, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { AccountRole } from '../Access/AccountRole';
import { AbstractStateTransition } from './AbstractStateTransition';
import {v4 as uuidv4} from 'uuid';


export enum DelegationStates {
  assigned = 'assigned',
  returned = 'returned',
  revoked = 'accessRevoked'
}

@Entity()
export class DelegationStateTransition extends AbstractStateTransition{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Column({ nullable: false, unique: true})
  uuid: string;

  @Expose( {groups: ['showState']})
  @Type(() => AccountRole)
  @ManyToOne(type => AccountRole, {onDelete: 'CASCADE', eager: true})
  accountRole: AccountRole;

  @Expose( {groups: ['showState']})
  @Column({nullable: true})
  message: string;

  @Expose( {groups: ['showState']})
  @Column({nullable: true})
  deadline: number;

  @BeforeInsert()
  setUuid(){
    if (!isDefined(this.uuid)) {
      this.uuid = uuidv4();
    }
  }


}
