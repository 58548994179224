


import { Exclude, Expose, Type } from 'class-transformer';
import { Column, Entity,  ManyToOne,  PrimaryGeneratedColumn} from 'typeorm';
import { TreeEntity } from '../TreeEntity';
import { Translation } from '../Translation';

@Entity({name: 'meta'})
export class Meta extends TreeEntity {

  @Exclude({ toPlainOnly: true })
  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  version: string;

  @Column({ nullable: true})
  tick: number;

/**
 * @var delegate_api id
 */
  @Expose({name: 'id',toClassOnly:true})
  @Column({unique: true})
  dId: number;

  @Column()
  quid: string;

  @Column()
  className: string;

  @Exclude({ toPlainOnly: true })
  @Column()
  category: string;

  @Exclude({ toPlainOnly: true })
  @Column()
  level: number;

  @Exclude({ toPlainOnly: true })
  @Column()
  sequenceNumber: number;

  @Exclude({ toPlainOnly: true })
  @Type(() => Translation)
  @ManyToOne(() => Translation, { eager: true})
  title: Translation;

  @Exclude({ toPlainOnly: true })
  @Type(() => Translation)
  @ManyToOne(() => Translation, {nullable: true, eager: true})
  subtitle: Translation;

  @Exclude({ toPlainOnly: true })
  @Type(() => Translation)
  @ManyToOne(() => Translation, {nullable: true,eager: true})
  description: Translation;

  @Exclude({ toPlainOnly: true })
  @Type(() => Translation)
  @ManyToOne(() => Translation, {nullable: true,eager: true})
  info: Translation;


  @Expose({ name: 'id', toPlainOnly: true })
  getRemoteId(){
    return this.dId;
  }
}
