import { filter, map } from 'rxjs/operators';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';


export class AccountRoleStateQuery{

  static getRawRoleStream(){
    return AppStateStore.accountRoles;
  }

  static getRolesStream(){
    return AppStateStore.accountRoles.pipe(
      filter(roles => isDefined(roles)),
      map(roles => roles.filter(r => !r.deleted))
    );
  }

  static getUserRolesStream(uuid: string){
    return AccountRoleStateQuery.getRolesStream().pipe(
      map(roles => roles.filter(r => r?.user?.uuid === uuid && r.deleted === false))
    );
  }

}
