export class NoClassObjectForQuidFound extends Error {
  constructor(message: string) {
      super(message);
      Object.setPrototypeOf(this, NoClassObjectForQuidFound.prototype);
      this.name = this.constructor.name;
  }
  dump() {
      return { message: this.message, stack: this.stack };
  }
}
