import { Exclude } from 'class-transformer';
import { Entity, PrimaryGeneratedColumn } from 'typeorm';
import { AbstractQuestion } from '../AbstractQuestion';

@Entity()
export class QuestionContainer extends AbstractQuestion{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  constructor(){
    super();
  }
}
