import { Exclude, Type } from 'class-transformer';
import { Entity, ManyToOne, PrimaryGeneratedColumn} from 'typeorm';
import { Translation } from '../Translation';
import { AbstractTaxonomy } from './AbstractTaxonomy';

@Entity({name: 'industryBranch'})
export class IndustryBranch extends AbstractTaxonomy{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Exclude({ toPlainOnly: true })
  @Type(() => Translation)
  @ManyToOne(() => Translation, {nullable: false, eager: true})
  name: Translation;

  toString(): string {
      return this.name.de;
  }

}
