/* eslint-disable no-console */
import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, Platform } from '@ionic/angular';
import { RoutingService } from './services/routing.service';

import { TaxonomyService } from './services/taxonomy.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { SQLiteService } from './services/local-persistance/sqlite.service';
import { ReportService } from './services/report.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {

  private supportedLanguages = ['de', 'en'];

  constructor(
    private readonly translateService: TranslateService,
    private readonly sqliteService: SQLiteService,
    private readonly reportService: ReportService,
    private readonly routinService: RoutingService,
    private readonly platform: Platform,
    private readonly taxonomyService: TaxonomyService,
    private readonly alertCtrl: AlertController,
  ) {

    this.configureTranslation();
    this.platform.ready()
    .then(async () => {
      await this.initApp();
      await this.syncDatabase();
      SplashScreen.hide();
    });

  }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  private configureTranslation() {
    this.translateService.addLangs(this.supportedLanguages);
    this.translateService.setDefaultLang('de');
    const browserlang = this.translateService.getBrowserLang();
    this.translateService.use(browserlang);
  }


  private async initApp() {
    await this.sqliteService.initializePlugin();
  }


  private async syncDatabase() {
    this.setTaxonomy();
    this.setMeta();
  }

  private async setMeta(){
    this.reportService.autoSetReportMeta().subscribe();
  }

  private async setTaxonomy(){
   this.taxonomyService.autoSet().subscribe();
  }




}
