import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { classToPlain, plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Account } from 'src/app/entities/Account';
import { AccountRepository } from 'src/app/repositories/account.repository';
import { DelegateRestService } from './delegate.rest.service';
import { AccountResponse } from './payload/account.response';

@Injectable({
  providedIn: 'root'
})
export class RestAccount{

  constructor(
    protected readonly rest: DelegateRestService,
    protected readonly platform: Platform
  ){}

  getAuthenticatedUsersAccount(): Observable<Account>{
    return this.rest.get<AccountResponse>('/restapi/account/mine', { auth: 'oauth2' }).pipe(
      first(),
      map((accRes: AccountResponse) => plainToClass(Account, accRes)),
      switchMap( account => AccountRepository.persistRemoteData(account, this.platform.is('capacitor'))),
    );
  }

  updateAccount(entity: Account): Observable<Account> {
    return this.rest.post<AccountResponse>(
      '/restapi/account/update',
      classToPlain(entity, {exposeUnsetFields: false}),
      {auth: 'oauth2'}).pipe(
      first(),
      map((accRes: AccountResponse) => plainToClass(Account,accRes)),
      switchMap( account => AccountRepository.persistRemoteData(account, this.platform.is('capacitor'))),
    );
  }


  deleteAccount(){
    return this.rest.post('/restapi/account/delete',{},{auth:'oauth2'}).pipe(
      first()
    );
  }

}
