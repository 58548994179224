/* eslint-disable max-len */
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { exhaustMap, first, timeout } from 'rxjs/operators';
import { EntityRelation, Syncdirection, SyncEntry } from 'src/app/entities/Sync/SyncEntry';
import { TreeEntity } from 'src/app/entities/TreeEntity';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';
import { Synchronizable } from './synchronizable';



export abstract class AbstractSynchronizer<T extends TreeEntity> implements Synchronizable<T>{

  constructor(protected readonly platform: Platform) { }

  protected notifySyncSuccess<U>(ctor: abstract new (...args: any[]) => U, updated: T[], direction: Syncdirection, nextSyncInSeconds?: number): void;
  protected notifySyncSuccess<U>(ctor: new (...args: any[]) => U, updated: T[], direction: Syncdirection, nextSyncInSeconds?: number): void {
    this.setSyncEntry(ctor, direction, nextSyncInSeconds);
    // console.log(`synced ${ctor.name} successfully ${direction}`);
  }

  protected notifySyncFailure<U>(ctor: abstract new (...args: any[]) => U, error: Error, direction: Syncdirection): void
  protected notifySyncFailure<U>(ctor: new (...args: any[]) => U, error: Error, direction: Syncdirection): void {
    // console.log(`${direction} sync for ${ctor.name} failed. Reason: ${error.message}`);
  }

  /**
   * WEB: simply returns null.
   * MOBILE: waits for db to become ready,
   * gets last syncdate and returns sync updates if any,
   * if relations are provided, default behaviour for relations returned from "getRelations()" will be overwritten
   * emits only once
   *
   * @returns Observable<T> | null
   * @throws HttpErrors
   * @throws Timeout Error (mobile ony) if db takes longer than 5000ms. This will complete the observable.
   */
  protected getPendingUpdateFor(ctor: new (...args: any[]) => T, entity: T, relations?: EntityRelation[]): Observable<T> | null {
    if (!isDefined(entity)) {
      return null;
    }
    if (this.platform.is('capacitor')) {
      return AppStateStore.dbReady.pipe(
        first(ready => ready),
        exhaustMap(_ => SyncEntry.getUpdateFor(ctor, entity.dId, relations ?? this.getRelations())),
        timeout(5000)
      );
    } else {
      return of(null);
    }
  }

  protected getPendingUpdateForDiD(ctor: new (...args: any[]) => T, dId: number, relations?: EntityRelation[]): Observable<T> | null {
    if (this.platform.is('capacitor')) {
      return AppStateStore.dbReady.pipe(
        first(ready => ready),
        exhaustMap(_ => SyncEntry.getUpdateFor(ctor, dId, relations ?? this.getRelations())),
        timeout(5000)
      );
    } else {
      return of(null);
    }
  }

  protected getPendingUpdates(ctor: new (...args: any[]) => T, relations?: EntityRelation[]): Observable<T[]> {
    if (this.platform.is('capacitor')) {
      return AppStateStore.dbReady.pipe(
        first(ready => ready),
        exhaustMap(_ => SyncEntry.getUpdatesFor(ctor, relations ?? this.getRelations())),
        timeout(5000)
      );
    } else {
      return of([]);
    }
  }

  /**
   * sets default behaviour for entity to find relations in the local db. If no relations should be considered by default, return null
   * returns Entity relations or null
   */
  protected getRelations(): EntityRelation[] | null {
    return null;
  }

  /**
   * use to set current synch date of current entity type
   */
  protected setSyncEntry<U>(ctor: abstract new (...args: any[]) => U, direction: Syncdirection, nextSyncInSeconds?: number): void;
  protected setSyncEntry<U>(ctor: new (...args: any[]) => U, direction: Syncdirection, nextSyncInSeconds?: number): void {
    const type = ctor.name;
    if (this.platform.is('capacitor')) {
      AppStateStore.dbReady.pipe(first()).subscribe(ready => {
        if (ready) {
          new SyncEntry(type, direction, nextSyncInSeconds ?? this.getSyncInterval()).save();
        }
      });
    }
  }



  abstract executeSync(): Observable<T[]>;

  /**
   * updates all entities with changes since last sync. (Mobile only)
   * If entity param is provided returns that entity else returns array of updated entities
   * needs to notify sync success or failure
   */
  protected abstract commit(entity?: T): Observable<T | T[]>;


  protected abstract getSyncInterval(): number;








}
