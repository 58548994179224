import { state } from '@angular/animations';
import { Stats } from 'fs';
import { combineLatest, Observable } from 'rxjs';
import { MapOperator } from 'rxjs/internal/operators/map';
import { first, map, switchMap } from 'rxjs/operators';
import { AbstractStateTransition } from 'src/app/entities/State/AbstractStateTransition';
import { DelegationStateTransition } from 'src/app/entities/State/DelegationStateTransition';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';
import { AccountRoleStateQuery } from './account.role.state.query';
import { AccountRoleViewModelQuery } from './account.role.view.model.query';
import { EditorStateQuery } from './editor.state.query';

export class TransitionStateQuery{

  static getRawTransitionStream(){
    return AppStateStore.questionTransitions;
  }

  static getTeamTransitions(){
    return AppStateStore.user.pipe(
      first(user => isDefined(user)),
      switchMap(user => AppStateStore.questionTransitions.pipe(
          map( statesMaps => {
           const stateArray: AbstractStateTransition[] = [];
           const maps =  Array.from(statesMaps.values());
           maps.forEach(questionStateMap => stateArray.push(...Array.from(questionStateMap.values())));
           return stateArray.filter(transition => transition?.user?.uuid !== user?.uuid).sort((a,b) => b.createdAt - a.createdAt);
          })
        ))
    );
  }

  static getMyTransitions(){
    return AppStateStore.user.pipe(
      first(user => isDefined(user)),
      switchMap(user => AppStateStore.questionTransitions.pipe(
          map( statesMaps => {
           const stateArray: AbstractStateTransition[] = [];
           const maps =  Array.from(statesMaps.values());
           maps.forEach(questionStateMap => stateArray.push(...Array.from(questionStateMap.values())));
           return stateArray.filter(transition => transition?.user?.uuid === user?.uuid);
          })
        ))
    );
  }

  static getTransitionsForUuid(uuid: string): Observable<Map<string, AbstractStateTransition>>
  {
    return AppStateStore.questionTransitions.pipe(
      map( stateMap => {
        if(stateMap.has(uuid)){
          return stateMap.get(uuid);
        }
        return new Map<string, AbstractStateTransition>();
      })
    );
  }

  static getTransitionsForUuidWithAccountRole(uuid: string){
      return combineLatest([
        AccountRoleViewModelQuery.getRolesWithUser(),
        EditorStateQuery.getAllUsersStream(),
        TransitionStateQuery.getTransitionsForUuid(uuid)
      ]).pipe(
        map( ([roles, users, states]) => {
          const enrichtedMap: Map<string, AbstractStateTransition> = new Map();
          states.forEach( (v,k) => {
            if(v instanceof DelegationStateTransition &&  v?.accountRole?.hasOwnProperty('uuid')){
              const role = roles.find( r => r.uuid === v?.accountRole?.uuid);
              if(isDefined(role)){
                v.accountRole = role;
              }
            }

           const user =  users.find( u => u.uuid === v?.user?.uuid);
           if(isDefined(user)){
            v.user = user;
           }

           enrichtedMap.set(k, v);
          });
          return enrichtedMap;
        })
      );
  }

  static getTeamTransitionsWithAccountRole(){
    return combineLatest([
      AccountRoleViewModelQuery.getRolesWithUser(),
      EditorStateQuery.getAllUsersStream(),
      TransitionStateQuery.getTeamTransitions()
    ]).pipe(
      map( ([roles, users, states]) => states.map( (transition) => {
          if(transition instanceof DelegationStateTransition &&  transition?.accountRole?.hasOwnProperty('uuid')){
            const role = roles.find( r => r.uuid === transition.accountRole?.uuid);
            if(isDefined(role)){
              transition.accountRole = role;
            }
          }
         const user =  users.find( u => u.uuid === transition?.user?.uuid);
         if(isDefined(user)){
          transition.user = user;
         }
         return transition;
        }))
    );
}

static getMyTransitionsWithAccountRole(){
  return combineLatest([
    AccountRoleViewModelQuery.getRolesWithUser(),
    EditorStateQuery.getAllUsersStream(),
    TransitionStateQuery.getMyTransitions()
  ]).pipe(
    map( ([roles, users, states]) => states.map( (transition) => {
        if(transition instanceof DelegationStateTransition &&  transition?.accountRole?.hasOwnProperty('uuid')){
          const role = roles.find( r => r.uuid === transition?.accountRole?.uuid);
          if(isDefined(role)){
            transition.accountRole = role;
          }
        }
       const user =  users.find( u => u.uuid === transition?.user?.uuid);
       if(isDefined(user)){
        transition.user = user;
       }
       return transition;
      }))
  );
}
}
