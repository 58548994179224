/* eslint-disable max-len */
import { Injectable } from '@angular/core';
// eslint-disable-next-line max-len
import { SQLiteConnection, CapacitorSQLite, CapacitorSQLitePlugin} from '@capacitor-community/sqlite';

import { Platform } from '@ionic/angular';
import { initial1644187130501 } from 'src/app/migrations/1644187130501-initial';
import { sync1651805077952 } from 'src/app/migrations/1651805077952-sync';
import { AppStateStore } from 'src/app/store/app.state.store';
import {Connection,createConnection, getConnection } from 'typeorm';
import { CapacitorConnectionOptions } from 'typeorm/driver/capacitor/CapacitorConnectionOptions';
import { entities } from './sqlite.service.entities';

@Injectable({
  providedIn: 'root'
})
export class SQLiteService{


  databaseName = 'NachhaltigkeitsApp';
  sqlite: CapacitorSQLitePlugin;
  sqliteConnection: SQLiteConnection;
  db: Connection;

  constructor(private platform: Platform) {
  }


  getCapacitorConnectionOptions(sync: boolean = false): Promise<CapacitorConnectionOptions>{
    if(this.sqliteConnection != null){
     return Promise.resolve({
        type: 'capacitor',
        driver: this.sqliteConnection,
        database: this.databaseName,
        synchronize: sync,
        entities,
        migrationsRun:true,
        migrations:[
          sync1651805077952,
          initial1644187130501
      ]

    });
    }else{Promise.reject();}
  }
/**
 * Plugin Initialization
 */
  async initializePlugin(): Promise<Connection> {
      if(this.platform.is('capacitor')){
        this.sqlite = CapacitorSQLite;
        if(this.sqliteConnection == null){
           this.sqliteConnection = new SQLiteConnection(this.sqlite);
        }
        const options = await this.getCapacitorConnectionOptions(false);
        const con = await this.createTypeormConnection(options);
        return con;
      }
  }


  async createTypeormConnection(options: CapacitorConnectionOptions): Promise<Connection> {

    if(this.sqliteConnection == null){
     throw Error('Error creating db connection. Sqlite Plugin not initialized');
    }

    let connection: Connection;
    try{
      connection = getConnection();
    }catch(e){
      console.warn('no existing connection found', e);
    }

    if(connection == null) {
      try{
        connection = await createConnection(options);
      }catch(e){
        console.warn(e.message);
      }
    }

    /**
     * fall back when capacitor plugin does not clean up the connection properly;
     */
    if(connection == null){
      try{
        await CapacitorSQLite.closeConnection({database: this.databaseName});
        const connectionOptions = await this.getCapacitorConnectionOptions(false);
        connection = await createConnection(connectionOptions);
      }catch(e){
        throw Error('Error creating db connection'+JSON.stringify(e));
      }
    }

    if(connection != null){
      this.db = connection;
      AppStateStore.dbReady.next(true);
    }

    return connection;
  }


  async closeConnection(): Promise<void> {
      if(this.db != null){
        await this.db.close();
        await CapacitorSQLite.closeConnection({database: this.databaseName});
      }
      return;
  }

  async openConnection(): Promise<void> {
    if(this.db != null){
      await this.db.connect();
    }
    return;
}

  async getConnection(): Promise<Connection>{
    if(this.db != null){
      return this.db;
    }else{
      const options = await this.getCapacitorConnectionOptions(false);
      const con = await this.createTypeormConnection(options);
      return con;
    }
  }
}
