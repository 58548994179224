export class SecurityError extends Error {
  constructor(readonly title: string, message: string) {
    super(message);
    Object.setPrototypeOf(this, SecurityError.prototype);
    this.name = this.constructor.name;
  }
}

export class Unauthorized extends SecurityError {
  constructor(title: string, message: string) {
    super(title, message);
    Object.setPrototypeOf(this, Unauthorized.prototype);
    this.name = this.constructor.name;
  }
}

export class MasterOnly extends Unauthorized {
  constructor(title: string, message: string) {
    super(title, message);
    Object.setPrototypeOf(this, MasterOnly.prototype);
    this.name = this.constructor.name;
  }

}

export class RefreshTokenExpired extends SecurityError {
  constructor(title: string, message: string) {
    super(title, message);
    Object.setPrototypeOf(this, RefreshTokenExpired.prototype);
    this.name = this.constructor.name;
  }
}
