/* eslint-disable @typescript-eslint/naming-convention */

import { AbstractQuestion } from 'src/app/entities/Questions/AbstractQuestion';
import { QuestionMaterialHandling } from 'src/app/entities/Questions/Material/QuestionMaterialHandling';
import { QuestionElectricityConsumption } from 'src/app/entities/Questions/Ressources/QuestionElectricityConsumption';
import { QuestionHeatConsumption } from 'src/app/entities/Questions/Ressources/QuestionHeatConsumption';
import { QuestionTransportConsumption } from 'src/app/entities/Questions/Ressources/QuestionTransportConsumption';
import { QuestionWaterConsumption } from 'src/app/entities/Questions/Ressources/QuestionWaterConsumption';
import { AbstractStateTransition } from 'src/app/entities/State/AbstractStateTransition';
import { DelegationStates } from 'src/app/entities/State/DelegationStateTransition';
import { ProgressStates } from 'src/app/entities/State/ProgressStateTransition';
import { User } from 'src/app/entities/User';
import { isDefined } from 'src/app/util/util.function';
import { AccountRole } from '../../entities/Access/AccountRole';

export interface TaskListStateViewModel{
  icon: string;
  text: string;
  seen: boolean;
}

export enum QuestionProgressState{
  Created = 'created',
  InProgress = 'inProgress',
  Completed = 'completed'
}


export type Progress = keyof typeof QuestionProgressState;

export class QuestionStateHelper{

  constructor(){}


  static getTimeToDeadline(deadlineInSec: number){
    const miliSecDeadline = deadlineInSec * 1000;
    const deadlineDate = new Date(miliSecDeadline);
    const currentDate = new Date();
    const difference = deadlineDate.getTime() - currentDate.getTime();
    const days = Math.ceil(difference / (1000 * 3600 * 24));
    return days;
  }

  static deadlineHasPassed(question: AbstractQuestion){
    return isDefined(question?.deadlineDelegation)
    && question?.deadlineDelegation > 0
    && QuestionStateHelper.getTimeToDeadline(question.deadlineDelegation) < 0;
  }

  static isDelegated(question: AbstractQuestion){
    return isDefined(question.accountRole);
  }

  static hasStates(question: AbstractQuestion, logic: 'or' | 'and',...states: Progress[]){
    const results = [];
    states.forEach( key =>  {
      if(!isDefined(question.progressState) && key === 'Created'){
        results.push(true);
      }else{
        results.push(QuestionProgressState[key] === question.progressState);
      }
    });
    return logic === 'or' ? results.includes(true) : !results.includes(false);
  }

  static getDisplayName(accountRole: AccountRole){
    if(!isDefined(accountRole.user)){
      return accountRole.name;
    }

    return isDefined(accountRole.user.firstname) && isDefined(accountRole.user.lastname)
          ? accountRole.user.firstname + ' ' + accountRole.user.lastname
          : accountRole.name;
  }

  static getRoleDisplayName(user?: User){
    if(!isDefined(user)){
      return 'frei';
    }

    if(isDefined(user?.firstname)){
      return user?.firstname + ' ' + user?.lastname;
    }

    if(isDefined(user?.dId)){
      return user?.username;
    }

    return 'unknown';
  }


  static getInitialsFromUserOrAccountRole(accountRole: AccountRole){
    if(!isDefined(accountRole.user)){
      return accountRole?.name?.slice(0,2);
    }

    if(isDefined(accountRole.user.firstname)){
      return accountRole.user.firstname.charAt(0) + accountRole.user.lastname?.charAt(0);
    }else{
      return accountRole?.name?.slice(0,2);
    }
  }

  static getInitialsFromUser(user: User){
    if(isDefined(user?.firstname) && user?.lastname){
      return user?.firstname.charAt(0) + user?.lastname?.charAt(0);
    }else if(isDefined(user?.firstname)){
      return user.firstname?.slice(0,2);
    }else if(isDefined(user?.lastname)){
      return user.lastname?.slice(0,2);
    }else{
      return user?.username.slice(0,2);
    }
  }

  static getDisplaynameFromUser(user: User){
    if(isDefined(user?.firstname) && user?.lastname){
      return user?.firstname + ' ' + user?.lastname;
    }else if(isDefined(user?.firstname)){
      return user.firstname;
    }else if(isDefined(user?.lastname)){
      return user.lastname;
    }else{
      return user?.username;
    }
  }

  static  getQuestionTitle(question: AbstractQuestion){
    switch(question.meta.quid){
          case 'material.inventory':
            return question.meta.title.de + ' '+ (question as QuestionMaterialHandling)?.material?.name?.de;
          case 'electricity.consumption':
            return question.meta.title.de + ' '+(question as QuestionElectricityConsumption)?.source?.name?.de;
          case 'heat.consumption':
             return question.meta.title.de + ' '+(question as QuestionHeatConsumption)?.source?.name?.de;
          case 'transport.consumption':
            return question.meta.title.de + ' - Verbrauch '+(question as QuestionTransportConsumption)?.source?.name?.de;
          case 'water.consumption':
            return question.meta.title.de + ' '+(question as QuestionWaterConsumption)?.source?.name?.de;
          default: return question.meta.title.de;
    }
  }

  static getStatusTransitionVerb(state: AbstractStateTransition): string{
    switch(state.toState){
      case ProgressStates.completed: return 'abgeschlossen';
      case ProgressStates.created: return 'erstellt';
      case ProgressStates.inProgress: return 'bearbeitet';
      case DelegationStates.assigned: return 'delegiert';
      case DelegationStates.returned: return 'zurückgegeben';
      case DelegationStates.revoked: return 'entzogen';
    }
  }

  static getStatusTransitionVerbEditor(state: AbstractStateTransition): string{
    switch(state.toState){
      case ProgressStates.completed: return 'Beantwortet';
      case ProgressStates.created: return 'Erstellt';
      case ProgressStates.inProgress: return 'Bearbeitet';
      case DelegationStates.assigned: return 'Delegiert';
      case DelegationStates.returned: return 'Zurückgegeben';
      case DelegationStates.revoked: return 'Entzogen';
    }
  }

  static getNewsText(state: AbstractStateTransition, question: AbstractQuestion): string{
        return QuestionStateHelper.getDisplaynameFromUser(state.user) +
        ' hat '+ QuestionStateHelper.getQuestionTitle(question) +
        ' ' + QuestionStateHelper.getStatusTransitionVerb(state);
  }


  static getWebQuestionViewModel(question?: AbstractQuestion): TaskListStateViewModel{
    switch(true){
        case question?.progressState === 'completed':
          return {
            icon: 'assets/icon/state/completed.svg',
            text: 'Erledigt',
            seen: true
          };
        case (question?.progressState === 'created' || question?.progressState === 'inProgress')
        && QuestionStateHelper?.deadlineHasPassed(question):
          const daysOverdue = QuestionStateHelper?.getTimeToDeadline(question?.deadlineDelegation) * -1;
          return {
            icon: 'assets/icon/state/overdue.svg',
            text: 'Seit '+ daysOverdue + (daysOverdue > 1 ? ' Tagen ' : ' Tag ') + 'überfällig',
            seen: true
          };
        default :
          return {
            icon: '/assets/icon/info-dynamic.svg',
            text: 'Bitte beantworten Sie die Frage und klicken "Abschicken" um die Frage abzuschließen',
            seen: true
          };

    }
  }


  static getTeamTaskViewModel(question: AbstractQuestion): TaskListStateViewModel{
    switch(true){

      case QuestionStateHelper.hasStates(question, 'and', 'Completed'):
        return {
          icon: 'assets/icon/state/completed.svg',
          text: 'Erledigt',
          seen: true
        };

        case QuestionStateHelper.hasStates(question, 'or', 'InProgress', 'Created')
        && QuestionStateHelper.deadlineHasPassed(question):
          const daysOverdue = QuestionStateHelper.getTimeToDeadline(question.deadlineDelegation) * -1;
          return {
            icon: 'assets/icon/state/overdue.svg',
            text: 'Seit '+ daysOverdue + (daysOverdue > 1 ? ' Tagen ' : ' Tag ') + 'überfällig',
            seen: true
          };
        case QuestionStateHelper.hasStates(question, 'or', 'InProgress', 'Created')
        && !QuestionStateHelper.deadlineHasPassed(question):
          return {
            icon: '',
            text: 'Warten auf Bearbeitung',
            seen: true
          };
    }
  }

  static getDelegationTaskViewModel(question: AbstractQuestion): TaskListStateViewModel{
    switch(true){

      case QuestionStateHelper.hasStates(question, 'and', 'Completed'):
        return {
          icon: 'assets/icon/state/completed.svg',
          text: QuestionStateHelper.getDisplayName(question?.accountRole) + ' - Erledigt',
          seen: true
        };

        case QuestionStateHelper.hasStates(question, 'or', 'InProgress', 'Created')
        && QuestionStateHelper.deadlineHasPassed(question):
          const daysOverdue = QuestionStateHelper.getTimeToDeadline(question.deadlineDelegation) * -1;
          return {
            icon: 'assets/icon/state/overdue.svg',
            text: QuestionStateHelper.getDisplayName(question?.accountRole) +
            ' - Seit '+ daysOverdue + (daysOverdue > 1 ? ' Tagen ' : ' Tag ') + 'überfällig',
            seen: true
          };
        case QuestionStateHelper.hasStates(question, 'or', 'InProgress', 'Created')
        && !QuestionStateHelper.deadlineHasPassed(question):
          return {
            icon: '',
            text: QuestionStateHelper.getDisplayName(question?.accountRole) + ' - Warten auf Bearbeitung',
            seen: true
          };
    }
  }

  static getTaskListStateViewModel(question: AbstractQuestion): TaskListStateViewModel{

    switch(true){

      case !QuestionStateHelper.isDelegated(question) && QuestionStateHelper.hasStates(question, 'and', 'Created'):
        return {
          icon:'',
          text:'Aufgabe bearbeiten',
          seen: false
        };

      case !QuestionStateHelper.isDelegated(question) && QuestionStateHelper.hasStates(question, 'and', 'InProgress'):
        return {
          icon:'',
          text:'Weiter bearbeiten',
          seen: true
        };

      case !QuestionStateHelper.isDelegated(question) && QuestionStateHelper.hasStates(question, 'and', 'Completed'):
        return {
          icon: 'assets/icon/state/completed.svg',
          text: 'Erledigt',
          seen: true
        };

      case QuestionStateHelper.isDelegated(question) && QuestionStateHelper.hasStates(question, 'and', 'Completed'):
        return {
          icon: 'assets/icon/state/delegated_completed.svg',
          text: 'Erledigt',
          seen: true
        };

      case QuestionStateHelper.isDelegated(question)
            && QuestionStateHelper.hasStates(question, 'or', 'InProgress', 'Created')
            && QuestionStateHelper.deadlineHasPassed(question):

         const daysOverdue = QuestionStateHelper.getTimeToDeadline(question.deadlineDelegation) * -1;

        return {
          icon: 'assets/icon/state/delegated_overdue.svg',
          text: 'Seit '+ daysOverdue + (daysOverdue > 1 ? ' Tagen ' : ' Tag ') + 'überfällig',
          seen: true
        };

      case QuestionStateHelper.isDelegated(question)
            && QuestionStateHelper.hasStates(question, 'or', 'InProgress', 'Created')
            && !QuestionStateHelper.deadlineHasPassed(question):

          const to = QuestionStateHelper.getDisplayName(question.accountRole);
          return {
            icon: 'assets/icon/state/delegated_unfinished.svg',
            text: 'Delegiert an ' +to,
            seen: true
          };

    }
  }




}
