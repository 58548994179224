import { Injectable } from '@angular/core';
import { TaxonomyManager } from '../store/manager/taxonomy.manager';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService {

  constructor(
    private readonly taxonomyManager: TaxonomyManager
  ) { }


  /**
   * first checks if in memory (subject), then checks if in database (typeorm)
   */
  autoSet(){
    return this.taxonomyManager.autoSet();
  }
}
