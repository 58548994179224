import { QuestionContainer } from 'src/app/entities/Questions/Container/QuestionContainer';
import { QuestionEmployees } from 'src/app/entities/Questions/Employees/QuestionEmployees';
import { Meta } from 'src/app/entities/Questions/Meta';
import { QuestionElectricityConsumption } from 'src/app/entities/Questions/Ressources/QuestionElectricityConsumption';
import { QuestionHeatConsumption } from 'src/app/entities/Questions/Ressources/QuestionHeatConsumption';
import { QuestionTransportConsumption } from 'src/app/entities/Questions/Ressources/QuestionTransportConsumption';
import { QuestionWaterConsumption } from 'src/app/entities/Questions/Ressources/QuestionWaterConsumption';
import { DelegationStateTransition } from 'src/app/entities/State/DelegationStateTransition';
import { ProgressStateTransition } from 'src/app/entities/State/ProgressStateTransition';
import { EnergySource } from 'src/app/entities/Taxonomy/EnergySource';
import { Unit } from 'src/app/entities/Taxonomy/Unit';
import { WaterSource } from 'src/app/entities/Taxonomy/WaterSource';
import { Translation } from 'src/app/entities/Translation';
import { AccountRole } from '../../entities/Access/AccountRole';
import { Token } from '../../entities/Access/Token';
import { Account } from '../../entities/Account';
import { QuestionMaterialHandling } from '../../entities/Questions/Material/QuestionMaterialHandling';
import { SyncEntry } from '../../entities/Sync/SyncEntry';
import { Business } from '../../entities/Taxonomy/Business';
import { EmployeeCount } from '../../entities/Taxonomy/EmployeeCount';
import { Headquarters } from '../../entities/Taxonomy/Headquarters';
import { IndustryBranch } from '../../entities/Taxonomy/IndustryBranch';
import { LegalForm } from '../../entities/Taxonomy/LegalForm';
import { Material } from '../../entities/Taxonomy/Material';
import { Trade } from '../../entities/Taxonomy/Trade';
import { User } from '../../entities/User';
import { ZWHReport } from '../../entities/ZWHReport';

export const entities = [
  User,
  Account,
  AccountRole,
  Token,
  LegalForm,
  Business,
  EmployeeCount,
  Headquarters,
  IndustryBranch,
  Trade,
  Unit,
  Material,
  EnergySource,
  WaterSource,
  SyncEntry,
  ZWHReport,
  Meta,
  Translation,
  QuestionContainer,
  QuestionEmployees,
  QuestionMaterialHandling,
  QuestionElectricityConsumption,
  QuestionHeatConsumption,
  QuestionWaterConsumption,
  QuestionTransportConsumption,
  DelegationStateTransition,
  ProgressStateTransition
];
