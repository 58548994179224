/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { User } from '../../entities/User';
import { DelegateRestService } from '../../provider/rest/delegate.rest.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { RestUser, MasterRegistrationPayload, EditorRegistrationPayload } from 'src/app/provider/rest/rest.user';
import { Synchronizable } from 'src/app/store/synchronization/synchronizable';
import { AuthenticatedUserManager } from 'src/app/store/manager/authenticated.user.manager';
import { SynchronizationService } from '../synchronization.service';




/**
 * THIS CLASS SHOULDNT REFERENC ANY OTHER SERVICS IF POSSIBLE
 */

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements Synchronizable<User>{


  constructor(
    protected readonly rest: DelegateRestService,
    protected readonly platform: Platform,
    protected readonly tranlator: TranslateService,
    protected readonly router: Router,
    protected readonly userData: RestUser,
    protected readonly userStoreManager: AuthenticatedUserManager,
    protected readonly synchronizationService: SynchronizationService
  ) {
  }

  createNewMasterAccount(payload: MasterRegistrationPayload){
    return this.userStoreManager.createNewMasterAccount(payload);
  }

  createNewEditorAccount(payload: EditorRegistrationPayload, registrationToken: string){
    return this.userStoreManager.createNewEditorAccount(payload, registrationToken);
  }

  login(usernameORemail: string, password: string) {
    return this.userStoreManager.login(usernameORemail,password).pipe(
      first(),
      tap(user => this.synchronizationService.startSync())
    );
  }

  logout(){
    return this.userStoreManager.logout().pipe(
      tap(res => this.synchronizationService.stopSync()),
      tap(res => this.router.navigate(['/authenticate']))
      );
  }

  /**
   * sends http password reset request.
   */
  changePassword(email: string){
    return this.userData.changePassword(email);
  }

  /**
   * sends http password reset request.
   */
  resendConfirmationMail(){
    return this.userData.resendConfirmationMail();
  }

  // updateUsersAuthenticationTokens(user: User, accessToken: Token, refreshToken?: Token){
  //   return this.userStoreManager.setUpdatedRefreshTokens(user,accessToken,refreshToken);
  // }

  forceSetNewUser(user: User){
    return this.userStoreManager.setNewUser(user);
  }

  /**
   *
   * @param doctype agb | gdpr
   * @param version Version number / identifier
   * @param relative if true returns relative path, default returns absolut path
   * @returns url string
   */
  getLegalUrlFor(doctype: 'agb' | 'gdpr', version: string, relative: boolean = false){
    return `${relative ? '' : environment.remoteBaseUrl}/legal/${doctype}/${this.tranlator.currentLang}/${version}`;
  }

  autoLogin(){
    return this.userStoreManager.setAuthenticatedUserIfExists().pipe(
      first(),
      tap(user => this.synchronizationService.startSync())
    );
  }

  refresh(){
    return this.userStoreManager.refresh();
  }

  public update(user: User){
    return this.userStoreManager.update(user);
  }

  executeSync(): Observable<User[]> {
    return this.userStoreManager.executeSync();
  }

}
