/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, from, Subscription, timer } from 'rxjs';
import { concatAll, concatMap, exhaustMap, filter, first, switchMap, take, tap } from 'rxjs/operators';
import { Account } from 'src/app/entities/Account';
import { SyncEntry } from 'src/app/entities/Sync/SyncEntry';
import { TreeEntity } from 'src/app/entities/TreeEntity';
import { User } from 'src/app/entities/User';
import { ZWHReport } from 'src/app/entities/ZWHReport';
import { AccountManager } from 'src/app/store/manager/account.manager';
import { AuthenticatedUser, AuthenticatedUserManager } from 'src/app/store/manager/authenticated.user.manager';
import { EditorUserManager } from 'src/app/store/manager/editor.user.manager';
import { ReportManager } from 'src/app/store/manager/report.manager';
import { DBStateQuery } from 'src/app/store/query/db.state.query';
import { UserStateQuery } from 'src/app/store/query/user.state.query';
import { Synchronizable } from 'src/app/store/synchronization/synchronizable';
import { isDefined } from 'src/app/util/util.function';
import { AccountRole } from '../entities/Access/AccountRole';
import { AbstractQuestion } from '../entities/Questions/AbstractQuestion';
import { Meta } from '../entities/Questions/Meta';
import { AbstractStateTransition } from '../entities/State/AbstractStateTransition';
import { AccountRoleManager } from '../store/manager/account.role.manager';
import { QuestionManager } from '../store/manager/question.manager';
import { ReportMetaManager } from '../store/manager/report.meta.manager';
import { TransitionStateManager } from '../store/manager/transition.state.manager';


@Injectable({
  providedIn: 'root'
})
export class SynchronizationService implements OnDestroy {

  static syncResults: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private syncInterval: number = 60000;
  private subscription: Subscription;
  private synchronizers = new Map<string, Synchronizable<TreeEntity>>();
  private executionOrder: string[];
  private syncStarted = false;

  constructor(
    private readonly platform: Platform,
    authenticatedUserManager: AuthenticatedUserManager,
    editorUserManager: EditorUserManager,
    accountManager: AccountManager,
    reportManager: ReportManager,
    roleManager: AccountRoleManager,
    questionManager: QuestionManager,
    metaManager: ReportMetaManager,
    transitionManager: TransitionStateManager
    )
  {
    /**
     * order here determines sync order
     */
    this.synchronizers.set(User.name, editorUserManager);
    this.synchronizers.set(ZWHReport.name, reportManager);
    this.synchronizers.set(AccountRole.name, roleManager);
    this.synchronizers.set(AbstractQuestion.name, questionManager);
    this.synchronizers.set(AbstractStateTransition.name, transitionManager);

    this.synchronizers.set(Meta.name, metaManager);
    this.synchronizers.set(AuthenticatedUser.name, authenticatedUserManager);
    this.synchronizers.set(Account.name, accountManager);

    this.executionOrder = Array.from(this.synchronizers.keys());
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }


  // should return success or failure
  startSync(){
    if(this.platform.is('capacitor')){
      let now: number;
      if(this.syncStarted || isDefined(this.subscription)){
        return;
      }

      this.syncStarted = true;
      this.subscription = this.syncInInterval().subscribe( n => {
         UserStateQuery.getAuthenticatedUser().pipe(
          switchMap(() => SyncEntry.getLastSynced()),
          tap(() => now = Math.round(Date.now()/1000)),
          switchMap((entries: SyncEntry[]) => {
            const uniqueMap: Map<string, SyncEntry> = new Map();
            entries.forEach(e => uniqueMap.set(e.class, e));
            const sortedSet = Array.from(uniqueMap.values()).sort(this.compareFn.bind(this));
            return from(sortedSet);
            }),
          filter(sync => (now - sync.lastSync) >= sync.syncInterval && this.synchronizers.has(sync.class)),
          // tap(sync => console.log('SYNC SYNCING: ', sync.class)),
          concatMap( sync => this.synchronizers.get(sync.class).executeSync()),
         ).subscribe();
      });
    }
  }

  syncInInterval(){
    return DBStateQuery.firstReady().pipe(switchMap(() => timer(0,this.syncInterval)));
  }

  stopSync(){
    if(isDefined(this.subscription)){
      this.subscription.unsubscribe();
      this.syncStarted = false;
    }
  }



  private compareFn(a: SyncEntry, b: SyncEntry): number {
    const indexA = this.executionOrder.findIndex(className => className === a.class);
    const indexB = this.executionOrder.findIndex(className => className === b.class);

    if(indexB === -1){
      return -1;
    }

    if(indexA === indexB){
      return 0;
    }
    return indexA - indexB;
  }



}
