/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { AbstractQuestion } from 'src/app/entities/Questions/AbstractQuestion';
import { EntityRelation } from 'src/app/entities/Sync/SyncEntry';
import { isDefined } from 'src/app/util/util.function';
import { MissingMetaInformationError } from './MissingMetaInformationError';
import { NoClassObjectForQuidFound } from './NoClassObjectForQuidFound';
import { Quid, quidClassMap } from './quidClassMap';






@Injectable({
  providedIn: 'root'
})
export class QuestionTransformer {



  static syncingRelations: Record<string, EntityRelation[]> = {
    AbstractQuestion: [
      {
        propertyname: 'meta',
        alias: 'meta'
      },
      {
        propertyname: 'zwhReport',
        alias: 'zwh_report'
      },
      {
        propertyname: 'accountRole',
        alias: 'accountRole'
      }
    ],
    QuestionMaterialHandling: [
      {
        propertyname: 'material',
        alias: 'material'
      },
      {
        propertyname: 'material.name',
        alias: 'materialName'
      },
      {
        propertyname: 'unit',
        alias: 'unit'
      }
    ]
  };


  static plainToConcreteClass(questions: AbstractQuestion[]): AbstractQuestion[] {
    const result = [];
    questions.forEach(element => {
      if (!isDefined(element.meta) || !isDefined(element.meta.quid)) {
        throw new MissingMetaInformationError(
          'expected Meta information in element "\ ' + element.constructor.name + ' "\ but found undefined'
        );
      }
      const classObject = QuestionTransformer.getClassObject(element.meta.quid);
      if (!isDefined(classObject)) {
        throw new NoClassObjectForQuidFound(
          'couldn\'t find a corresponding class object for ' + element.meta.quid + '.' + 'Did you forget to define one in classMap?'
        );
      }

      result.push(plainToClass(classObject, element, { groups: ['showQuestion', 'showUser', 'showAccountRole', 'showZwhReport'] }));
    });

    return result;

  }


  static concreteClassToPlain(questions: AbstractQuestion[]): any[] {
    const result = [];
    questions.forEach(element => {
      if (!isDefined(element.meta) || !isDefined(element.meta.quid)) {
        throw new MissingMetaInformationError(
          'expected Meta information in element "\ ' + element.constructor.name + ' "\ but found undefined'
        );
      }
      const classObject = QuestionTransformer.getClassObject(element.meta.quid);
      if (!isDefined(classObject)) {
        throw new NoClassObjectForQuidFound(
          'couldn\'t find a corresponding class object for ' + element.meta.quid + '.' + 'Did you forget to define one in classMap?'
        );
      }

      result.push(classToPlain(element, { groups: ['showQuestion']}));
    });

    // console.log('TREE updates pre transform: ', questions);
    // console.log('TREE updates post transform: ', result);
    return result;

  }


  static getClassObject(quid: string) {
    return quidClassMap[quid];
  }

  static getUniqueClassObjects() {
    return [...new Set(Object.values(quidClassMap))];
  }

  static getInstance(quid: Quid): AbstractQuestion {
    return new quidClassMap[quid]();
  }

}
