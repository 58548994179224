/* eslint-disable quote-props */
/* eslint-disable max-len */

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { combineLatest, EMPTY, forkJoin, from, Observable, of, pipe, Subscription, throwError } from 'rxjs';
import { exhaustMap, filter, first, map, pairwise, switchMap, tap, timeout } from 'rxjs/operators';
import { AbstractQuestion } from 'src/app/entities/Questions/AbstractQuestion';
import { QuestionMaterialHandling } from 'src/app/entities/Questions/Material/QuestionMaterialHandling';
import { EntityRelation, SyncEntry } from 'src/app/entities/Sync/SyncEntry';
import { User } from 'src/app/entities/User';
import { ZWHReport } from 'src/app/entities/ZWHReport';
import { RestQuestion } from 'src/app/provider/rest/rest.questions';
import { QuestionRepository } from 'src/app/repositories/question.repository';
import { ZWHReportRepository } from 'src/app/repositories/zwhreport.repository';
import { QuestionTransformer } from 'src/app/services/questions/questionTransformer';
import { isDefined } from 'src/app/util/util.function';
import { getCustomRepository } from 'typeorm';
import { AppStateStore } from '../app.state.store';
import { AbstractStateManager } from './abstract.state.manager';


@Injectable({
  providedIn: 'root'
})
export class QuestionManager extends AbstractStateManager<AbstractQuestion> implements OnDestroy {

  static primaryRelations: Record<string, string[]> = {
    AbstractQuestion: ['accountRole', 'meta','zwhReport'],
    QuestionMaterialHandling: ['material', 'unit'],
  };



  private syncInterval = 300; // once every 5 minutes
  private newReportListener: Subscription;
  private noReportListener: Subscription;


  constructor(
    protected readonly platform: Platform,
    private readonly remoteQuestionProvider: RestQuestion
  ) {
    super(platform);

   this.newReportListener = this.onNewReportSelected().subscribe();
   this.noReportListener = this.onNoReportsSelected().subscribe();

  }

  ngOnDestroy(): void {
    if(isDefined(this.newReportListener)){
      this.newReportListener.unsubscribe();
    }

    if(isDefined(this.noReportListener)){
      this.noReportListener.unsubscribe();
    }
  }


  updateMany(questions: AbstractQuestion[]){
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      exhaustMap(() => QuestionRepository.persistLocalData(questions, this.platform.is('capacitor'))),
      switchMap((persistantQuestions: AbstractQuestion[]) => this.platform.is('capacitor')
        ? this.updateRemoteOnMobile(persistantQuestions)
        : this.updateRemoteOnWeb(persistantQuestions)
      )
    );
  }

  update(question: AbstractQuestion) {
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      exhaustMap(() =>  QuestionRepository.persistLocalData([question], this.platform.is('capacitor'))),
      switchMap((persistantQuestions: AbstractQuestion[]) => isDefined(persistantQuestions) && persistantQuestions.length > 0
      ? this.platform.is('capacitor') ? this.updateOneRemoteMobile(persistantQuestions.pop()) : this.updateOneRemoteWeb(persistantQuestions.pop())
      : EMPTY)
    );
  }

  getDelegatedQuestions(){
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() => this.remoteQuestionProvider.getDelegatedQuestionsForUser()),
      exhaustMap(qs =>  this.setQuestionsInBehaviorSubject(qs))
      );
  }


  executeSync(): Observable<AbstractQuestion[]> {
    return this.pushAndPullAll();
  }

  removeQuestionsFromStore(uuids: string[]){
    if(!isDefined(uuids) || uuids.length < 1){
      return;
    }
    const questions = AppStateStore.questions.getValue();
    const filtered = questions.filter(q =>  uuids.findIndex(uuid => uuid === q.uuid) === - 1);
    this.setQuestionsInBehaviorSubject(filtered);
  }

  //listen to new reports only
  protected  listenForNewReports() {
    return AppStateStore.report.pipe(
      pairwise(),
      filter(r => isDefined(r[1]) && isDefined(r[1].uuid)), //if next is actually a report
      filter(r => !isDefined(r[0]) || r[0].uuid !== r[1].uuid), //pass if if previous report was null   // pass if (new) ie.next report is different from report before
      map(r => r[1]),
      // tap(r => console.log('report set: ', r))
    );
  }

  protected listenForNullReports(){
    return AppStateStore.report.pipe(
      pairwise(),
      filter( r => isDefined(r[0]) && isDefined(r[0].uuid)), //if prev was a report
      filter( r => !isDefined(r[1])) // pass if no report is currently set
    );
  }

  protected  onNoReportsSelected(){
    return this.listenForNullReports().pipe(
      tap(report =>{
        if(report === null){AppStateStore.questions.next(null);}
      })
    );
  }



  protected  onNewReportSelected() {
   return this.listenForNewReports().pipe(
      filter(r => !isDefined(AppStateStore.questions.getValue()) || AppStateStore.questions.getValue().length < 1 || (AppStateStore.questions.getValue()[0].zwhReport.uuid !== r.uuid)),
      switchMap(report => {
        if (this.platform.is('capacitor')) {
          return this.getAllQuestionsFromDB(report);
        }else{
          return this.getAllQuestionsFromRemote(report);
        }
      }),
      exhaustMap(qs =>  this.setQuestionsInBehaviorSubject(qs))
    );
  }

  protected updateOneRemoteWeb(persistantQuestion: AbstractQuestion){
    return this.pushAndPull(persistantQuestion).pipe(
      tap(q => {
        if(isDefined(q)){
          of([q]).pipe(
            this.mergeQuestionsWithState(),
            this.invalidateStateCache(),
            switchMap(merged => this.setQuestionsInBehaviorSubject(merged))
          ).subscribe();
        }
      })
    );
  }

  protected updateRemoteOnWeb(persistantQuestions: AbstractQuestion[]){
    let serverQuestions: AbstractQuestion[];
    return this.pushAndPullMany(persistantQuestions).pipe(
      tap(serverQs => serverQuestions = serverQs ),
      this.mergeQuestionsWithState(),
      this.invalidateStateCache(),
      switchMap(merged => this.setQuestionsInBehaviorSubject(merged)),
      map(() => serverQuestions)
    );
  }

  protected updateOneRemoteMobile(persistantQuestion: AbstractQuestion){
    return of(persistantQuestion).pipe(
      tap(() => of([persistantQuestion]).pipe(
        this.mergeQuestionsWithState(),
        this.invalidateStateCache(),
        switchMap(merged => this.setQuestionsInBehaviorSubject(merged)),
        switchMap(() => this.pushAndPull(persistantQuestion)),
        map(q => [q]),
        this.mergeQuestionsWithState(),
        switchMap(merged => this.setQuestionsInBehaviorSubject(merged)),
        this.handleErrorsPlatformDependent(AbstractQuestion, 'single',null)
      ).subscribe())
    );
  }


  protected updateRemoteOnMobile(persistantQuestions: AbstractQuestion[]){
   return of(persistantQuestions).pipe(
      this.mergeQuestionsWithState(),
      this.invalidateStateCache(),
      tap(merged => this.setQuestionsInBehaviorSubject(merged)),
      tap(() => {
        this.commit().pipe(
          this.mergeQuestionsWithState(),
          first(),
          tap(serverQs => this.setQuestionsInBehaviorSubject(serverQs) ),
          this.handleErrorsPlatformDependent(AbstractQuestion,'up', []),
        ).subscribe();
      }),
      map(() => persistantQuestions)
    );
  }


  protected getAllQuestionsFromRemote(report: ZWHReport){
   return this.commmitQuestionsForReport(report).pipe(
     exhaustMap(()=> this.pullAll(report))
   );
  }


  protected getAllQuestionsFromDB(report: ZWHReport){
    return AppStateStore.dbReady.pipe(
      first(ready => ready),
      exhaustMap(() => {
        const observables: Observable<AbstractQuestion[]>[] = [];
        QuestionTransformer.getUniqueClassObjects().forEach(classObj =>
          observables.push(this.findQuestions(classObj, report))
        );
        return forkJoin(observables).pipe(
          map(arar => arar.flat(2))
          );
      })
    );
  }


  protected findQuestions(ctor: typeof AbstractQuestion, report: ZWHReport): Observable<AbstractQuestion[]> {

    switch(ctor.name){
      case QuestionMaterialHandling.name:
        return from(ctor.find({
          where:{
            zwhReportId: report.id,
            deleted: false
          },
          join: {
            alias: 'a',
            leftJoinAndSelect: {
              'accountRole': 'a.accountRole',
              'meta': 'a.meta',
              'zwhReport': 'a.zwhReport',
              'material': 'a.material',
              'materialName': 'material.name',
              'unit': 'a.unit',
              'unitName': 'unit.name'
            }
          }
        }));
      default: return from(ctor.find(
        {
          where: {
            zwhReportId: report.id,
            deleted: false
          },
          relations: [
            ...QuestionManager.primaryRelations[AbstractQuestion.name],
            ...QuestionManager.primaryRelations[ctor.name] ?? []
          ]
        }
       )
      );

    }
  }

  protected pullAll(report?: ZWHReport): Observable<AbstractQuestion[]> {
    return this.remoteQuestionProvider.getQuestions(report);
  }


  protected pull(dId: number): Observable<AbstractQuestion> {
    return this.remoteQuestionProvider.getQuestion(dId);
  }


  protected pushAndPull(entity: AbstractQuestion): Observable<AbstractQuestion> {
    return this.remoteQuestionProvider.updateQuestions([entity]).pipe(
      map(qs => isDefined(qs) && qs.length > 0 ? qs.pop() : null)
    );
  }

  protected pushAndPullMany(entities: AbstractQuestion[]): Observable<AbstractQuestion[]> {
    return this.remoteQuestionProvider.updateQuestions(entities);
  }


  protected pushAndPullAll(): Observable<AbstractQuestion[]> {
    return this.commit().pipe(
      switchMap(() => this.pullAll()),
      this.mergeQuestionsWithState(),
      switchMap(donwwardsUpdates => this.setQuestionsInBehaviorSubject(donwwardsUpdates)),
      tap(donwwardsUpdates => this.notifySyncSuccess(AbstractQuestion, donwwardsUpdates, 'down')),
      tap(donwwardsUpdates => this.notifySyncSuccess(AbstractQuestion, donwwardsUpdates, 'bidirectional')),
      this.handleErrorsPlatformDependent(AbstractQuestion, 'bidirectional', [])
      );
  }

  protected commit(entity?: AbstractQuestion): Observable<AbstractQuestion | AbstractQuestion[]> {
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(user => combineLatest([this.getReportIds(user),this.getAllPendingUpdates()])),
      map(([reportIds, updates]) => updates.filter(update => reportIds.includes(update.zwhReportId))),
      switchMap(currentReportUpdates => {
        if (isDefined(currentReportUpdates) && Array.isArray(currentReportUpdates) && currentReportUpdates.length > 0) {
          return this.remoteQuestionProvider.updateQuestions(currentReportUpdates).pipe(
            this.verifySuccess(AbstractQuestion, currentReportUpdates),
            map(updatedQuestions => {
              if (isDefined(entity)) {
                const index = updatedQuestions.findIndex(q => q.uuid === entity.uuid);
                if (isDefined(index) && index >= 0) {
                  return updatedQuestions[index];
                }
              }
              return entity ?? updatedQuestions;
            })
          );
        } else {
          return of(entity ?? []).pipe(tap(()=> this.notifySyncSuccess(AbstractQuestion, [], 'up')));
        }
      })
    );
  }


  protected commmitQuestionsForReport(report: ZWHReport): Observable<AbstractQuestion[]>{
   return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      exhaustMap(() => this.getAllPendingUpdates()),
      map(updates => updates.filter(update => update.zwhReportId === report.id)),
      switchMap(currentReportUpdates => {
        if (isDefined(currentReportUpdates) && Array.isArray(currentReportUpdates) && currentReportUpdates.length > 0) {
          return this.remoteQuestionProvider.updateQuestions(currentReportUpdates);
        } else {
          return of([]);
        }
      })
   );
  }


  protected getAllPendingUpdates(): Observable<AbstractQuestion[]> {
    if (this.platform.is('capacitor')) {
      return AppStateStore.dbReady.pipe(
        first(ready => ready),
        map(() => QuestionTransformer.getUniqueClassObjects()),
        switchMap(questionClasses => {
          const observables: Observable<AbstractQuestion[]>[] = [];
          questionClasses.forEach(qclass => {
            observables.push(this.getPendingUpdates(qclass, this.getSyncRelationsForAbstractQuestion(qclass)));
          });
          return forkJoin(observables);
        }),
        map(arar => arar.flat(2)),
      );
    } else {
      return of([]);
    }
  }

  protected getPendingUpdates(ctor: new (...args: any[]) => AbstractQuestion, relations?: EntityRelation[]): Observable<AbstractQuestion[]>{
    if (this.platform.is('capacitor')) {
      return AppStateStore.dbReady.pipe(
        first(ready => ready),
        exhaustMap(_ => SyncEntry.getUpdatesAbstractQuestion(ctor, relations ?? this.getRelations())),
        timeout(5000)
      );
    } else {
      return of([]);
    }
  }

  protected getSyncRelationsForAbstractQuestion(ctor: new (...args: any[]) => AbstractQuestion): EntityRelation[] | null {
    const baseRelations: EntityRelation[] = QuestionTransformer.syncingRelations[AbstractQuestion.name] ?? [];
    const addRelations:  EntityRelation[] = QuestionTransformer.syncingRelations[ctor.name] ?? [];
    const relations = [...baseRelations,...addRelations];
    return relations ?? null;
  }

  protected getSyncInterval(): number {
    return this.syncInterval;
  }

  private setQuestionsInBehaviorSubject(questions: AbstractQuestion[]) {
    if (isDefined(questions)) {

      if(questions.length > 0 && isDefined(AppStateStore.report.getValue())){
        AppStateStore.questions.next(questions.filter(q => q.zwhReport.uuid === AppStateStore.report.getValue().uuid));
        // console.log('set next set of Questions: ', questions);
      }else{
        AppStateStore.questions.next(questions);
      }

    }
    return of(questions);
  }




  private mergeQuestionsWithState(){
    let newQuestions: AbstractQuestion[];
    return pipe(
      switchMap((qs: AbstractQuestion[]) => {
        newQuestions = qs;
        return AppStateStore.questions;
      }),
      first(),
      map(existingQuestion => {
        const resultQuestionMap: Map<string, AbstractQuestion> = new Map();
        existingQuestion.forEach( q => resultQuestionMap.set(q.uuid, q));
        newQuestions.forEach(q => resultQuestionMap.set(q.uuid, q));
        return Array.from(resultQuestionMap.values());
      }),
    );
  }

  private invalidateStateCache(){
    return pipe(
      tap((qs: AbstractQuestion[]) => {
        // const transitions = AppStateStore.questionTransitions.getValue();
        // qs.forEach(q => transitions.delete(q.uuid));
        // AppStateStore.questionTransitions.next(transitions);
      })
    );
  }



  private getReportIds(user: User){
    return AppStateStore.dbReady.pipe(
      first(ready => ready),
      map(() => getCustomRepository(ZWHReportRepository)),
      switchMap(repo => repo.findAllReportIdsWhereDidNotNull(user))
      );
  }

}



