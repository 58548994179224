import { Exclude, Expose, Type } from 'class-transformer';
import {BeforeInsert, Column, Entity, ManyToOne, PrimaryGeneratedColumn} from 'typeorm';
import { Translation } from '../Translation';
import { AbstractTaxonomy } from './AbstractTaxonomy';
import {v4 as uuidv4} from 'uuid';
import { isDefined } from 'src/app/util/util.function';


@Entity({name: 'waterSource'})
export class WaterSource extends AbstractTaxonomy {

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Column({ nullable: false, unique: true})
  uuid: string;

  @Column({ nullable: true})
  tick: number;

  @Type(() => Translation)
  @ManyToOne(() => Translation, {nullable: false, cascade:['insert','update'], eager: true})
  name: Translation;


  @BeforeInsert()
  setUuid(){
    if (!isDefined(this.uuid)) {
      this.uuid = uuidv4();
    }
  }

  toString(): string {
      return this.name.de;
  }

}
