import { forkJoin, from, Observable, of } from 'rxjs';
import { exhaustMap, first, map, switchMap, tap } from 'rxjs/operators';
import { getConnection, Repository } from 'typeorm';
import { AbstractQuestion } from '../entities/Questions/AbstractQuestion';
import { TreeEntity } from '../entities/TreeEntity';
import { AppStateStore } from '../store/app.state.store';
import { DBStateQuery } from '../store/query/db.state.query';

export class QuestionRepository {


  static persistRemoteData(questions: AbstractQuestion[], inDB: boolean){
    if(inDB){
      return  DBStateQuery.firstReady().pipe(
        switchMap(() => {
          if (questions.length > 0 ) {
            const observables: Observable<AbstractQuestion>[] = [];
            questions.forEach(r => {
              observables.push(AppStateStore.dbReady.pipe(
                first(ready => ready),
                exhaustMap(() => from(r.saveRemoteTree()))
              ));
            });
            return forkJoin(observables).pipe(
              map( qs => qs.filter(q => q.id > -1) ),
              // tap(persists => persists.forEach(p => console.log('TREE PERSISTED: ', p)))
              );
          } else {
            return of(questions);
          }
        })
      );
    }else{
      return of(questions);
    }
  }


static persistLocalData(questions: AbstractQuestion[], inDB: boolean) {
  if(inDB){
    return  DBStateQuery.firstReady().pipe(
      switchMap(() => {
        if (questions.length > 0 ) {
          const observables: Observable<AbstractQuestion>[] = [];
          questions.forEach(r => {
            observables.push(AppStateStore.dbReady.pipe(
              first(ready => ready),
              exhaustMap(() => from(r.saveTree()))
            ));
          });
          return forkJoin(observables).pipe(
            // tap(persists => persists.forEach(p => console.log('TREE PERSISTED: ', p)))
            );
        } else {
          return of(questions);
        }
      })
    );
  }else{
   const modifiedQuestions = questions.map(q => {
      q.updatedAt = Math.round(Date.now()/1000);
      return q;
    });
    return of(modifiedQuestions);
  }
}


static getDbInstance<U extends AbstractQuestion>(obj: U): Observable<AbstractQuestion> {
  const repo: Repository<U> = getConnection().getRepository(obj.constructor);
  return from(repo.findOne({where: {uuid: obj.uuid}}));
}


}
