/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { classToPlain, plainToClass } from 'class-transformer';
import { forkJoin, Observable, of } from 'rxjs';
import { exhaustMap, first, map, switchMap, tap } from 'rxjs/operators';
import { User } from 'src/app/entities/User';
import { UserRepository } from 'src/app/repositories/user.repository';
import { RoleAccessLevel } from 'src/app/services/security/role.accesslevel';
import { isDefined } from 'src/app/util/util.function';
import { DelegateRestService } from './delegate.rest.service';
import { UserResponse } from './payload/user.response';


export interface MasterRegistrationPayload{
  email: string;
  username: string;
  password: string;
  companyname: string;
  gdprVersionUrl: string;
  agbVersionUrl: string;
}


export interface EditorRegistrationPayload{
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  gdprVersionUrl: string;
  agbVersionUrl: string;
}


@Injectable({
  providedIn: 'root'
})
export class RestUser{

  constructor(
    protected readonly rest: DelegateRestService,
    protected readonly platform: Platform
  ){

  }


  createMasterAccount(payload: MasterRegistrationPayload): Observable<User>{
    return this.rest.post<UserResponse>('/auth/register/account', payload, {auth:'noAuth'}).pipe(
      first(),
      map( userRes => plainToClass(User,userRes, {groups: ['showUser', 'showUserToken']})),
      exhaustMap(userObj => UserRepository.persistRemoteData(userObj, this.platform.is('capacitor'))),
    );
  }

  createEditorAccount(payload: EditorRegistrationPayload, registrationToken: string): Observable<User>{
    return this.rest.post<UserResponse>(`/auth/register/editor/role?registrationToken=${registrationToken}`,payload,{auth: 'noAuth'}).pipe(
      first(),
      map( userRes => plainToClass(User,userRes, {groups: ['showUser', 'showUserToken']})),
      exhaustMap(userObj => UserRepository.persistRemoteData(userObj, this.platform.is('capacitor'))),
    );
  }


  /**
   * only method that does not direclty persist result
   *
   * @returns user inkl account relation & authentication tokens
   */
  login(usernameORemail: string, password: string): Observable<User>{
    return this.rest.post<UserResponse>('/auth/login', { username_email: usernameORemail, password }, { auth: 'noAuth' }).pipe(
      first(),
      map(res => plainToClass(User, res,  {groups: ['showUser', 'showUserToken']}))
      );
  }

  /**
   * @returns user inkl account relation
   */
  getAuthenticatedUser(existingUserWithTokens: User): Observable<User>{
    let memoryUser: User;
    return this.rest.get<UserResponse>('/restapi/user/me', { auth: 'oauth2' }).pipe(
      first(),
      map((userRes: UserResponse) =>
      plainToClass(User, { ...existingUserWithTokens, ...userRes }, {groups: ['showUser', 'showUserToken']})),
      tap(userObj => memoryUser = userObj),
      switchMap(userObj => UserRepository.persistRemoteData(userObj, this.platform.is('capacitor'))),
      map(persistantUser => this.platform.is('capacitor') ? persistantUser : memoryUser)
    );
  }

  getUserWith(dId: number): Observable<User>{
    return this.rest.get<UserResponse>(`/restapi/user/${dId}`, { auth: 'oauth2' }).pipe(
      first(),
      map((userRes: UserResponse) => plainToClass(User,userRes, {groups: ['showUser']})),
      switchMap(userObj => UserRepository.persistRemoteData(userObj, this.platform.is('capacitor'))),
    );
  }

  getUsers(): Observable<User[]>{
   return this.rest.get<UserResponse[]>('/restapi/users', {auth: 'oauth2'}).pipe(
      first(),
      map( users => plainToClass(User,users,{groups: ['showUser']})),
      switchMap(users => {
        if (users.length > 0) {
          const observables: Observable<User>[] = [];
          users.forEach(u => observables.push(UserRepository.persistRemoteData(u, this.platform.is('capacitor'))));
          return forkJoin(observables);
        } else {
          return of(users);
        }
      }),
      map(users => users.filter(u => u.roles.includes(RoleAccessLevel.editor)))
   );
  }

  updateUser(user: User, existingUserWithTokens?: User): Observable<User>{
    let memoryUser: User;
   return this.rest.post<UserResponse>(
     '/restapi/user/update',
     classToPlain(user, {groups: ['showUser']}),
     !(isDefined(existingUserWithTokens) && isDefined(existingUserWithTokens.accessToken))
      ? {auth: 'oauth2'}
      : {headers: [['Authorization',existingUserWithTokens.accessToken.token]]}).pipe(
          first(),
          map((userRes: UserResponse) =>
            plainToClass(User, { ...existingUserWithTokens, ...userRes }, {groups: ['showUser', 'showUserToken']})),
          tap(userObj => memoryUser = userObj),
          switchMap(userObj => UserRepository.persistLocalData(userObj, this.platform.is('capacitor'))),
          switchMap(persistantUser => this.platform.is('capacitor') ? UserRepository.getOneLoggedInUserFromDb() : of(persistantUser)),
          map(persistantUser => this.platform.is('capacitor') ? persistantUser : memoryUser)
        );
  }


  changePassword(email: string){
    return this.rest.post('/auth/send-password-reset',{email}, {auth: 'noAuth'}).pipe(first());
  }

  resendConfirmationMail(){
    return this.rest.post('/auth/confirm/resend', {}, {auth: 'oauth2'}).pipe(first());
  }


}
