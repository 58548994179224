import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionStateHelper } from 'src/app/services/questions/question.state.helper';
import { QuestionStateQuery } from './question.state.query';
import { TransitionStateQuery } from './transition.state.query';

export interface News{
  dId: number;
  initials: string;
  text: string;
  questionUuid: string;
}

export interface Activity{
  verb: string;
  createdAt: number;
  title: string;
  questionUuid: string;
}


export class NewStatesQuery{

 static getNewsStateQuery(){
  return combineLatest([
     TransitionStateQuery.getTeamTransitionsWithAccountRole(),
     QuestionStateQuery.getQuestionsStreamWithMetaMap()
    ]).pipe(
      map( ([states, questionMap]) => {
        const result: News[] = [];
        states.forEach(state => {
          if(questionMap.has(state.question)){
            result.push({
              dId: questionMap.get(state.question).dId,
              initials: QuestionStateHelper.getInitialsFromUser(state.user),
              text: QuestionStateHelper.getNewsText(state, questionMap.get(state.question)),
              questionUuid: state.question
            });
          }
        });
        return result;
      })
   );
 }

 static getMyNewsStateQuery(){
  return TransitionStateQuery.getMyTransitionsWithAccountRole().pipe(
      map( states => {
        const result: Activity[] = [];
        states.forEach(state => {
            result.push({
              verb: QuestionStateHelper.getStatusTransitionVerbEditor(state),
              createdAt: state.createdAt,
              title: state.questionTitle,
              questionUuid: state.question
            });
        });
        return result.sort((a,b) => b.createdAt - a.createdAt);
      })
   );
 }

}
