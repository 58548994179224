import { Exclude } from 'class-transformer';
import { Column, Entity, PrimaryGeneratedColumn} from 'typeorm';
import { AbstractTaxonomy } from './AbstractTaxonomy';

@Entity({name: 'legalForm'})
export class LegalForm extends AbstractTaxonomy {

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  name: string;

  toString(): string {
      return this.name;
  }
}
