import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { filter, exhaustMap, first, map, switchMap } from 'rxjs/operators';
import { TaxonomyContainer } from 'src/app/entities/Taxonomy/TaxonomyContainer';
import { TaxonomyRepository } from 'src/app/repositories/taxonomy.repository';
import { UserStateQuery } from 'src/app/store/query/user.state.query';
import { isDefined } from 'src/app/util/util.function';
import { DelegateRestService } from './delegate.rest.service';

@Injectable({
  providedIn: 'root'
})
export class RestTaxonomy{

  constructor(
    protected readonly rest: DelegateRestService,
    protected readonly platform: Platform
  ){}


   public getTaxonomy(): Observable<TaxonomyContainer>{
    return UserStateQuery.getAuthenticatedUser().pipe(
      filter( user => isDefined(user.refreshToken)),
      exhaustMap( _ =>  this.rest.get<TaxonomyContainer>('/restapi/taxonomy', {auth: 'oauth2'}).pipe(
         first(),
         map((taxonomyResponse: TaxonomyContainer) => plainToClass(TaxonomyContainer,taxonomyResponse)),
         switchMap(container => TaxonomyRepository.persistRemoteData(container, this.platform.is('capacitor')))
      )));
  }


}
