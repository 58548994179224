import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { classToPlain, plainToClass } from 'class-transformer';
import { first , map, switchMap, tap} from 'rxjs/operators';
import { AbstractQuestion } from 'src/app/entities/Questions/AbstractQuestion';
import { QuestionEmployees } from 'src/app/entities/Questions/Employees/QuestionEmployees';
import { ZWHReport } from 'src/app/entities/ZWHReport';
import { QuestionRepository } from 'src/app/repositories/question.repository';
import { QuestionTransformer } from 'src/app/services/questions/questionTransformer';
import { DelegateRestService } from './delegate.rest.service';



@Injectable({
  providedIn: 'root'
})
export class RestQuestion{

  constructor(
    protected readonly rest: DelegateRestService,
    protected readonly platform: Platform
  ){}



  getQuestions(report?: ZWHReport){
    return this.rest.post<AbstractQuestion[]>('/restapi/questions', {...classToPlain(report)},{auth: 'oauth2'}).pipe(
      first(),
      map( questions => QuestionTransformer.plainToConcreteClass(questions)),
      switchMap((questions: AbstractQuestion[]) => QuestionRepository.persistRemoteData(questions, this.platform.is('capacitor')))
    );
  }

  getQuestion(dId: number){
    return this.rest.post<AbstractQuestion[]>(`/restapi/question/${dId}`, {},{auth: 'oauth2'}).pipe(
      first(),
      map(questions => QuestionTransformer.plainToConcreteClass(questions)),
      switchMap((questions) => QuestionRepository.persistRemoteData(questions, this.platform.is('capacitor'))),
      map(questions => questions.length > 0 ? questions.pop() : null)
    );
  }

  getDelegatedQuestionsForUser(){
    return this.rest.get<AbstractQuestion[]>(`/restapi/questions/user`, {auth: 'oauth2'}).pipe(
      first(),
      map(questions => QuestionTransformer.plainToConcreteClass(questions)),
      switchMap((questions) => QuestionRepository.persistRemoteData(questions, this.platform.is('capacitor'))),
    );
  }

  updateQuestions(questions: AbstractQuestion[]){
   const plain = QuestionTransformer.concreteClassToPlain(questions);
   return this.rest.post<AbstractQuestion[]>(
      '/restapi/questions/update',
      {...plain},
       {auth:'oauth2'}).pipe(
      first(),
      map( updatedQuestions => QuestionTransformer.plainToConcreteClass(updatedQuestions)),
      // tap( concrete =>     concrete.forEach(x => console.log('TREE CONCRETE', x))),
      switchMap(updatedQs => QuestionRepository.persistRemoteData(updatedQs, this.platform.is('capacitor')))
    );
  }



  }
