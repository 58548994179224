import { Exclude, Expose, Type } from 'class-transformer';
import { Column, Entity, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Unit } from '../../Taxonomy/Unit';
import { WaterSource } from '../../Taxonomy/WaterSource';
import { AbstractQuestion } from '../AbstractQuestion';

@Entity()
export class QuestionWaterConsumption extends AbstractQuestion{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true, default: 0})
  consumed: number;

  @Expose( {groups: ['showQuestion']})
  @Type(()=>WaterSource)
  @ManyToOne(()=>WaterSource, {nullable: false, cascade:['insert','update'], eager: true})
  source: WaterSource;

  @Expose( {groups: ['showQuestion']})
  @Type(()=>Unit)
  @ManyToOne(()=>Unit, {nullable: true, eager: true})
  unit: Unit;

  constructor(){
    super();
  }
}
