import { from, Observable } from 'rxjs';
import { exhaustMap, filter, map } from 'rxjs/operators';
import { isDefined } from 'src/app/util/util.function';
import {BaseEntity, BeforeInsert, Column, Entity, getConnection, IsNull, MoreThan, PrimaryGeneratedColumn} from 'typeorm';
import { AbstractQuestion } from '../Questions/AbstractQuestion';
import { AbstractStateTransition } from '../State/AbstractStateTransition';
import { User } from '../User';


export interface EntityRelation{
  propertyname: string;
  alias: string;
}

export type Syncdirection ='up' | 'down' | 'bidirectional' | 'single';

@Entity({name: 'sync'})
export class SyncEntry extends BaseEntity{

  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  class: string;

  @Column({nullable: true, default: 'up'})
  direction: Syncdirection;

  @Column()
  lastSync: number;

  /**Default 2 Minutes */
  @Column({nullable: true, default: 120})
  syncInterval: number;


  constructor(className: string, direction: Syncdirection, interval?: number){
    super();
    this.class = className;
    this.direction = direction;

    if(interval){
      this.syncInterval = interval;
    }
  }

static getLastSyncedByClass(className: string): Observable<SyncEntry[]>{
  return from(SyncEntry.query(
    `Select id, class,  max(lastSync) as lastSync, syncInterval
    From sync
    where sync.class = :className and sync.direction = "up" `, [className]
    )).pipe(
      map(entry => Array.isArray(entry) && entry.length > 0 && isDefined(entry[0].id) ? entry : [])
    );
}

static getUpdatesFor<U>(ctor: new (...args: any[]) => U, relations: EntityRelation[]): Observable<U[]>{
  return SyncEntry.getLastSyncedByClass(ctor.name).pipe(
    filter(syncEntry => isDefined(syncEntry) && isDefined(syncEntry[0])),
    exhaustMap( syncEntry => {
      const repo = getConnection().getRepository(ctor);
      return from(repo.find({where: [
        { updatedAt: MoreThan(syncEntry[0].lastSync)},
        { dId: IsNull()}
      ]
    }));
    })
  );
}

static getUpdatesAbstractQuestion<U>(ctor: new (...args: any[]) => U, relations: EntityRelation[]): Observable<U[]>{
  return SyncEntry.getLastSyncedByClass(AbstractQuestion.name).pipe(
    filter(syncEntry => isDefined(syncEntry) && isDefined(syncEntry[0])),
    exhaustMap( syncEntry => {
      const repo = getConnection().getRepository(ctor);
      return from(repo.find({where: [
          { updatedAt: MoreThan(syncEntry[0].lastSync)},
          { dId: IsNull()}
        ]
      }));
    })
  );
}



static getUpdateFor<U>(ctor: new (...args: any[]) => U, dId: number, relations?: EntityRelation[]){
  return SyncEntry.getLastSyncedByClass(ctor.name).pipe(
    filter(syncEntry => isDefined(syncEntry) && isDefined(syncEntry[0])),
    exhaustMap( syncEntry => {
      const repo = getConnection().getRepository(ctor);

      return from(repo.findOne({where: [
        { updatedAt: MoreThan(syncEntry[0].lastSync)},
        { dId }
      ]
    }));
    })
  );
}

static getUnsentMessages<U extends AbstractStateTransition>(ctor: new (...args: any[]) => U, user: User, relations?: EntityRelation[]){
  const repo = getConnection().getRepository(ctor);
  return from(repo.find({where:  { user: user.id, dId: IsNull()}}));
}



static getLastSynced(){
      return from(SyncEntry.query(`
      WITH s1 AS (
        SELECT class, lastSync, direction, syncInterval,
               RANK() OVER (PARTITION BY class, direction
                                ORDER BY lastSync DESC
                           ) AS "Rank"
          FROM sync
     )
     SELECT *
       FROM s1
       WHERE s1.direction = 'up' and RANK = 1
     ORDER BY lastSync;
      `));
  }

  static resetSyncHistory(){
    return from(SyncEntry.delete({lastSync: MoreThan(1)}));
  }

  @BeforeInsert()
  setDates(){
    this.lastSync =  Math.round(Date.now()/1000);
  }




}
