import { from, of } from 'rxjs';
import { exhaustMap, first, switchMap, timeout } from 'rxjs/operators';
import { EntityRepository, Repository } from 'typeorm';
import { Meta } from '../entities/Questions/Meta';
import { DBStateQuery } from '../store/query/db.state.query';

@EntityRepository(Meta)
export class MetaRepository extends Repository<Meta> {

   /**
    * @var inDb: true -> tries to save to the database once it becomes ready.
    * If it does not become ready or request takes too long, throws timeoutError.
    * Emits only once
    * @returns Observable<T> emits only once
    * @throws Timeout Error (if takes longer than 5000ms). This will complete the observable.
    */
    static persistRemoteData(meta: Meta, inDB: boolean) {
      if(inDB){
        return  DBStateQuery.firstReady().pipe(
            exhaustMap( () => from(Meta.saveRemoteTree(meta)).pipe(first(), timeout(5000)))
        );
      }else{
        return of(meta);
      }
    }


  static persistLocalData(entity: Meta, inDB: boolean) {
    if(inDB){
      return  DBStateQuery.firstReady().pipe( exhaustMap(_=> from(Meta.saveTree(entity)).pipe(first(), timeout(5000))));
    }else{
      return of(entity);
    }
  }


    findAllForVersion(version: string){
      return DBStateQuery.firstReady().pipe(
          switchMap(()=> {
            const relations = this.metadata.relations.map( m => m.propertyName);
            return from(this.find({where: {version} , relations}).then( result =>{
              const resultMetaMap: Map<string, Meta> = new Map();
              result.forEach( m => resultMetaMap.set(m.quid, m));
             return Array.from(resultMetaMap.values());
            }));
          }),
          first()
        );
    }

}
