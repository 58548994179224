import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';


@Injectable({providedIn: 'root'})
export class AppOnlyGuard implements CanActivate, CanLoad{

  constructor(
    private readonly platform: Platform,
    private readonly router: Router
  ){}


  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return !this.platform.is('capacitor') ? this.router.navigate(['/browser/tasks']) : true;
  }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      return !this.platform.is('capacitor') ? this.router.navigate(['/browser/tasks']) : true;
    }
}
