/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccountRole } from '../entities/Access/AccountRole';
import { AbstractQuestion } from '../entities/Questions/AbstractQuestion';
import { Meta } from '../entities/Questions/Meta';
import { AbstractStateTransition } from '../entities/State/AbstractStateTransition';
import { TaxonomyContainer } from '../entities/Taxonomy/TaxonomyContainer';
import { User } from '../entities/User';
import { ZWHReport } from '../entities/ZWHReport';

@Injectable({
  providedIn: 'root'
})
export class AppStateStore {

  static dbReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
  static user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  static editors: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
  static accountRoles: BehaviorSubject<AccountRole[]> = new BehaviorSubject<AccountRole[]>(null);
  static report: BehaviorSubject<ZWHReport> = new BehaviorSubject<ZWHReport | null>(null);
  static questionMeta: BehaviorSubject<Meta[]> = new BehaviorSubject<Meta[]>(null);
  static questions: BehaviorSubject<AbstractQuestion[]> = new BehaviorSubject<AbstractQuestion[]>(null);
  static taxonomy: BehaviorSubject<TaxonomyContainer> = new BehaviorSubject<TaxonomyContainer>(null);
  /**
   * map<question.uuid, map<createdAt|constructor.name>[]>
   */
  static questionTransitions: BehaviorSubject<Map<string, Map<string, AbstractStateTransition>>> = new BehaviorSubject<Map<string, Map<string, AbstractStateTransition>>>(new Map());

  static resetPipe(){
    return pipe(
      tap(()=>{
        AppStateStore.user.next(null);
        AppStateStore.editors.next(null);
        AppStateStore.accountRoles.next(null);
        AppStateStore.report.next(null);
        AppStateStore.questionMeta.next(null);
        AppStateStore.questions.next(null);
        AppStateStore.taxonomy.next(null);
      })
    );
  }

  static reset(){
    AppStateStore.user.next(null);
    AppStateStore.editors.next(null);
    AppStateStore.accountRoles.next(null);
    AppStateStore.report.next(null);
    AppStateStore.questionMeta.next(null);
    AppStateStore.questions.next(null);
    AppStateStore.taxonomy.next(null);
  }
}
