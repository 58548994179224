import { Observable, from, forkJoin, of } from 'rxjs';
import { first, exhaustMap, switchMap, tap, map } from 'rxjs/operators';
import { getConnection } from 'typeorm';
import { AbstractStateTransition } from '../entities/State/AbstractStateTransition';
import { DelegationStateTransition } from '../entities/State/DelegationStateTransition';
import { ProgressStateTransition } from '../entities/State/ProgressStateTransition';
import { AppStateStore } from '../store/app.state.store';
import { DBStateQuery } from '../store/query/db.state.query';

export class TransitionRepository{


  static persistRemoteData(transitions: AbstractStateTransition[], inDB: boolean){
    if(inDB){
      return  DBStateQuery.firstReady().pipe(
        switchMap(() => {
          if (transitions.length > 0 ) {
            const observables: Observable<AbstractStateTransition>[] = [];
            transitions.forEach(r => {
              observables.push(AppStateStore.dbReady.pipe(
                first(ready => ready),
                exhaustMap(() => from(r.saveRemoteTree()))
              ));
            });
            return forkJoin(observables).pipe(
              // tap(persists => persists.forEach(p => console.log('TREE PERSISTED: ', p)))
              );
          } else {
            return of(transitions);
          }
        })
      );
    }else{
      return of(transitions);
    }
  }


  static persistLocalData(transitions: AbstractStateTransition[], inDB: boolean){
    if(inDB){
      return  DBStateQuery.firstReady().pipe(
        switchMap(() => {
          if (transitions.length > 0 ) {
            const observables: Observable<AbstractStateTransition>[] = [];
            transitions.forEach(r => {
              observables.push(AppStateStore.dbReady.pipe(
                first(ready => ready),
                exhaustMap(() => from(r.saveTree()))
              ));
            });
            return forkJoin(observables).pipe(
              // tap(persists => persists.forEach(p => console.log('TREE PERSISTED: ', p)))
              );
          } else {
            return of(transitions);
          }
        })
      );
    }else{
      return of(transitions);
    }
  }

  static getDelegationStatesForQuestion(uuid: string){
    const delegationRepo = getConnection().getRepository(DelegationStateTransition);
    return DBStateQuery.firstReady().pipe(
      switchMap(()=> from(
        delegationRepo.createQueryBuilder('d')
        .leftJoinAndSelect('d.accountRole','role')
        .leftJoinAndSelect('d.user', 'user')
        .where('d.question = :uuid',{uuid})
        .orderBy({'d.dId': 'DESC'})
        .take(10)
        .getMany()
      ))
    );
  }



  static getProgressStatesForQuestion(uuid: string){
    const delegationRepo = getConnection().getRepository(ProgressStateTransition);
    return DBStateQuery.firstReady().pipe(
      switchMap(()=> from(
        delegationRepo.createQueryBuilder('p')
        .leftJoinAndSelect('p.user', 'user')
        .where('p.question = :uuid',{uuid})
        .orderBy({'p.dId': 'DESC'})
        .take(10)
        .getMany()
      ))
    );
  }

  static getStatesForQuestion(uuid: string): Observable<AbstractStateTransition[]>{
    return forkJoin([
      TransitionRepository.getDelegationStatesForQuestion(uuid),
      TransitionRepository.getProgressStatesForQuestion(uuid)
    ]
      ).pipe(
        map( arar => arar.flat(2) as AbstractStateTransition[])
      );
  }

  static getDelegationStatesOfUsersWith(ids: number[]){
    const delegationRepo = getConnection().getRepository(DelegationStateTransition);
    return DBStateQuery.firstReady().pipe(
      switchMap(()=> from(
        delegationRepo.createQueryBuilder('d')
        .leftJoinAndSelect('d.accountRole','role')
        .leftJoinAndSelect('d.user', 'user')
        .where('d.user IN (:...ids)',{ids})
        .orderBy({'d.dId': 'DESC'})
        .take(20)
        .getMany()
      ))
    );
  }

  static getProgressStatesOfUsersWith(ids: number[]){
    const delegationRepo = getConnection().getRepository(ProgressStateTransition);
    return DBStateQuery.firstReady().pipe(
      switchMap(()=> from(
        delegationRepo.createQueryBuilder('d')
        .leftJoinAndSelect('d.user', 'user')
        .where('d.user IN (:...ids)',{ids})
        .orderBy({'d.dId': 'DESC'})
        .take(20)
        .getMany()
      ))
    );
  }



  static getTeamStates(ids: number[]): Observable<AbstractStateTransition[]>{
    return forkJoin([
      TransitionRepository.getDelegationStatesOfUsersWith(ids),
      TransitionRepository.getProgressStatesOfUsersWith(ids)]).pipe(
        map( arar => arar.flat(2) as AbstractStateTransition[])
      );
  }

}
