import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EMPTY, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, exhaustMap, first, map, switchMap, tap } from 'rxjs/operators';
import { Token } from 'src/app/entities/Access/Token';
import { Account } from 'src/app/entities/Account';
import { EntityRelation, SyncEntry } from 'src/app/entities/Sync/SyncEntry';
import { User } from 'src/app/entities/User';
import { HttpError } from 'src/app/provider/rest/exceptions/HttpError';
import { RestAccount } from 'src/app/provider/rest/rest.account';
import { AccountRepository } from 'src/app/repositories/account.repository';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';
import { AbstractStateManager } from './abstract.state.manager';

@Injectable({
  providedIn: 'root'
})
export class AccountManager extends AbstractStateManager<Account>{

  private syncInterval = 3600; // once per hour

  private relations: EntityRelation[] = [
    {propertyname: 'legalForm', alias: 'legalForm'},
    {propertyname: 'headquarters', alias: 'headquarters'},
    {propertyname: 'employeeCount', alias: 'employeeCount'},
    {propertyname: 'industryBranch', alias: 'industryBranch'},
    {propertyname: 'business', alias: 'business'},
    {propertyname: 'trade', alias: 'trade'},
  ];

  constructor(
    protected readonly platform: Platform,
    private readonly remoteAccountProvider: RestAccount) {
    super(platform);
  }


  /**TODO: set authenticated user subject */
  public update(account: Account){
    return AppStateStore.user.pipe(
       first(user => isDefined(user)),
       exhaustMap( user => AccountRepository.persistLocalData(account, this.platform.is('capacitor')).pipe(
         tap(persistantAccount => user.account = persistantAccount),
         tap(acc => this.setAccountInAuthenticatedUserSubject(acc)),
         exhaustMap(alteredAccount => this.platform.is('capacitor')
           ? this.commit().pipe(
              map(res => Array.isArray(res) && res.length > 0 ? res.pop() : res),
              this.handleErrorsPlatformDependent(Account, 'up', alteredAccount)
            )
           : this.pushAndPull(alteredAccount).pipe(
            tap(acc => this.setAccountInAuthenticatedUserSubject(acc))
          )
          )
       ))
     );
   }

  deleteRemoteAccount(){
   return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() => this.remoteAccountProvider.deleteAccount()),
      catchError(error => {
        switch(true){
          case error instanceof Error && error.name === 'TimeoutError':
            return of('timeout');
          default: return throwError(error);
        }
      })
    );
  }


  deleteAccountOnMobileDevice(){
    if(this.platform.is('capacitor')){
     return AppStateStore.dbReady.pipe(
       first(ready => ready),
       switchMap( () => AppStateStore.user ),
       first(u => isDefined(u)),
       switchMap(u => forkJoin([
        AccountRepository.deleteAccount(u.account),
        SyncEntry.resetSyncHistory(),
        Token.deleteTable()
      ])),
      switchMap(() => of('account deletion success')),
      catchError(err => {
        console.warn('Error deleting account from device ', err.message);
        return of('account deletion failed');
      })
     );
  }
  return of('');
}




  executeSync(): Observable<Account[]> {
    return this.pushAndPullAll();
  }

  protected getRelations(): EntityRelation[] {
    return this.relations;
  }

  protected pull(): Observable<Account> {
   return this.remoteAccountProvider.getAuthenticatedUsersAccount();
  }

  protected pullAll(): Observable<Account[]> {
    return this.pull().pipe(map(account => [account]));
  }

  protected pushAndPull(entity: Account): Observable<Account> {
    return this.remoteAccountProvider.updateAccount(entity);
  }

  protected pushAndPullAll(): Observable<Account[]> {
    return this.commit().pipe(
      switchMap(() => this.pull()),
      tap(res => this.setAccountInAuthenticatedUserSubject(res)),
      tap(res => this.notifySyncSuccess(Account,[res], 'down') ),
      this.handleErrorsPlatformDependent(Account, 'bidirectional', null)
    );
  }

  protected commit(): Observable<Account | Account[]> {
    let currentAccount: Account;
    return AppStateStore.user.pipe(
      first(user => isDefined(user) && isDefined(user.account)),
      tap(u => currentAccount = u.account),
      exhaustMap(() => this.getPendingUpdateFor(Account, currentAccount)),
      exhaustMap( update => isDefined(update)
        ? this.pushAndPull(update).pipe(
          tap(res => this.setAccountInAuthenticatedUserSubject(res)),
          tap(res => this.notifySyncSuccess(Account,[res], 'up') ),
        )
        : of([]).pipe(tap(res => this.notifySyncSuccess(Account,[], 'up')))
      )
    );
  }


  protected getSyncInterval(): number {
    return this.syncInterval;
  }


  private setAccountInAuthenticatedUserSubject(account: Account){
    const existingUser = AppStateStore.user.getValue();
    if(isDefined(existingUser) && isDefined(account)){
      existingUser.account = account;
      AppStateStore.user.next(existingUser);
    }

  }

}
