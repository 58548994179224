/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ZWHReport } from '../entities/ZWHReport';
import { RestReport } from '../provider/rest/rest.report';
import { ReportManager } from '../store/manager/report.manager';
import { ReportMetaManager } from '../store/manager/report.meta.manager';
import { UserStateQuery } from '../store/query/user.state.query';
import { RefreshTokenExpired } from './security/exceptions/security.error';


@Injectable({
  providedIn: 'root'
})
export class ReportService{

  constructor(
    protected readonly restReport: RestReport,
    protected readonly reportManager: ReportManager,
    protected readonly metaManager: ReportMetaManager
  ) {

  }


  getSelectedTermNavigator(): Observable<number> {
    return UserStateQuery.getAuthenticatedUser().pipe(
      switchMap(() => this.restReport.getCurrentDrupalReportYear()),
      catchError(e => {
        if(e instanceof RefreshTokenExpired){
          return throwError(e);
        }
        return of(null);
      })
    );
  }



  autoSetReport() {
    return this.reportManager.setSelectedReportOrSetLastUpdatedIfExists();
  }

  autoSetReportMeta(){
    return this.metaManager.autoSetMeta('v_1');
  }

  getAllReports() {
    return this.reportManager.getAllReports();
  }


  createNewReport(year: number) {
   return this.reportManager.createNewReport(year);
  }


  deleteReport(r: ZWHReport) {
    return this.reportManager.setReportDeleted(r);
  }

  select(r: ZWHReport){
    return this.reportManager.setReport(r);
  }

}
