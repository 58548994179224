import { Exclude, Expose } from 'class-transformer';
import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';
import { AbstractQuestion } from '../AbstractQuestion';

@Entity()
export class QuestionEmployees extends AbstractQuestion{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true})
  value: number;

  constructor(){
    super();
  }
}
