import { Exclude, Expose, Type } from 'class-transformer';
import { Column, Entity, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Material } from '../../Taxonomy/Material';
import { Unit } from '../../Taxonomy/Unit';
import { AbstractQuestion } from '../AbstractQuestion';

@Entity()
export class QuestionMaterialHandling extends AbstractQuestion{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Expose( {groups: ['showQuestion']})
  @Type(()=>Material)
  @ManyToOne(()=>Material, {nullable: false, cascade:['insert','update'], eager: true})
  material: Material;

  @Expose( {groups: ['showQuestion']})
  @Type(()=>Unit)
  @ManyToOne(()=>Unit, {nullable: true, eager: true})
  unit: Unit;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true, default: 0})
  openingBalance: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true, default: 0})
  procurement: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true, default: 0})
  usedInProduction: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true, default: 0})
  disposed: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true, default: 0})
  transferred: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true, default: 0})
  sold: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true, default: 0})
  endingBalance: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true, default: 0})
  numberOfSuppliers: number;

  constructor(){
    super();
  }
}
