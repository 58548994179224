import { Exclude } from 'class-transformer';
import { Entity, PrimaryGeneratedColumn } from 'typeorm';
import { AbstractStateTransition } from './AbstractStateTransition';

export enum ProgressStates {
  created = 'created',
  inProgress = 'inProgress',
  completed = 'completed'
}


@Entity()
export class ProgressStateTransition extends AbstractStateTransition{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

}
