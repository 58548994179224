import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, take } from 'rxjs/operators';
import { isDefined } from 'src/app/util/util.function';
import { AuthenticationService } from './authentication.service';
import { SecurityError} from './exceptions/security.error';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    private readonly router: Router,
    private readonly authService: AuthenticationService
  ) { }


  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.can();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      return this.can();
  };


  private can(): Observable<boolean>{
    return this.authService.autoLogin().pipe(
      first(),
      map(user => isDefined(user)),
      catchError(er => {
        if(er instanceof SecurityError){
          this.router.navigate(['/authenticate']);
        }
        return throwError(er);
      })
    );

  }



}
