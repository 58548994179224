import { QuestionContainer } from 'src/app/entities/Questions/Container/QuestionContainer';
import { QuestionEmployees } from 'src/app/entities/Questions/Employees/QuestionEmployees';
import { QuestionMaterialHandling } from 'src/app/entities/Questions/Material/QuestionMaterialHandling';
import { QuestionElectricityConsumption } from 'src/app/entities/Questions/Ressources/QuestionElectricityConsumption';
import { QuestionHeatConsumption } from 'src/app/entities/Questions/Ressources/QuestionHeatConsumption';
import { QuestionTransportConsumption } from 'src/app/entities/Questions/Ressources/QuestionTransportConsumption';
import { QuestionWaterConsumption } from 'src/app/entities/Questions/Ressources/QuestionWaterConsumption';

export type Quid = keyof typeof quidClassMap;

export const quidClassMap = {
  'employees.total': QuestionEmployees,
  'employees.journeyman': QuestionEmployees,
  'employees.masters': QuestionEmployees,
  'employees.administrative': QuestionEmployees,
  'employees.business': QuestionEmployees,
  'employees.helpers': QuestionEmployees,
  'employees.interns': QuestionEmployees,
  'employees.subcontracted': QuestionEmployees,
  'employees.jobbers': QuestionEmployees,
  'employees.temporaryjobbers': QuestionEmployees,
  'employees.male': QuestionEmployees,
  'employees.female': QuestionEmployees,
  'employees.diverse': QuestionEmployees,
  'employees.underthirty': QuestionEmployees,
  'employees.midaged': QuestionEmployees,
  'employees.abovefifty': QuestionEmployees,
  'employees.childrenbelowschoolage': QuestionEmployees,
  'employees.relativesWithNeedOfCare': QuestionEmployees,
  'employees.physicaldisability': QuestionEmployees,
  'employees.mentaldisabilities': QuestionEmployees,
  'employees.recognizedrefugees': QuestionEmployees,
  'employees.toleratedrefugees': QuestionEmployees,
  'employees.euseasonalworkers': QuestionEmployees,
  'employees.noneuseasonalworkers': QuestionEmployees,
  'employees.eupermanentemployees': QuestionEmployees,
  'employees.noneupermanentemployees': QuestionEmployees,
  'employees.withoutrelevanteducation': QuestionEmployees,
  'employees.withouthighschooldiploma': QuestionEmployees,
  'employees.religion': QuestionEmployees,
  'employees.sexualorientation': QuestionEmployees,
  'employees.apprentices': QuestionEmployees,
  'employees.region': QuestionEmployees,
  'employees.totaltrainings': QuestionEmployees,
  'employees.trainingsmale': QuestionEmployees,
  'employees.trainingsfemale': QuestionEmployees,
  'employees.trainingsdiverse': QuestionEmployees,
  'employees.trainingsapprentices': QuestionEmployees,
  'employees.trainingsjourneymen': QuestionEmployees,
  'employees.trainingsmastercraftsmen': QuestionEmployees,
  'employees.trainingsadministrative': QuestionEmployees,
  'employees.trainingsmanagementboard': QuestionEmployees,
  'employees.trainingsduration5d': QuestionEmployees,
  'employees.trainingsduration2d': QuestionEmployees,
  'employees.trainingsduration1d': QuestionEmployees,
  'employees.trainingsduration4h': QuestionEmployees,
  'employees.trainingsduration2h': QuestionEmployees,
  'material.container': QuestionContainer,
  'material.inventory': QuestionMaterialHandling,
  'water.container': QuestionContainer,
  'water.consumption': QuestionWaterConsumption,
  'heat.container': QuestionContainer,
  'heat.consumption': QuestionHeatConsumption,
  'electricity.container': QuestionContainer,
  'electricity.consumption': QuestionElectricityConsumption,
  'transport.container': QuestionContainer,
  'transport.consumption': QuestionTransportConsumption
};
