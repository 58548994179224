import { Exclude } from 'class-transformer';
import { Column, Entity, PrimaryGeneratedColumn} from 'typeorm';
import { AbstractTaxonomy } from './AbstractTaxonomy';

@Entity({name: 'employeeCount'})
export class EmployeeCount extends AbstractTaxonomy{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  name: string;

  @Column()
  nrFrom: number;

  @Column()
  nrTo: number;

  toString(): string {
    return this.name;
}

}
