/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
import { BaseEntity, getConnection, SaveOptions } from 'typeorm';
import { FindConditions } from 'typeorm/find-options/FindConditions';
import { isDefined } from '../util/util.function';
import { TypeOrmObject } from './TypeOrmObject';

type Strategy = 'SERVER' | 'LOCAL';

export class TreeEntity extends BaseEntity {

  uuid: string;
  dId: number;
  id: number;


  /**
   * saves local data to db;
   */
   static async saveTree<T extends TreeEntity>(this: TypeOrmObject<T>, obj: T): Promise<T | null> {
      const repo = getConnection().getRepository(obj.constructor);
      const relations = repo.metadata.relations.map(m => m.propertyName);
      const findByUuid: FindConditions<T> = { where: { uuid: obj.uuid }, relations } as unknown as FindConditions<T>;//find by uuid if exist
      const findBydId: FindConditions<T> = { where: { dId: obj.dId }, relations} as unknown as FindConditions<T>; //fall back to dId other
      const existingObj = await this.findOne<T>('uuid' in obj ? findByUuid : findBydId); //if available find object by uuid, else use dId.
      let entity = isDefined(existingObj) ? existingObj : new this();
      entity = await entity.mapProperties(obj, relations, 'LOCAL');
      entity = await entity.save();
      if (entity.hasOwnProperty('id') && entity['id'] > -1) {
        // console.log('TREE LOCAL [INSERT|UPDATE] || ', 'success', obj.constructor.name);
        return entity;
      } else {
        // console.error('TREE LOCAL INSERT FAILED FOR ', entity.constructor.name, ' || ', entity);
        return null;
      }
  }


  /**
   * saves remote data to db. Checks  tick(server version counter) for being strictly greater to consider update;
   */
  static async saveRemoteTree<T extends TreeEntity>(this: TypeOrmObject<T>, obj: T): Promise<T | null> {
    if ('dId' in obj && obj['dId']) {
      const repo = getConnection().getRepository(obj.constructor);
      const relations = repo.metadata.relations.map(m => m.propertyName);
      const findByUuid: FindConditions<T> = { where: { uuid: obj.uuid }, relations } as unknown as FindConditions<T>;//find by uuid if exist
      const findBydId: FindConditions<T> = { where: { dId: obj.dId }, relations } as unknown as FindConditions<T>; //fall back to dId other
      const existingObj = await this.findOne<T>('uuid' in obj ? findByUuid : findBydId); //if available find object by uuid, else use dId.

      let entity: typeof obj;
      let strategy: string;
      if (isDefined(existingObj)) {
        if (existingObj.hasOwnProperty('tick')) {
          if (existingObj['tick'] < obj['tick']) {
            strategy = 'TREE SERVER STRATEGY - UPDATE '+ existingObj.constructor.name+ ' || tick(new): '+ obj['tick']+ ' > tick(old) '+ existingObj['tick']+' =  '+ (existingObj['tick'] < obj['tick']);
            const updatedEntity = await existingObj.mapProperties(obj, relations, 'SERVER');
            entity = await updatedEntity.save();
          } else {
            strategy = 'TREE SERVER STRATEGY - IGNORE UPDATES '+ existingObj.constructor.name+ ' || tick(new): '+ obj['tick']+ ' > tick(old) '+ existingObj['tick']+' =  '+ (existingObj['tick'] < obj['tick']);
            entity = existingObj;
          }
        } else {
          strategy = 'TREE SERVER STRATEGY - UPDATE '+ existingObj.constructor.name+ ' || '+  'no tick property';
          const updatedEntity = await existingObj.mapProperties(obj, relations, 'SERVER');
          entity = await updatedEntity.save();
        }
      }else{
        strategy = 'TREE SERVER STRATEGY - INSERT '+ obj.constructor.name;
        let newObj = new this();
        newObj = await newObj.mapProperties(obj, relations, 'SERVER');
        newObj = await newObj.save();
        entity = newObj;
      }

      if (entity.hasOwnProperty('id') && entity['id'] > -1) {
        strategy = strategy + ' result: '+ 'sucess';
        // console.log(strategy);
      } else {
        strategy = strategy + ' result: '+ 'failed';
        // console.warn(strategy);
      }
      //!!!always return failed entities to make parent entity's update also fail
      return entity;
    } else {
      console.warn('MISSING DID FOR ', obj.constructor.name, obj);
      return null;
    }
  }



  async saveTree(options?: SaveOptions): Promise<this>{
    const repository = getConnection().getRepository(this.constructor);
    const relations = repository.metadata.relations.map(m => m.propertyName);
    const findByUuid = { where: { uuid: this.uuid }, relations };
    const findBydId = { where: { dId: this.dId }, relations };
    const existingObj = await repository.findOne('uuid' in this ? findByUuid : findBydId);

    if (isDefined(existingObj) && (typeof this === typeof existingObj)) {
      const existing = existingObj as unknown as this;
      let result = await existing.mapProperties(this, relations, 'LOCAL');
      result = await result.save(options);
      if (result.hasOwnProperty('id') && result['id'] > -1) {
        return result;
      } else {
        console.error('TREE LOCAL INSERT FAILED FOR ', result.constructor.name, ' || ', result);
        return null;
      }
    }else{
      await this.mapProperties(this, relations, 'LOCAL');
      await this.save(options);
      if (this.id > -1) {
        return this;
      } else {
        console.error('TREE LOCAL INSERT FAILED FOR ', this.constructor.name, ' || ', this);
        return null;
      }
    }
  }

  /**
   * saves remote data to db. Checks tick(server version counter) for being strictly greater to consider update;
   */
  async saveRemoteTree(options?: SaveOptions): Promise<this> {
    const repository = getConnection().getRepository(this.constructor);
    const relations = repository.metadata.relations.map(m => m.propertyName);
    const findByUuid = { where: { uuid: this.uuid }, relations };
    const findBydId = { where: { dId: this.dId }, relations };
    const existingObj = await repository.findOne('uuid' in this ? findByUuid : findBydId);

    let entity: this;
    let strategy: string;

    if (isDefined(existingObj) && (typeof this === typeof existingObj)) {
      const existing = existingObj as unknown as this;
      if (existing.hasOwnProperty('tick')) {
        if (existing['tick'] < this['tick']) {
          strategy = 'TREE SERVER STRATEGY - UPDATE '+ existing.constructor.name+ ' || tick(new): '+ this['tick']+ ' > tick(old) '+ existing['tick']+' =  '+ (existing['tick'] < this['tick']);
          const mappedEntity = await existing.mapProperties(this, relations, 'SERVER');
          entity = await mappedEntity.save(options);
        } else {
          strategy = 'TREE SERVER STRATEGY - IGNORE UPDATES '+ existing.constructor.name+ ' || tick(new): '+ this['tick']+ ' > tick(old) '+ existing['tick']+' =  '+ (existing['tick'] < this['tick']);
          entity = existing;
        }
      }else{
        strategy = 'TREE SERVER STRATEGY - UPDATE '+ existingObj.constructor.name+ ' || '+  'no tick property';
        const mappedEntity = await existing.mapProperties(this, relations, 'SERVER');
        entity = await mappedEntity.save(options);
      }
    } else {
      strategy = 'TREE SERVER STRATEGY - INSERT '+ this.constructor.name;
      await this.mapProperties(this, relations, 'SERVER');
      await this.save(options);
      entity = this;
    }

    if (entity.hasOwnProperty('id') && entity['id'] > -1) {
      strategy = strategy + ' result: '+ 'sucess';
      // console.log(strategy);
    } else {
      strategy = strategy + ' result: '+ 'failed';
      // console.warn(strategy);
    }
    //!!!always return failed entities to make parent entity's update also fail
    return entity;
  }


  private async makePropertyValue(propertyKey: string, value: any, relations: string[], strategy: Strategy): Promise<any | null> {
    let propertyValue;
    switch (true) {
      case typeof value === 'object' && value instanceof TreeEntity:
        // is a delegate_api equivalent relation with an existing dId
        propertyValue = strategy === 'LOCAL' ? await value.constructor.saveTree(value) : await value.constructor.saveRemoteTree(value);
        break;
      case typeof value !== 'object' && relations.includes(propertyKey):
        // Ignore if backend sends relation data in non-object-from (e.g. only ids)
        propertyValue = null;
        break;

      case Array.isArray(value) && relations.includes(propertyKey):
        const collection = [];
        for await (const v of value) {
          switch (true) {
            case v instanceof TreeEntity:
              collection.push(strategy === 'LOCAL' ? await v.constructor.saveTree(v) : await v.constructor.saveRemoteTree(v));
              break;
          }
        }
        // collectio
        propertyValue = collection;
        break;
      default:
        propertyValue = value;
    }
    return propertyValue === 'undefined' ? null : propertyValue;
  }

  private async mapProperties(fromObj: any, relations: string[], stategy: Strategy): Promise<any> {
    for await (const key of Object.keys(fromObj)) {

      if (fromObj[key] !== null) {
        const property = await this.makePropertyValue(key, fromObj[key], relations, stategy);
        if (isDefined(property)) {
          this[key] = property;
        }
      } else {
        this[key] = null;
      }
    }
    return this;
  }


}
