/* eslint-disable @typescript-eslint/ban-types */
import { JsonpClientBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { from, Observable, throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { Token } from 'src/app/entities/Access/Token';
import { User } from 'src/app/entities/User';
import { isDefined } from 'src/app/util/util.function';
import { Unauthorized } from '../security/exceptions/security.error';


export enum StorageKeys {
  user = 'user',
  registrationToken= 'registrationToken',
  reportUuid= 'reportUuid',
  lastQuestion= 'last_question',
}

export class StorageUser {

  static storedProperties = ['dId', 'uuid', 'accessToken', 'refreshToken'];

  dId: number;
  uuid: string;
  accessToken: Token;
  refreshToken: Token;


  map(object: object): this {
    for (const property in object) {
      if (StorageUser.storedProperties.includes(property)) {
        this[property] = object[property];
      }
    }
    return this;
  }
}

export class StorageQuestion {
  dId?: number;
  uuid?: string;
  metaQuid?: string;
  metaDID?: number;

  map(object: object): this {
    // eslint-disable-next-line guard-for-in
    for (const property in object) {
        this[property] = object[property];
    }
    return this;
  }
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  static async storeUser(user: object): Promise<void> {
    if (user.hasOwnProperty('accessToken') && user.hasOwnProperty('refreshToken') && user.hasOwnProperty('dId')) {
      await Storage.set({ key: StorageKeys.user, value: JSON.stringify(user) });
    }
  }

  /**
   * get user json from storage, parses json, create storages user and map parsed json.
   * emits only once
   *
   * @throws Unauthorized if no entry in Storage
   */
  static getStorageUser() {
    return from(Storage.get({ key: StorageKeys.user })
        .then(value => {
          if(!isDefined(value.value)){
            throw new Unauthorized('Unauthorized', 'please login');
          }
          return value.value;
        }).then( (json: string) => {
          const obj = JSON.parse(json);
          return new StorageUser().map(obj);
        })).pipe(
          first(),
          catchError( err => throwError(err))
        );

  }

  /**
   * gets logged in user with a valid refresh token from storage or throws unauthorized.
   * emits only once
   *
   * @throws Unauthorized Error (if no user or user has no valid refresh token)
   */
  static getLoggedInUser(): Observable<StorageUser> {
    return StorageService.getStorageUser().pipe(
      tap((user: StorageUser) => {
        if(!isDefined(user.refreshToken)){
          throw new Unauthorized('Unauthorized', 'please login');
        }
        if(user.refreshToken.expires <= Math.floor(Date.now() / 1000)){
          throw new Unauthorized('Unauthorized', 'please login');
        }
      }),
      catchError( err => throwError(err))
      );
  }

  /**
   * Deletes user from Storage. Emits once, then dies
   */
  static deleteUser(): Observable<void>{
    return from(Storage.remove({key: StorageKeys.user})).pipe(first());
  }

  /**
   * Stores registrationToken in Storage. Emits once, then dies
   */
  static storeRegistrationToken(token: string): Observable<void>{
    return from(Storage.set({key: StorageKeys.registrationToken, value: token})).pipe(first());
  }

  /**
   * get registrationToken from Storage. Emits once, then dies
   *
   * @returns registrationToken (if exists) | null
   */
  static getRegistrationToken(): Observable<string | null>{
    return from(
      Storage.get({ key: StorageKeys.registrationToken})
      .then( getResult => isDefined(getResult.value) ? getResult.value : null))
      .pipe(first());
  }

  /**
   * deletes registrationToken from Storage. Emits once, then dies
   */
  static deleteRegistrationToken(){
    return from(Storage.remove({key: StorageKeys.registrationToken})).pipe(first());
  }

  /**
   * sets report uuid in user defaults. Emits once, then dies
   *
   * @returns void
   */
  static setReportUuid(uuid: string, currentUser: User){
    return from( Storage.set({key: StorageKeys.reportUuid+'|'+currentUser.uuid, value: uuid})).pipe(first());
  }


  /**
   * get report uuid from user defaults. Emits once, then dies
   *
   * @returns number | null
   */
  static getReportUuid(user: User): Observable<string>{
    return from( Storage.get({key: StorageKeys.reportUuid+'|'+user.uuid})
    .then(getResult => isDefined(getResult.value) ? getResult.value: null));
  }


  /**
   * deletes reporting year from user defaults. Emits once, then dies
   *
   *  @returns void
   */
  static deleteReportingYear(user: User){
    return  from(Storage.remove({key: StorageKeys.reportUuid+'|'+user.uuid})).pipe(first());
  }


  static setLastQuestion(reportUuid: string, storageQuestion: StorageQuestion){
    return from( Storage.set({key: StorageKeys.lastQuestion+'|'+reportUuid, value: JSON.stringify(storageQuestion)})).pipe(first());
  }

  static getLastQuestion(reportUuid: string){
    return from(
      Storage.get({key: StorageKeys.lastQuestion+'|'+reportUuid})
      .then(getResult => isDefined(getResult.value) ? getResult.value: null).then( (json: string | null) => {
        if(isDefined(json)){
          const obj = JSON.parse(json);
          const q = (new StorageQuestion()).map(obj);
          return q;
        }
       return null;
      })
      );

  }



}
