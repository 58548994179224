import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class RoutingService{

  private prefix: string;

 constructor(
  private platformService: Platform,
  private router: Router,
  private ngZone: NgZone

  ){
    this.prefix = this.platformService.is('capacitor') ? 'app' : 'browser';
  }

   redirectTo(url: string): Promise<boolean> {
    // console.log(`redirecting to: /${this.prefix}/${url}`);
    return this.ngZone.run( async () => await this.router.navigate( [`/${this.prefix}/${url}`]));
  }


}
