import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, of, pipe, throwError, UnaryFunction } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { Syncdirection } from 'src/app/entities/Sync/SyncEntry';
import { AbstractTaxonomy } from 'src/app/entities/Taxonomy/AbstractTaxonomy';
import { TaxonomyContainer } from 'src/app/entities/Taxonomy/TaxonomyContainer';
import { RestTaxonomy } from 'src/app/provider/rest/rest.taxonomy';
import { TaxonomyRepository } from 'src/app/repositories/taxonomy.repository';
import { RefreshTokenExpired } from 'src/app/services/security/exceptions/security.error';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';
import { AbstractSynchronizer } from '../synchronization/abstract.synchronizer';


@Injectable({
  providedIn: 'root'
})
export class TaxonomyManager extends AbstractSynchronizer<AbstractTaxonomy>{

  private syncInterval = 2419200; // once per month /4 weeks

  constructor(
    protected readonly restTaxonomy: RestTaxonomy,
    protected readonly platform: Platform,
  ){
    super(platform);
  }

  autoSet(){
    return  AppStateStore.user.pipe(
       first(u => isDefined(u)),
       switchMap(()=>AppStateStore.taxonomy),
       switchMap(container =>
        isDefined(container) && container.industryBranch.length > 10
        ? of(container)
        : this.platform.is('capacitor') ? this.retrieveOnMobile() : this.retrieveOnWeb()
        ),
      switchMap(container =>
        isDefined(container) && container.industryBranch.length > 10
        ? of(container)
        : this.pullContainer()
      )
    );
  }

  retrieveOnMobile(){
    return TaxonomyRepository.retrieveOrLoadTaxonomy().pipe(
      switchMap(container =>
        isDefined(container) && container.industryBranch.length > 10
        ? of(container)
        : this.pullContainer()
      ),
      this.verifySuccess(),
      switchMap(taxonomy => this.setTaxonomy(taxonomy))
    );
  }

  retrieveOnWeb(){
    return this.pullContainer().pipe(
      this.verifySuccess(),
      switchMap(taxonomy => this.setTaxonomy(taxonomy))
    );
  }

  executeSync(): Observable<AbstractTaxonomy[]> {
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() => AppStateStore.dbReady),
      first(),
      switchMap(() => this.pullContainer().pipe(
        tap(container => AppStateStore.taxonomy.next(container)),
      )),
      map(container => {
        const result: AbstractTaxonomy[] = [];
        for (const key of Object.keys(container)) {
          result.push(...container[key]);
        }
        return result;
      }),
      tap( (flat: AbstractTaxonomy[]) => this.notifySyncSuccess(AbstractTaxonomy, flat, 'up')),
      );
  }

  protected pullContainer(): Observable<TaxonomyContainer> {
    return this.restTaxonomy.getTaxonomy();
  }

  protected commit(entity?: AbstractTaxonomy): Observable<AbstractTaxonomy | AbstractTaxonomy[]> {
    throw new Error('Method not implemented.');
  }

  protected getSyncInterval(): number {
    return this.syncInterval;
  }

  protected handleErrorsPlatformDependent(direction: Syncdirection, defaultValue: any = null){
    return pipe(
     catchError(e => {
       if(this.platform.is('capacitor')){
         this.notifySyncFailure(AbstractTaxonomy, e, direction);
         if(e instanceof RefreshTokenExpired){
           return throwError(e);
         }
         return of(defaultValue);
       }else{
         return throwError(e);
       }
     })
    );
}

protected verifySuccess(): UnaryFunction<Observable<TaxonomyContainer>, Observable<TaxonomyContainer>>{
  return pipe(
    switchMap((taxonomyContainer: TaxonomyContainer) => {
      let error: Error;
      for (const key of Object.keys(taxonomyContainer)) {
        if(taxonomyContainer[key].length > 0){
          continue;
        }else{
          error = new Error('at least one taxonomy property returned empty');
        }
      }

      if(isDefined(error)){
        this.notifySyncFailure(AbstractTaxonomy, error, 'up');
        return throwError(error);
      }else{
        return of(taxonomyContainer);
      }

    }),
    tap(() => this.notifySyncSuccess(AbstractTaxonomy, [], 'up' )),
    this.handleErrorsPlatformDependent('up',[]),
  );
}


private setTaxonomy(taxonomy: TaxonomyContainer){

  if(isDefined(taxonomy) && taxonomy.industryBranch.length > 10){
    AppStateStore.taxonomy.next(taxonomy);
  }
  return of(taxonomy);
}



}
