import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';


export class EditorStateQuery{

  static getRawEditorsStream(){
    return AppStateStore.editors;
  }

  static getAllUsersStream(){
    return combineLatest([AppStateStore.editors, AppStateStore.user]).pipe(
      map(([editors, user]) => {
        const res = [...editors||[]];
        if(isDefined(user)){
          res.push(user);
        }
        return res;
      })
    );
  }

}
