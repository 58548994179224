import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { first, map, switchMap,  tap } from 'rxjs/operators';
import { Meta } from 'src/app/entities/Questions/Meta';
import { RestReport } from 'src/app/provider/rest/rest.report';
import { MetaRepository } from 'src/app/repositories/meta.repository';
import { AbstractStateManager } from './abstract.state.manager';
import { AppStateStore } from '../app.state.store';
import { isDefined } from 'src/app/util/util.function';
import { getCustomRepository } from 'typeorm';

@Injectable({
  providedIn: 'root'
})
export class ReportMetaManager extends AbstractStateManager<Meta>{


  private syncInterval = 604800; // once every week

  constructor(
    protected readonly platform: Platform,
    protected readonly restReport: RestReport
  ){
    super(platform);
  }


  autoSetMeta(version: string){
   return  AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(()=>AppStateStore.questionMeta),
      first(),
      switchMap(meta => {
        if(isDefined(meta) && meta.length > 0){
          return of(meta);
        }else{
          return this.platform.is('capacitor') ? this.findMetaInDb(version) : of(meta);
        }
      }),
      switchMap(meta => {
        if(isDefined(meta) && meta.length > 0){
          AppStateStore.questionMeta.next(meta);
          return of(meta);
        }else{
          return this.platform.is('capacitor')
          ? this.executeSync()
          : this.pullAll().pipe(tap(remoteMeta => AppStateStore.questionMeta.next(remoteMeta)));
        }
      })
    );
  }

  findMetaInDb(version: string): Observable<Meta[]>{
    if(this.platform.is('capacitor')){
      return AppStateStore.user.pipe(
        first(u => isDefined(u)),
        switchMap(() => this.getRepository()),
        switchMap( rep => rep.findAllForVersion(version))
      );
    }else{
      return of(null);
    }

  }


  executeSync(): Observable<Meta[]> {
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      switchMap(() => AppStateStore.dbReady),
      first(),
      switchMap( synced => this.pullAll().pipe(
            tap(meta => AppStateStore.questionMeta.next(meta)),
            tap(meta => this.notifySyncSuccess(Meta, meta, 'up')),
            this.handleErrorsPlatformDependent(Meta,'up',[]),
          ))
    );
  }

  protected pull(dId: number): Observable<Meta> {
    throw new Error('Method not implemented.');
  }
  protected pullAll(): Observable<Meta[]> {
    return this.restReport.getMetaReport();
  }
  protected pushAndPull(entity: Meta): Observable<Meta> {
    throw new Error('Method not implemented.');
  }
  protected pushAndPullAll(): Observable<Meta[]> {
    throw new Error('Method not implemented.');
  }

  protected commit(entity?: Meta): Observable<Meta | Meta[]> {
    throw new Error('Method not implemented.');
  }

  protected getSyncInterval(): number {
    return this.syncInterval;
  }

  private getRepository(){
    return AppStateStore.dbReady.pipe(first(ready => ready), map(() => getCustomRepository(MetaRepository)));
  }


}
