import { filter, first, map } from 'rxjs/operators';
import { QuestionCategory, questionCategories } from 'src/app/services/questions/questionCategories';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';


export class MetaStateQuery{

  static getRawMetaStream(){
    return AppStateStore.questionMeta;
  }



  static getMetaStreamForType(type?: QuestionCategory){
    return AppStateStore.questionMeta.pipe(
      filter(meta => isDefined(meta)),
      map(qs => isDefined(type) ? qs.filter(meta => meta.category === questionCategories[type]) : qs )
    );
  }


  static getMetaForType(type?: QuestionCategory){
    return AppStateStore.questionMeta.pipe(
      first(q => isDefined(q)),
      map(qs => isDefined(type) ? qs.filter(meta => meta.category === questionCategories[type]) : qs )
    );
  }

}
