import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { exhaustMap, first, switchMap, tap } from 'rxjs/operators';
import { EntityRelation } from 'src/app/entities/Sync/SyncEntry';
import { User } from 'src/app/entities/User';
import { RestUser } from 'src/app/provider/rest/rest.user';
import { isDefined } from 'src/app/util/util.function';
import { AppStateStore } from '../app.state.store';
import { AbstractStateManager } from './abstract.state.manager';
import { UserRepository } from 'src/app/repositories/user.repository';

@Injectable({
  providedIn: 'root'
})
export class EditorUserManager extends AbstractStateManager<User>{


  private syncInterval = 300; // every 5 minutes

  constructor(
    protected readonly platform: Platform,
    private readonly remoteUserProvider: RestUser
  ) {
    super(platform);
  }

  autoSetEditors(){
    let accountId: number;
    return AppStateStore.user.pipe(
      first(u => isDefined(u)),
      tap(u => accountId = u.accountId),
      exhaustMap(u => AppStateStore.editors.pipe(first())),
      switchMap(editors => isDefined(editors) ? of(editors)
      : this.platform.is('capacitor')
        ? UserRepository.getAllEditors(accountId).pipe(switchMap(users => this.setTeamInBehaviorSubject(users)))
        : this.pullAll().pipe(switchMap(users => this.setTeamInBehaviorSubject(users)))
      )
    );
  }

  executeSync(): Observable<User[]> {
    return this.pushAndPullAll();
  }
  protected pull(dId: number): Observable<User> {
    return this.remoteUserProvider.getUserWith(dId);
  }

  protected pullAll(): Observable<User[]> {
    return this.remoteUserProvider.getUsers();
  }


  protected pushAndPullAll(): Observable<User[]> {
    return this.commit().pipe(
      switchMap( () => this.pullAll()),
      switchMap( users => this.setTeamInBehaviorSubject(users)),
      tap(users => this.notifySyncSuccess(User, users, 'up')), // up is what triggers sync
      tap(users => this.notifySyncSuccess(User, users, 'down')),
      this.handleErrorsPlatformDependent(User, 'down', []),
      );
  }
  /**
   * updates not allowed for users other than authenticated user
   */
  protected commit(entity?: User): Observable<User | User[]> {
    return entity ? this.pushAndPull(entity) : of(entity);
  }
  /**
   * updates not allowed for users other than authenticated user
   */
   protected pushAndPull(entity: User): Observable<User> {
    return this.pull(entity.dId);
  }

  protected getSyncInterval(): number {
    return this.syncInterval;
  }


  private setTeamInBehaviorSubject(team: User[]) {
    if (isDefined(team)) {
      AppStateStore.editors.next(team);
    }
    return of(team);
  }

}
