import {MigrationInterface, QueryRunner} from 'typeorm';

export class sync1651805077952 implements MigrationInterface {
  name = 'sync1651805077952';

    public async up(queryRunner: QueryRunner): Promise<void> {


      await queryRunner.query(
        `CREATE TRIGGER user_trigger_updatedAt
         AFTER UPDATE OF firstname, lastname, username, confirmedEmail, roles ON user
        FOR EACH ROW WHEN NEW.updatedAt <= OLD.updatedAt
        BEGIN
          UPDATE user SET updatedAt= (strftime('%s', 'now')) WHERE id=OLD.id;
        END`
      );


      await queryRunner.query(
        `CREATE TRIGGER account_trigger_updatedAt
         AFTER UPDATE ON account
        FOR EACH ROW WHEN NEW.updatedAt <= OLD.updatedAt
        BEGIN
          UPDATE account SET updatedAt= (strftime('%s', 'now')) WHERE id=OLD.id;
        END`
      );

      await queryRunner.query(
        `CREATE TRIGGER zwhreport_trigger_updatedAt
         AFTER UPDATE ON zwh_report
        FOR EACH ROW WHEN NEW.updatedAt <= OLD.updatedAt
        BEGIN
          UPDATE zwh_report SET updatedAt= (strftime('%s', 'now')) WHERE id=OLD.id;
        END`
      );



      await queryRunner.query(
        `CREATE TRIGGER accountRole_trigger_updatedAt
          AFTER UPDATE ON accountRole
        FOR EACH ROW WHEN NEW.updatedAt <= OLD.updatedAt
        BEGIN
          UPDATE user SET updatedAt= (strftime('%s', 'now')) WHERE id=OLD.id;
        END`
      );



      await queryRunner.query(`INSERT INTO "sync"("class", "lastSync", "syncInterval") VALUES ('AuthenticatedUser', 1, 1)`);
      await queryRunner.query(`INSERT INTO "sync"("class", "lastSync", "syncInterval") VALUES ('User', 1, 1)`);
      await queryRunner.query(`INSERT INTO "sync"("class", "lastSync", "syncInterval") VALUES ('Account', 1, 1)`);
      await queryRunner.query(`INSERT INTO "sync"("class", "lastSync", "syncInterval") VALUES ('AccountRole', 1, 1)`);
      await queryRunner.query(`INSERT INTO "sync"("class", "lastSync", "syncInterval") VALUES ('ZWHReport', 1, 1)`);
      await queryRunner.query(`INSERT INTO "sync"("class", "lastSync", "syncInterval") VALUES ('AbstractQuestion', 1, 1)`);
      await queryRunner.query(`INSERT INTO "sync"("class", "lastSync", "syncInterval") VALUES ('Meta', 1, 1)`);
      await queryRunner.query(`INSERT INTO "sync"("class", "lastSync", "syncInterval") VALUES ('AbstractStateTransition', 1, 1)`);
      await queryRunner.query(`INSERT INTO "sync"("class", "lastSync", "syncInterval") VALUES ('Taxonomy', 1, 1)`);


    }



    public async down(queryRunner: QueryRunner): Promise<void> {

    }

}
