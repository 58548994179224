import {MigrationInterface, QueryRunner} from "typeorm";

export class initial1644187130501 implements MigrationInterface {
    name = 'initial1644187130501'

    public async up(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`CREATE TABLE "translation" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "dId" integer, "tick" integer, "de" varchar, "en" varchar, CONSTRAINT "UQ_37c083bb95353ada5e0c73b3016" UNIQUE ("uuid"), CONSTRAINT "UQ_4b4531b0f709fdcd8f675353ac9" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "business" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_5d5550387a5ba571d7ed792151d" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "employeeCount" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "name" varchar NOT NULL, "nrFrom" integer NOT NULL, "nrTo" integer NOT NULL, CONSTRAINT "UQ_56db7d0ef6d4382955cf1689082" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "headquarters" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_340fa075ad60415bc6e0eaffb95" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "industryBranch" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_8320b4bf759d72f7c7269a15d60" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "legalForm" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "name" varchar NOT NULL, CONSTRAINT "UQ_55ea72bf0cf5c53210d3cf15543" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "trade" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_0e91295657c0cc2a5e3dfb26016" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "account" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "dId" integer NOT NULL, "companyName" varchar, "telefon" varchar, "website" varchar, "contact" varchar, "companyDescription" varchar, "furtherInformation" varchar, "createdAt" integer, "updatedAt" integer, "legalFormId" integer, "headquartersId" integer, "employeeCountId" integer, "industryBranchId" integer, "businessId" integer, "tradeId" integer, CONSTRAINT "UQ_31e2fd7720a2da3af586f17778f" UNIQUE ("uuid"))`);
        await queryRunner.query(`CREATE TABLE "token" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "token" varchar NOT NULL, "expires" integer NOT NULL)`);
        await queryRunner.query(`CREATE TABLE "user" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "tick" integer, "dId" integer NOT NULL, "uuid" varchar NOT NULL, "accountId" integer NOT NULL, "firstname" varchar, "lastname" varchar, "username" varchar NOT NULL, "email" varchar NOT NULL, "confirmedEmail" boolean NOT NULL, "roles" text NOT NULL, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "accessTokenId" integer, "refreshTokenId" integer, CONSTRAINT "UQ_8acd9f2aef4d26aebbb39c42385" UNIQUE ("dId"), CONSTRAINT "UQ_a95e949168be7b7ece1a2382fed" UNIQUE ("uuid"), CONSTRAINT "REL_ba259dd78f192f9d78ef47aef8" UNIQUE ("accessTokenId"), CONSTRAINT "REL_7c91492fa6749e6d222216fa87" UNIQUE ("refreshTokenId"))`);
        await queryRunner.query(`CREATE TABLE "accountRole" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "dId" integer, "tick" integer, "uuid" varchar NOT NULL, "name" varchar NOT NULL, "userId" integer, "totalQuestions" integer, "registrationLink" varchar, "expirationTime" integer, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "accountId" integer NOT NULL, CONSTRAINT "UQ_d2e5f074c66b8e15f71ff54acbb" UNIQUE ("dId"), CONSTRAINT "UQ_fa6219e337b99ffe8f909da74de" UNIQUE ("uuid"))`);
        await queryRunner.query(`CREATE TABLE "meta" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "version" varchar NOT NULL, "tick" integer, "dId" integer NOT NULL, "quid" varchar NOT NULL, "className" varchar NOT NULL, "category" varchar NOT NULL, "level" integer NOT NULL, "sequenceNumber" integer NOT NULL, "titleId" integer, "subtitleId" integer, "descriptionId" integer, "infoId" integer, CONSTRAINT "UQ_83fb6670a4c4f3696737d87aad7" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "zwh_report" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "tick" integer, "dId" integer, "uuid" varchar NOT NULL, "accountId" integer NOT NULL, "year" integer NOT NULL, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), CONSTRAINT "UQ_408dacbdfe3a1efd00be6609b5c" UNIQUE ("dId"), CONSTRAINT "UQ_803a7a6b781a734981eca3f95c2" UNIQUE ("uuid"))`);
        await queryRunner.query(`CREATE TABLE "question_container" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "accountRoleId" integer, CONSTRAINT "UQ_0b2784261d31f59845cf064399f" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "question_employees" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "value" integer, "accountRoleId" integer, CONSTRAINT "UQ_2089a6d88dbee51022e4fa26317" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "material" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "renewable" boolean DEFAULT (0), "approved" boolean DEFAULT (0), "nameId" integer NOT NULL, CONSTRAINT "UQ_71c728a2dfea42fb19af1d84f6a" UNIQUE ("dId"), CONSTRAINT "UQ_c9598c156ad443188d8f245f883" UNIQUE ("uuid"))`);
        await queryRunner.query(`CREATE TABLE "unit" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "tick" integer, "symbol" varchar NOT NULL, "nameId" integer, CONSTRAINT "UQ_7fc01c7e48d34784d0e0b115ad3" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "question_material_handling" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "openingBalance" integer DEFAULT (0), "procurement" integer DEFAULT (0), "usedInProduction" integer DEFAULT (0), "disposed" integer DEFAULT (0), "transferred" integer DEFAULT (0), "sold" integer DEFAULT (0), "endingBalance" integer DEFAULT (0), "numberOfSuppliers" integer DEFAULT (0), "accountRoleId" integer, "materialId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_9757c6b72b945137960758e80cf" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "energySource" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "renewable" boolean DEFAULT (0), "approved" boolean DEFAULT (0), "nameId" integer NOT NULL, CONSTRAINT "UQ_539aec6ac8636c2fdf32a91920f" UNIQUE ("dId"), CONSTRAINT "UQ_efa7b713de326b29400c4fa6e68" UNIQUE ("uuid"))`);
        await queryRunner.query(`CREATE TABLE "question_electricity_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "produced" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_9497d14af152ff617f6c73fcb9d" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "question_heat_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_499ea410d2eb0183d1bea397cd6" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "question_transport_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_185aac9e201168128464a8e7fb2" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "waterSource" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "nameId" integer NOT NULL, CONSTRAINT "UQ_333544c3ae0354110d86b3737f0" UNIQUE ("dId"), CONSTRAINT "UQ_f41b0a5e9f25f9da66d6cf45831" UNIQUE ("uuid"))`);
        await queryRunner.query(`CREATE TABLE "question_water_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_c8b0fdad6ae7005dfc9fbd4c73b" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "delegation_state_transition" ("dId" integer, "tick" integer, "question" varchar NOT NULL, "questionTitle" varchar, "toState" varchar NOT NULL, "createdAt" integer NOT NULL, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "message" varchar, "deadline" integer, "userId" integer, "accountRoleId" integer, CONSTRAINT "UQ_f0c9f8884b95c42a2b9185c2888" UNIQUE ("dId"), CONSTRAINT "UQ_f9b94eec279b456a394f84ba647" UNIQUE ("uuid"))`);
        await queryRunner.query(`CREATE TABLE "progress_state_transition" ("dId" integer, "tick" integer, "question" varchar NOT NULL, "questionTitle" varchar, "toState" varchar NOT NULL, "createdAt" integer NOT NULL, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "userId" integer, CONSTRAINT "UQ_671c0dac236dfa15d2c9c1fd27b" UNIQUE ("dId"))`);
        await queryRunner.query(`CREATE TABLE "sync" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "class" varchar NOT NULL, "direction" varchar DEFAULT ('up'), "lastSync" integer NOT NULL, "syncInterval" integer DEFAULT (120))`);
        await queryRunner.query(`CREATE TABLE "temporary_business" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_5d5550387a5ba571d7ed792151d" UNIQUE ("dId"), CONSTRAINT "FK_4590a9757aa26509eba4882addf" FOREIGN KEY ("nameId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_business"("dId", "id", "nameId") SELECT "dId", "id", "nameId" FROM "business"`);
        await queryRunner.query(`DROP TABLE "business"`);
        await queryRunner.query(`ALTER TABLE "temporary_business" RENAME TO "business"`);
        await queryRunner.query(`CREATE TABLE "temporary_headquarters" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_340fa075ad60415bc6e0eaffb95" UNIQUE ("dId"), CONSTRAINT "FK_f3f5b7d8221033307cb7ebf30d0" FOREIGN KEY ("nameId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_headquarters"("dId", "id", "nameId") SELECT "dId", "id", "nameId" FROM "headquarters"`);
        await queryRunner.query(`DROP TABLE "headquarters"`);
        await queryRunner.query(`ALTER TABLE "temporary_headquarters" RENAME TO "headquarters"`);
        await queryRunner.query(`CREATE TABLE "temporary_industryBranch" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_8320b4bf759d72f7c7269a15d60" UNIQUE ("dId"), CONSTRAINT "FK_a5a88f06b6fa9b91cb2b727a622" FOREIGN KEY ("nameId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_industryBranch"("dId", "id", "nameId") SELECT "dId", "id", "nameId" FROM "industryBranch"`);
        await queryRunner.query(`DROP TABLE "industryBranch"`);
        await queryRunner.query(`ALTER TABLE "temporary_industryBranch" RENAME TO "industryBranch"`);
        await queryRunner.query(`CREATE TABLE "temporary_trade" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_0e91295657c0cc2a5e3dfb26016" UNIQUE ("dId"), CONSTRAINT "FK_46a65b19803dbefe7d1fb59d793" FOREIGN KEY ("nameId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_trade"("dId", "id", "nameId") SELECT "dId", "id", "nameId" FROM "trade"`);
        await queryRunner.query(`DROP TABLE "trade"`);
        await queryRunner.query(`ALTER TABLE "temporary_trade" RENAME TO "trade"`);
        await queryRunner.query(`CREATE TABLE "temporary_account" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "dId" integer NOT NULL, "companyName" varchar, "telefon" varchar, "website" varchar, "contact" varchar, "companyDescription" varchar, "furtherInformation" varchar, "createdAt" integer, "updatedAt" integer, "legalFormId" integer, "headquartersId" integer, "employeeCountId" integer, "industryBranchId" integer, "businessId" integer, "tradeId" integer, CONSTRAINT "UQ_31e2fd7720a2da3af586f17778f" UNIQUE ("uuid"), CONSTRAINT "FK_5477843db16c2591de36c49f6c7" FOREIGN KEY ("legalFormId") REFERENCES "legalForm" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_29d9bfda42f52c2c65eab31dd0b" FOREIGN KEY ("headquartersId") REFERENCES "headquarters" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_e94358ce56bb2101ffe81ad5545" FOREIGN KEY ("employeeCountId") REFERENCES "employeeCount" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_4481b9515a0945ed6db69176f39" FOREIGN KEY ("industryBranchId") REFERENCES "industryBranch" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_69e9f471a7867db19339261cad9" FOREIGN KEY ("businessId") REFERENCES "business" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_9dd2476ec0203045899d4e5b400" FOREIGN KEY ("tradeId") REFERENCES "trade" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_account"("id", "uuid", "tick", "dId", "companyName", "telefon", "website", "contact", "companyDescription", "furtherInformation", "createdAt", "updatedAt", "legalFormId", "headquartersId", "employeeCountId", "industryBranchId", "businessId", "tradeId") SELECT "id", "uuid", "tick", "dId", "companyName", "telefon", "website", "contact", "companyDescription", "furtherInformation", "createdAt", "updatedAt", "legalFormId", "headquartersId", "employeeCountId", "industryBranchId", "businessId", "tradeId" FROM "account"`);
        await queryRunner.query(`DROP TABLE "account"`);
        await queryRunner.query(`ALTER TABLE "temporary_account" RENAME TO "account"`);
        await queryRunner.query(`CREATE TABLE "temporary_user" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "tick" integer, "dId" integer NOT NULL, "uuid" varchar NOT NULL, "accountId" integer NOT NULL, "firstname" varchar, "lastname" varchar, "username" varchar NOT NULL, "email" varchar NOT NULL, "confirmedEmail" boolean NOT NULL, "roles" text NOT NULL, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "accessTokenId" integer, "refreshTokenId" integer, CONSTRAINT "UQ_8acd9f2aef4d26aebbb39c42385" UNIQUE ("dId"), CONSTRAINT "UQ_a95e949168be7b7ece1a2382fed" UNIQUE ("uuid"), CONSTRAINT "REL_ba259dd78f192f9d78ef47aef8" UNIQUE ("accessTokenId"), CONSTRAINT "REL_7c91492fa6749e6d222216fa87" UNIQUE ("refreshTokenId"), CONSTRAINT "FK_68d3c22dbd95449360fdbf7a3f1" FOREIGN KEY ("accountId") REFERENCES "account" ("id") ON DELETE CASCADE ON UPDATE NO ACTION, CONSTRAINT "FK_ba259dd78f192f9d78ef47aef87" FOREIGN KEY ("accessTokenId") REFERENCES "token" ("id") ON DELETE SET NULL ON UPDATE NO ACTION, CONSTRAINT "FK_7c91492fa6749e6d222216fa874" FOREIGN KEY ("refreshTokenId") REFERENCES "token" ("id") ON DELETE SET NULL ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_user"("id", "tick", "dId", "uuid", "accountId", "firstname", "lastname", "username", "email", "confirmedEmail", "roles", "createdAt", "updatedAt", "accessTokenId", "refreshTokenId") SELECT "id", "tick", "dId", "uuid", "accountId", "firstname", "lastname", "username", "email", "confirmedEmail", "roles", "createdAt", "updatedAt", "accessTokenId", "refreshTokenId" FROM "user"`);
        await queryRunner.query(`DROP TABLE "user"`);
        await queryRunner.query(`ALTER TABLE "temporary_user" RENAME TO "user"`);
        await queryRunner.query(`CREATE TABLE "temporary_accountRole" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "dId" integer, "tick" integer, "uuid" varchar NOT NULL, "name" varchar NOT NULL, "userId" integer, "totalQuestions" integer, "registrationLink" varchar, "expirationTime" integer, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "accountId" integer NOT NULL, CONSTRAINT "UQ_d2e5f074c66b8e15f71ff54acbb" UNIQUE ("dId"), CONSTRAINT "UQ_fa6219e337b99ffe8f909da74de" UNIQUE ("uuid"), CONSTRAINT "FK_b51f2cc6f3495e3db6b866e6bac" FOREIGN KEY ("accountId") REFERENCES "account" ("id") ON DELETE CASCADE ON UPDATE NO ACTION, CONSTRAINT "FK_947ca3072d7dca2475376a96401" FOREIGN KEY ("userId") REFERENCES "user" ("id") ON DELETE SET NULL ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_accountRole"("id", "dId", "tick", "uuid", "name", "userId", "totalQuestions", "registrationLink", "expirationTime", "createdAt", "updatedAt", "deleted", "accountId") SELECT "id", "dId", "tick", "uuid", "name", "userId", "totalQuestions", "registrationLink", "expirationTime", "createdAt", "updatedAt", "deleted", "accountId" FROM "accountRole"`);
        await queryRunner.query(`DROP TABLE "accountRole"`);
        await queryRunner.query(`ALTER TABLE "temporary_accountRole" RENAME TO "accountRole"`);
        await queryRunner.query(`CREATE TABLE "temporary_meta" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "version" varchar NOT NULL, "tick" integer, "dId" integer NOT NULL, "quid" varchar NOT NULL, "className" varchar NOT NULL, "category" varchar NOT NULL, "level" integer NOT NULL, "sequenceNumber" integer NOT NULL, "titleId" integer, "subtitleId" integer, "descriptionId" integer, "infoId" integer, CONSTRAINT "UQ_83fb6670a4c4f3696737d87aad7" UNIQUE ("dId"), CONSTRAINT "FK_0186a87f8c553e0cac693671cf0" FOREIGN KEY ("titleId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_42c159beb9649c792184be0c8d2" FOREIGN KEY ("subtitleId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_42359d4b408ef8ba80139ef7c00" FOREIGN KEY ("descriptionId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_7cbe11ae914bb3b5eb620f43044" FOREIGN KEY ("infoId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_meta"("id", "version", "tick", "dId", "quid", "className", "category", "level", "sequenceNumber", "titleId", "subtitleId", "descriptionId", "infoId") SELECT "id", "version", "tick", "dId", "quid", "className", "category", "level", "sequenceNumber", "titleId", "subtitleId", "descriptionId", "infoId" FROM "meta"`);
        await queryRunner.query(`DROP TABLE "meta"`);
        await queryRunner.query(`ALTER TABLE "temporary_meta" RENAME TO "meta"`);
        await queryRunner.query(`CREATE TABLE "temporary_zwh_report" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "tick" integer, "dId" integer, "uuid" varchar NOT NULL, "accountId" integer NOT NULL, "year" integer NOT NULL, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), CONSTRAINT "UQ_408dacbdfe3a1efd00be6609b5c" UNIQUE ("dId"), CONSTRAINT "UQ_803a7a6b781a734981eca3f95c2" UNIQUE ("uuid"), CONSTRAINT "FK_461bdd35b0d63d55e5cdee68835" FOREIGN KEY ("accountId") REFERENCES "account" ("id") ON DELETE CASCADE ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_zwh_report"("id", "tick", "dId", "uuid", "accountId", "year", "createdAt", "updatedAt", "deleted") SELECT "id", "tick", "dId", "uuid", "accountId", "year", "createdAt", "updatedAt", "deleted" FROM "zwh_report"`);
        await queryRunner.query(`DROP TABLE "zwh_report"`);
        await queryRunner.query(`ALTER TABLE "temporary_zwh_report" RENAME TO "zwh_report"`);
        await queryRunner.query(`CREATE TABLE "temporary_question_container" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "accountRoleId" integer, CONSTRAINT "UQ_0b2784261d31f59845cf064399f" UNIQUE ("dId"), CONSTRAINT "FK_cdb52d4538092c62fafbff91edb" FOREIGN KEY ("metaId") REFERENCES "meta" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_31659bb415f361c192a7cb0f8ec" FOREIGN KEY ("accountRoleId") REFERENCES "accountRole" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_0f7bcd1c41a02b47c7fc48587ce" FOREIGN KEY ("zwhReportId") REFERENCES "zwh_report" ("id") ON DELETE CASCADE ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_question_container"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "accountRoleId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "accountRoleId" FROM "question_container"`);
        await queryRunner.query(`DROP TABLE "question_container"`);
        await queryRunner.query(`ALTER TABLE "temporary_question_container" RENAME TO "question_container"`);
        await queryRunner.query(`CREATE TABLE "temporary_question_employees" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "value" integer, "accountRoleId" integer, CONSTRAINT "UQ_2089a6d88dbee51022e4fa26317" UNIQUE ("dId"), CONSTRAINT "FK_fa5b6bd5fb3e4198ecff7d9d228" FOREIGN KEY ("metaId") REFERENCES "meta" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_1f68a36571bcf4825b094eff73c" FOREIGN KEY ("accountRoleId") REFERENCES "accountRole" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_83f8896e1817b760a21bf95fc7f" FOREIGN KEY ("zwhReportId") REFERENCES "zwh_report" ("id") ON DELETE CASCADE ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_question_employees"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "value", "accountRoleId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "value", "accountRoleId" FROM "question_employees"`);
        await queryRunner.query(`DROP TABLE "question_employees"`);
        await queryRunner.query(`ALTER TABLE "temporary_question_employees" RENAME TO "question_employees"`);
        await queryRunner.query(`CREATE TABLE "temporary_material" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "renewable" boolean DEFAULT (0), "approved" boolean DEFAULT (0), "nameId" integer NOT NULL, CONSTRAINT "UQ_71c728a2dfea42fb19af1d84f6a" UNIQUE ("dId"), CONSTRAINT "UQ_c9598c156ad443188d8f245f883" UNIQUE ("uuid"), CONSTRAINT "FK_551445f7516e34089765727bd55" FOREIGN KEY ("nameId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_material"("dId", "id", "uuid", "tick", "renewable", "approved", "nameId") SELECT "dId", "id", "uuid", "tick", "renewable", "approved", "nameId" FROM "material"`);
        await queryRunner.query(`DROP TABLE "material"`);
        await queryRunner.query(`ALTER TABLE "temporary_material" RENAME TO "material"`);
        await queryRunner.query(`CREATE TABLE "temporary_unit" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "tick" integer, "symbol" varchar NOT NULL, "nameId" integer, CONSTRAINT "UQ_7fc01c7e48d34784d0e0b115ad3" UNIQUE ("dId"), CONSTRAINT "FK_adae73731070d6f9ae75332fca8" FOREIGN KEY ("nameId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_unit"("dId", "id", "tick", "symbol", "nameId") SELECT "dId", "id", "tick", "symbol", "nameId" FROM "unit"`);
        await queryRunner.query(`DROP TABLE "unit"`);
        await queryRunner.query(`ALTER TABLE "temporary_unit" RENAME TO "unit"`);
        await queryRunner.query(`CREATE TABLE "temporary_question_material_handling" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "openingBalance" integer DEFAULT (0), "procurement" integer DEFAULT (0), "usedInProduction" integer DEFAULT (0), "disposed" integer DEFAULT (0), "transferred" integer DEFAULT (0), "sold" integer DEFAULT (0), "endingBalance" integer DEFAULT (0), "numberOfSuppliers" integer DEFAULT (0), "accountRoleId" integer, "materialId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_9757c6b72b945137960758e80cf" UNIQUE ("dId"), CONSTRAINT "FK_625e630b6177f28a059740d8a9c" FOREIGN KEY ("metaId") REFERENCES "meta" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_a9300651620460a91e1f38852f1" FOREIGN KEY ("accountRoleId") REFERENCES "accountRole" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_9ba3c6e0cdf8fff1deb1777d7b2" FOREIGN KEY ("zwhReportId") REFERENCES "zwh_report" ("id") ON DELETE CASCADE ON UPDATE NO ACTION, CONSTRAINT "FK_4f537b43c940ddac5e0e65e73f5" FOREIGN KEY ("materialId") REFERENCES "material" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_3e2feae564280a80f7e1ef785f9" FOREIGN KEY ("unitId") REFERENCES "unit" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_question_material_handling"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "openingBalance", "procurement", "usedInProduction", "disposed", "transferred", "sold", "endingBalance", "numberOfSuppliers", "accountRoleId", "materialId", "unitId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "openingBalance", "procurement", "usedInProduction", "disposed", "transferred", "sold", "endingBalance", "numberOfSuppliers", "accountRoleId", "materialId", "unitId" FROM "question_material_handling"`);
        await queryRunner.query(`DROP TABLE "question_material_handling"`);
        await queryRunner.query(`ALTER TABLE "temporary_question_material_handling" RENAME TO "question_material_handling"`);
        await queryRunner.query(`CREATE TABLE "temporary_energySource" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "renewable" boolean DEFAULT (0), "approved" boolean DEFAULT (0), "nameId" integer NOT NULL, CONSTRAINT "UQ_539aec6ac8636c2fdf32a91920f" UNIQUE ("dId"), CONSTRAINT "UQ_efa7b713de326b29400c4fa6e68" UNIQUE ("uuid"), CONSTRAINT "FK_0cd2b5f12890dad806e8c7d469d" FOREIGN KEY ("nameId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_energySource"("dId", "id", "uuid", "tick", "renewable", "approved", "nameId") SELECT "dId", "id", "uuid", "tick", "renewable", "approved", "nameId" FROM "energySource"`);
        await queryRunner.query(`DROP TABLE "energySource"`);
        await queryRunner.query(`ALTER TABLE "temporary_energySource" RENAME TO "energySource"`);
        await queryRunner.query(`CREATE TABLE "temporary_question_electricity_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "produced" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_9497d14af152ff617f6c73fcb9d" UNIQUE ("dId"), CONSTRAINT "FK_d0a52325352157437286f1046c0" FOREIGN KEY ("metaId") REFERENCES "meta" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_4d99da71df7062bed82d6ba2de7" FOREIGN KEY ("accountRoleId") REFERENCES "accountRole" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_de1ea3a3703103201f2e355ab52" FOREIGN KEY ("zwhReportId") REFERENCES "zwh_report" ("id") ON DELETE CASCADE ON UPDATE NO ACTION, CONSTRAINT "FK_d81e4d2a6b9c69998a3fc9d3949" FOREIGN KEY ("sourceId") REFERENCES "energySource" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_c3a0b6c353e1cf9e39a7b58681f" FOREIGN KEY ("unitId") REFERENCES "unit" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_question_electricity_consumption"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "produced", "accountRoleId", "sourceId", "unitId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "produced", "accountRoleId", "sourceId", "unitId" FROM "question_electricity_consumption"`);
        await queryRunner.query(`DROP TABLE "question_electricity_consumption"`);
        await queryRunner.query(`ALTER TABLE "temporary_question_electricity_consumption" RENAME TO "question_electricity_consumption"`);
        await queryRunner.query(`CREATE TABLE "temporary_question_heat_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_499ea410d2eb0183d1bea397cd6" UNIQUE ("dId"), CONSTRAINT "FK_a4d4cf1742758bfa3939fbf8cc2" FOREIGN KEY ("metaId") REFERENCES "meta" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_5ae07ac907138fa04fc1876bb3a" FOREIGN KEY ("accountRoleId") REFERENCES "accountRole" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_1a50a1eaae8e280b0b662b8d8e4" FOREIGN KEY ("zwhReportId") REFERENCES "zwh_report" ("id") ON DELETE CASCADE ON UPDATE NO ACTION, CONSTRAINT "FK_d1bb715e61567edd5c76235e3ef" FOREIGN KEY ("sourceId") REFERENCES "energySource" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_b08e2fab272a9411a8981c0772c" FOREIGN KEY ("unitId") REFERENCES "unit" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_question_heat_consumption"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId" FROM "question_heat_consumption"`);
        await queryRunner.query(`DROP TABLE "question_heat_consumption"`);
        await queryRunner.query(`ALTER TABLE "temporary_question_heat_consumption" RENAME TO "question_heat_consumption"`);
        await queryRunner.query(`CREATE TABLE "temporary_question_transport_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_185aac9e201168128464a8e7fb2" UNIQUE ("dId"), CONSTRAINT "FK_4e00d826c06507d4a6ed217b5fd" FOREIGN KEY ("metaId") REFERENCES "meta" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_cded4d3a16ad0fdf35d04014436" FOREIGN KEY ("accountRoleId") REFERENCES "accountRole" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_3d1e58585f08bac93a16bd4694a" FOREIGN KEY ("zwhReportId") REFERENCES "zwh_report" ("id") ON DELETE CASCADE ON UPDATE NO ACTION, CONSTRAINT "FK_b0dbb1f85ecaaef52f0c31837de" FOREIGN KEY ("sourceId") REFERENCES "energySource" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_f759aed7c0ad4e93172f955aacf" FOREIGN KEY ("unitId") REFERENCES "unit" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_question_transport_consumption"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId" FROM "question_transport_consumption"`);
        await queryRunner.query(`DROP TABLE "question_transport_consumption"`);
        await queryRunner.query(`ALTER TABLE "temporary_question_transport_consumption" RENAME TO "question_transport_consumption"`);
        await queryRunner.query(`CREATE TABLE "temporary_waterSource" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "nameId" integer NOT NULL, CONSTRAINT "UQ_333544c3ae0354110d86b3737f0" UNIQUE ("dId"), CONSTRAINT "UQ_f41b0a5e9f25f9da66d6cf45831" UNIQUE ("uuid"), CONSTRAINT "FK_6090cd52a6444320a7296e8ef33" FOREIGN KEY ("nameId") REFERENCES "translation" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_waterSource"("dId", "id", "uuid", "tick", "nameId") SELECT "dId", "id", "uuid", "tick", "nameId" FROM "waterSource"`);
        await queryRunner.query(`DROP TABLE "waterSource"`);
        await queryRunner.query(`ALTER TABLE "temporary_waterSource" RENAME TO "waterSource"`);
        await queryRunner.query(`CREATE TABLE "temporary_question_water_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_c8b0fdad6ae7005dfc9fbd4c73b" UNIQUE ("dId"), CONSTRAINT "FK_ee45763d8a4302ba885fa71d9dd" FOREIGN KEY ("metaId") REFERENCES "meta" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_eed52f728c482590624ea48bb03" FOREIGN KEY ("accountRoleId") REFERENCES "accountRole" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_0524ce08c62ba0b1f9b7c759d1f" FOREIGN KEY ("zwhReportId") REFERENCES "zwh_report" ("id") ON DELETE CASCADE ON UPDATE NO ACTION, CONSTRAINT "FK_c8458650f6d9ebf4ecde4185fc1" FOREIGN KEY ("sourceId") REFERENCES "waterSource" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION, CONSTRAINT "FK_791ca4ec73286261e4d91285439" FOREIGN KEY ("unitId") REFERENCES "unit" ("id") ON DELETE NO ACTION ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_question_water_consumption"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId" FROM "question_water_consumption"`);
        await queryRunner.query(`DROP TABLE "question_water_consumption"`);
        await queryRunner.query(`ALTER TABLE "temporary_question_water_consumption" RENAME TO "question_water_consumption"`);
        await queryRunner.query(`CREATE TABLE "temporary_delegation_state_transition" ("dId" integer, "tick" integer, "question" varchar NOT NULL, "questionTitle" varchar, "toState" varchar NOT NULL, "createdAt" integer NOT NULL, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "message" varchar, "deadline" integer, "userId" integer, "accountRoleId" integer, CONSTRAINT "UQ_f0c9f8884b95c42a2b9185c2888" UNIQUE ("dId"), CONSTRAINT "UQ_f9b94eec279b456a394f84ba647" UNIQUE ("uuid"), CONSTRAINT "FK_7490e427e1a99e2f04bb8e6e005" FOREIGN KEY ("userId") REFERENCES "user" ("id") ON DELETE CASCADE ON UPDATE NO ACTION, CONSTRAINT "FK_1bab6314b6d282f7c8f2b8128bb" FOREIGN KEY ("accountRoleId") REFERENCES "accountRole" ("id") ON DELETE CASCADE ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_delegation_state_transition"("dId", "tick", "question", "questionTitle", "toState", "createdAt", "id", "uuid", "message", "deadline", "userId", "accountRoleId") SELECT "dId", "tick", "question", "questionTitle", "toState", "createdAt", "id", "uuid", "message", "deadline", "userId", "accountRoleId" FROM "delegation_state_transition"`);
        await queryRunner.query(`DROP TABLE "delegation_state_transition"`);
        await queryRunner.query(`ALTER TABLE "temporary_delegation_state_transition" RENAME TO "delegation_state_transition"`);
        await queryRunner.query(`CREATE TABLE "temporary_progress_state_transition" ("dId" integer, "tick" integer, "question" varchar NOT NULL, "questionTitle" varchar, "toState" varchar NOT NULL, "createdAt" integer NOT NULL, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "userId" integer, CONSTRAINT "UQ_671c0dac236dfa15d2c9c1fd27b" UNIQUE ("dId"), CONSTRAINT "FK_4636d495e7449e04305712db4c0" FOREIGN KEY ("userId") REFERENCES "user" ("id") ON DELETE CASCADE ON UPDATE NO ACTION)`);
        await queryRunner.query(`INSERT INTO "temporary_progress_state_transition"("dId", "tick", "question", "questionTitle", "toState", "createdAt", "id", "userId") SELECT "dId", "tick", "question", "questionTitle", "toState", "createdAt", "id", "userId" FROM "progress_state_transition"`);
        await queryRunner.query(`DROP TABLE "progress_state_transition"`);
        await queryRunner.query(`ALTER TABLE "temporary_progress_state_transition" RENAME TO "progress_state_transition"`);
    }

    public async down(queryRunner: QueryRunner): Promise<void> {
        await queryRunner.query(`ALTER TABLE "progress_state_transition" RENAME TO "temporary_progress_state_transition"`);
        await queryRunner.query(`CREATE TABLE "progress_state_transition" ("dId" integer, "tick" integer, "question" varchar NOT NULL, "questionTitle" varchar, "toState" varchar NOT NULL, "createdAt" integer NOT NULL, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "userId" integer, CONSTRAINT "UQ_671c0dac236dfa15d2c9c1fd27b" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "progress_state_transition"("dId", "tick", "question", "questionTitle", "toState", "createdAt", "id", "userId") SELECT "dId", "tick", "question", "questionTitle", "toState", "createdAt", "id", "userId" FROM "temporary_progress_state_transition"`);
        await queryRunner.query(`DROP TABLE "temporary_progress_state_transition"`);
        await queryRunner.query(`ALTER TABLE "delegation_state_transition" RENAME TO "temporary_delegation_state_transition"`);
        await queryRunner.query(`CREATE TABLE "delegation_state_transition" ("dId" integer, "tick" integer, "question" varchar NOT NULL, "questionTitle" varchar, "toState" varchar NOT NULL, "createdAt" integer NOT NULL, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "message" varchar, "deadline" integer, "userId" integer, "accountRoleId" integer, CONSTRAINT "UQ_f0c9f8884b95c42a2b9185c2888" UNIQUE ("dId"), CONSTRAINT "UQ_f9b94eec279b456a394f84ba647" UNIQUE ("uuid"))`);
        await queryRunner.query(`INSERT INTO "delegation_state_transition"("dId", "tick", "question", "questionTitle", "toState", "createdAt", "id", "uuid", "message", "deadline", "userId", "accountRoleId") SELECT "dId", "tick", "question", "questionTitle", "toState", "createdAt", "id", "uuid", "message", "deadline", "userId", "accountRoleId" FROM "temporary_delegation_state_transition"`);
        await queryRunner.query(`DROP TABLE "temporary_delegation_state_transition"`);
        await queryRunner.query(`ALTER TABLE "question_water_consumption" RENAME TO "temporary_question_water_consumption"`);
        await queryRunner.query(`CREATE TABLE "question_water_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_c8b0fdad6ae7005dfc9fbd4c73b" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "question_water_consumption"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId" FROM "temporary_question_water_consumption"`);
        await queryRunner.query(`DROP TABLE "temporary_question_water_consumption"`);
        await queryRunner.query(`ALTER TABLE "waterSource" RENAME TO "temporary_waterSource"`);
        await queryRunner.query(`CREATE TABLE "waterSource" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "nameId" integer NOT NULL, CONSTRAINT "UQ_333544c3ae0354110d86b3737f0" UNIQUE ("dId"), CONSTRAINT "UQ_f41b0a5e9f25f9da66d6cf45831" UNIQUE ("uuid"))`);
        await queryRunner.query(`INSERT INTO "waterSource"("dId", "id", "uuid", "tick", "nameId") SELECT "dId", "id", "uuid", "tick", "nameId" FROM "temporary_waterSource"`);
        await queryRunner.query(`DROP TABLE "temporary_waterSource"`);
        await queryRunner.query(`ALTER TABLE "question_transport_consumption" RENAME TO "temporary_question_transport_consumption"`);
        await queryRunner.query(`CREATE TABLE "question_transport_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_185aac9e201168128464a8e7fb2" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "question_transport_consumption"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId" FROM "temporary_question_transport_consumption"`);
        await queryRunner.query(`DROP TABLE "temporary_question_transport_consumption"`);
        await queryRunner.query(`ALTER TABLE "question_heat_consumption" RENAME TO "temporary_question_heat_consumption"`);
        await queryRunner.query(`CREATE TABLE "question_heat_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_499ea410d2eb0183d1bea397cd6" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "question_heat_consumption"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "accountRoleId", "sourceId", "unitId" FROM "temporary_question_heat_consumption"`);
        await queryRunner.query(`DROP TABLE "temporary_question_heat_consumption"`);
        await queryRunner.query(`ALTER TABLE "question_electricity_consumption" RENAME TO "temporary_question_electricity_consumption"`);
        await queryRunner.query(`CREATE TABLE "question_electricity_consumption" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "consumed" integer DEFAULT (0), "produced" integer DEFAULT (0), "accountRoleId" integer, "sourceId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_9497d14af152ff617f6c73fcb9d" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "question_electricity_consumption"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "produced", "accountRoleId", "sourceId", "unitId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "consumed", "produced", "accountRoleId", "sourceId", "unitId" FROM "temporary_question_electricity_consumption"`);
        await queryRunner.query(`DROP TABLE "temporary_question_electricity_consumption"`);
        await queryRunner.query(`ALTER TABLE "energySource" RENAME TO "temporary_energySource"`);
        await queryRunner.query(`CREATE TABLE "energySource" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "renewable" boolean DEFAULT (0), "approved" boolean DEFAULT (0), "nameId" integer NOT NULL, CONSTRAINT "UQ_539aec6ac8636c2fdf32a91920f" UNIQUE ("dId"), CONSTRAINT "UQ_efa7b713de326b29400c4fa6e68" UNIQUE ("uuid"))`);
        await queryRunner.query(`INSERT INTO "energySource"("dId", "id", "uuid", "tick", "renewable", "approved", "nameId") SELECT "dId", "id", "uuid", "tick", "renewable", "approved", "nameId" FROM "temporary_energySource"`);
        await queryRunner.query(`DROP TABLE "temporary_energySource"`);
        await queryRunner.query(`ALTER TABLE "question_material_handling" RENAME TO "temporary_question_material_handling"`);
        await queryRunner.query(`CREATE TABLE "question_material_handling" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "openingBalance" integer DEFAULT (0), "procurement" integer DEFAULT (0), "usedInProduction" integer DEFAULT (0), "disposed" integer DEFAULT (0), "transferred" integer DEFAULT (0), "sold" integer DEFAULT (0), "endingBalance" integer DEFAULT (0), "numberOfSuppliers" integer DEFAULT (0), "accountRoleId" integer, "materialId" integer NOT NULL, "unitId" integer, CONSTRAINT "UQ_9757c6b72b945137960758e80cf" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "question_material_handling"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "openingBalance", "procurement", "usedInProduction", "disposed", "transferred", "sold", "endingBalance", "numberOfSuppliers", "accountRoleId", "materialId", "unitId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "openingBalance", "procurement", "usedInProduction", "disposed", "transferred", "sold", "endingBalance", "numberOfSuppliers", "accountRoleId", "materialId", "unitId" FROM "temporary_question_material_handling"`);
        await queryRunner.query(`DROP TABLE "temporary_question_material_handling"`);
        await queryRunner.query(`ALTER TABLE "unit" RENAME TO "temporary_unit"`);
        await queryRunner.query(`CREATE TABLE "unit" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "tick" integer, "symbol" varchar NOT NULL, "nameId" integer, CONSTRAINT "UQ_7fc01c7e48d34784d0e0b115ad3" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "unit"("dId", "id", "tick", "symbol", "nameId") SELECT "dId", "id", "tick", "symbol", "nameId" FROM "temporary_unit"`);
        await queryRunner.query(`DROP TABLE "temporary_unit"`);
        await queryRunner.query(`ALTER TABLE "material" RENAME TO "temporary_material"`);
        await queryRunner.query(`CREATE TABLE "material" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "renewable" boolean DEFAULT (0), "approved" boolean DEFAULT (0), "nameId" integer NOT NULL, CONSTRAINT "UQ_71c728a2dfea42fb19af1d84f6a" UNIQUE ("dId"), CONSTRAINT "UQ_c9598c156ad443188d8f245f883" UNIQUE ("uuid"))`);
        await queryRunner.query(`INSERT INTO "material"("dId", "id", "uuid", "tick", "renewable", "approved", "nameId") SELECT "dId", "id", "uuid", "tick", "renewable", "approved", "nameId" FROM "temporary_material"`);
        await queryRunner.query(`DROP TABLE "temporary_material"`);
        await queryRunner.query(`ALTER TABLE "question_employees" RENAME TO "temporary_question_employees"`);
        await queryRunner.query(`CREATE TABLE "question_employees" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "value" integer, "accountRoleId" integer, CONSTRAINT "UQ_2089a6d88dbee51022e4fa26317" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "question_employees"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "value", "accountRoleId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "value", "accountRoleId" FROM "temporary_question_employees"`);
        await queryRunner.query(`DROP TABLE "temporary_question_employees"`);
        await queryRunner.query(`ALTER TABLE "question_container" RENAME TO "temporary_question_container"`);
        await queryRunner.query(`CREATE TABLE "question_container" ("dId" integer, "tick" integer, "title" varchar, "metaId" integer NOT NULL, "uuid" varchar NOT NULL, "zwhReportId" integer NOT NULL, "revision" integer, "deadlineDelegation" integer, "progressState" varchar, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "accountRoleId" integer, CONSTRAINT "UQ_0b2784261d31f59845cf064399f" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "question_container"("dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "accountRoleId") SELECT "dId", "tick", "title", "metaId", "uuid", "zwhReportId", "revision", "deadlineDelegation", "progressState", "createdAt", "updatedAt", "deleted", "id", "accountRoleId" FROM "temporary_question_container"`);
        await queryRunner.query(`DROP TABLE "temporary_question_container"`);
        await queryRunner.query(`ALTER TABLE "zwh_report" RENAME TO "temporary_zwh_report"`);
        await queryRunner.query(`CREATE TABLE "zwh_report" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "tick" integer, "dId" integer, "uuid" varchar NOT NULL, "accountId" integer NOT NULL, "year" integer NOT NULL, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), CONSTRAINT "UQ_408dacbdfe3a1efd00be6609b5c" UNIQUE ("dId"), CONSTRAINT "UQ_803a7a6b781a734981eca3f95c2" UNIQUE ("uuid"))`);
        await queryRunner.query(`INSERT INTO "zwh_report"("id", "tick", "dId", "uuid", "accountId", "year", "createdAt", "updatedAt", "deleted") SELECT "id", "tick", "dId", "uuid", "accountId", "year", "createdAt", "updatedAt", "deleted" FROM "temporary_zwh_report"`);
        await queryRunner.query(`DROP TABLE "temporary_zwh_report"`);
        await queryRunner.query(`ALTER TABLE "meta" RENAME TO "temporary_meta"`);
        await queryRunner.query(`CREATE TABLE "meta" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "version" varchar NOT NULL, "tick" integer, "dId" integer NOT NULL, "quid" varchar NOT NULL, "className" varchar NOT NULL, "category" varchar NOT NULL, "level" integer NOT NULL, "sequenceNumber" integer NOT NULL, "titleId" integer, "subtitleId" integer, "descriptionId" integer, "infoId" integer, CONSTRAINT "UQ_83fb6670a4c4f3696737d87aad7" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "meta"("id", "version", "tick", "dId", "quid", "className", "category", "level", "sequenceNumber", "titleId", "subtitleId", "descriptionId", "infoId") SELECT "id", "version", "tick", "dId", "quid", "className", "category", "level", "sequenceNumber", "titleId", "subtitleId", "descriptionId", "infoId" FROM "temporary_meta"`);
        await queryRunner.query(`DROP TABLE "temporary_meta"`);
        await queryRunner.query(`ALTER TABLE "accountRole" RENAME TO "temporary_accountRole"`);
        await queryRunner.query(`CREATE TABLE "accountRole" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "dId" integer, "tick" integer, "uuid" varchar NOT NULL, "name" varchar NOT NULL, "userId" integer, "totalQuestions" integer, "registrationLink" varchar, "expirationTime" integer, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "deleted" boolean NOT NULL DEFAULT (0), "accountId" integer NOT NULL, CONSTRAINT "UQ_d2e5f074c66b8e15f71ff54acbb" UNIQUE ("dId"), CONSTRAINT "UQ_fa6219e337b99ffe8f909da74de" UNIQUE ("uuid"))`);
        await queryRunner.query(`INSERT INTO "accountRole"("id", "dId", "tick", "uuid", "name", "userId", "totalQuestions", "registrationLink", "expirationTime", "createdAt", "updatedAt", "deleted", "accountId") SELECT "id", "dId", "tick", "uuid", "name", "userId", "totalQuestions", "registrationLink", "expirationTime", "createdAt", "updatedAt", "deleted", "accountId" FROM "temporary_accountRole"`);
        await queryRunner.query(`DROP TABLE "temporary_accountRole"`);
        await queryRunner.query(`ALTER TABLE "user" RENAME TO "temporary_user"`);
        await queryRunner.query(`CREATE TABLE "user" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "tick" integer, "dId" integer NOT NULL, "uuid" varchar NOT NULL, "accountId" integer NOT NULL, "firstname" varchar, "lastname" varchar, "username" varchar NOT NULL, "email" varchar NOT NULL, "confirmedEmail" boolean NOT NULL, "roles" text NOT NULL, "createdAt" integer NOT NULL, "updatedAt" integer NOT NULL, "accessTokenId" integer, "refreshTokenId" integer, CONSTRAINT "UQ_8acd9f2aef4d26aebbb39c42385" UNIQUE ("dId"), CONSTRAINT "UQ_a95e949168be7b7ece1a2382fed" UNIQUE ("uuid"), CONSTRAINT "REL_ba259dd78f192f9d78ef47aef8" UNIQUE ("accessTokenId"), CONSTRAINT "REL_7c91492fa6749e6d222216fa87" UNIQUE ("refreshTokenId"))`);
        await queryRunner.query(`INSERT INTO "user"("id", "tick", "dId", "uuid", "accountId", "firstname", "lastname", "username", "email", "confirmedEmail", "roles", "createdAt", "updatedAt", "accessTokenId", "refreshTokenId") SELECT "id", "tick", "dId", "uuid", "accountId", "firstname", "lastname", "username", "email", "confirmedEmail", "roles", "createdAt", "updatedAt", "accessTokenId", "refreshTokenId" FROM "temporary_user"`);
        await queryRunner.query(`DROP TABLE "temporary_user"`);
        await queryRunner.query(`ALTER TABLE "account" RENAME TO "temporary_account"`);
        await queryRunner.query(`CREATE TABLE "account" ("id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "uuid" varchar NOT NULL, "tick" integer, "dId" integer NOT NULL, "companyName" varchar, "telefon" varchar, "website" varchar, "contact" varchar, "companyDescription" varchar, "furtherInformation" varchar, "createdAt" integer, "updatedAt" integer, "legalFormId" integer, "headquartersId" integer, "employeeCountId" integer, "industryBranchId" integer, "businessId" integer, "tradeId" integer, CONSTRAINT "UQ_31e2fd7720a2da3af586f17778f" UNIQUE ("uuid"))`);
        await queryRunner.query(`INSERT INTO "account"("id", "uuid", "tick", "dId", "companyName", "telefon", "website", "contact", "companyDescription", "furtherInformation", "createdAt", "updatedAt", "legalFormId", "headquartersId", "employeeCountId", "industryBranchId", "businessId", "tradeId") SELECT "id", "uuid", "tick", "dId", "companyName", "telefon", "website", "contact", "companyDescription", "furtherInformation", "createdAt", "updatedAt", "legalFormId", "headquartersId", "employeeCountId", "industryBranchId", "businessId", "tradeId" FROM "temporary_account"`);
        await queryRunner.query(`DROP TABLE "temporary_account"`);
        await queryRunner.query(`ALTER TABLE "trade" RENAME TO "temporary_trade"`);
        await queryRunner.query(`CREATE TABLE "trade" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_0e91295657c0cc2a5e3dfb26016" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "trade"("dId", "id", "nameId") SELECT "dId", "id", "nameId" FROM "temporary_trade"`);
        await queryRunner.query(`DROP TABLE "temporary_trade"`);
        await queryRunner.query(`ALTER TABLE "industryBranch" RENAME TO "temporary_industryBranch"`);
        await queryRunner.query(`CREATE TABLE "industryBranch" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_8320b4bf759d72f7c7269a15d60" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "industryBranch"("dId", "id", "nameId") SELECT "dId", "id", "nameId" FROM "temporary_industryBranch"`);
        await queryRunner.query(`DROP TABLE "temporary_industryBranch"`);
        await queryRunner.query(`ALTER TABLE "headquarters" RENAME TO "temporary_headquarters"`);
        await queryRunner.query(`CREATE TABLE "headquarters" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_340fa075ad60415bc6e0eaffb95" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "headquarters"("dId", "id", "nameId") SELECT "dId", "id", "nameId" FROM "temporary_headquarters"`);
        await queryRunner.query(`DROP TABLE "temporary_headquarters"`);
        await queryRunner.query(`ALTER TABLE "business" RENAME TO "temporary_business"`);
        await queryRunner.query(`CREATE TABLE "business" ("dId" integer, "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL, "nameId" integer NOT NULL, CONSTRAINT "UQ_5d5550387a5ba571d7ed792151d" UNIQUE ("dId"))`);
        await queryRunner.query(`INSERT INTO "business"("dId", "id", "nameId") SELECT "dId", "id", "nameId" FROM "temporary_business"`);
        await queryRunner.query(`DROP TABLE "temporary_business"`);
        await queryRunner.query(`DROP TABLE "sync"`);
        await queryRunner.query(`DROP TABLE "progress_state_transition"`);
        await queryRunner.query(`DROP TABLE "delegation_state_transition"`);
        await queryRunner.query(`DROP TABLE "question_water_consumption"`);
        await queryRunner.query(`DROP TABLE "waterSource"`);
        await queryRunner.query(`DROP TABLE "question_transport_consumption"`);
        await queryRunner.query(`DROP TABLE "question_heat_consumption"`);
        await queryRunner.query(`DROP TABLE "question_electricity_consumption"`);
        await queryRunner.query(`DROP TABLE "energySource"`);
        await queryRunner.query(`DROP TABLE "question_material_handling"`);
        await queryRunner.query(`DROP TABLE "unit"`);
        await queryRunner.query(`DROP TABLE "material"`);
        await queryRunner.query(`DROP TABLE "question_employees"`);
        await queryRunner.query(`DROP TABLE "question_container"`);
        await queryRunner.query(`DROP TABLE "zwh_report"`);
        await queryRunner.query(`DROP TABLE "meta"`);
        await queryRunner.query(`DROP TABLE "accountRole"`);
        await queryRunner.query(`DROP TABLE "user"`);
        await queryRunner.query(`DROP TABLE "token"`);
        await queryRunner.query(`DROP TABLE "account"`);
        await queryRunner.query(`DROP TABLE "trade"`);
        await queryRunner.query(`DROP TABLE "legalForm"`);
        await queryRunner.query(`DROP TABLE "industryBranch"`);
        await queryRunner.query(`DROP TABLE "headquarters"`);
        await queryRunner.query(`DROP TABLE "employeeCount"`);
        await queryRunner.query(`DROP TABLE "business"`);
        await queryRunner.query(`DROP TABLE "translation"`);
    }

}
