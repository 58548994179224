/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UserStateQuery } from 'src/app/store/query/user.state.query';
import { isDefined } from 'src/app/util/util.function';
import { Unauthorized } from './exceptions/security.error';
import { RoleAccessLevel } from './role.accesslevel';


@Injectable({providedIn: 'root'})
export class MasterOnlyGuard implements CanActivate{

  constructor(
    private readonly translator: TranslateService
  ){}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return UserStateQuery.getAuthenticatedUserStream().pipe(
      first(user => isDefined(user.roles)),
      map(user => {
        if(user.roles.includes(RoleAccessLevel.admin) || user.roles.includes(RoleAccessLevel.master)){
          return true;
        }else{
          this.translator.get('security.master_only').subscribe( res => {
            throw new Unauthorized(res.title, res.message);
          });
        }
      })
      );
  }

}
