import { Observable } from 'rxjs';
import { first, timeout } from 'rxjs/operators';
import { AppStateStore } from '../app.state.store';

export class DBStateQuery{


  /**
   *
   * @returns true or false
   */
  static isReady(): boolean{
    return AppStateStore.dbReady.getValue();
  }

  /**
   * @returns true
   * @throws timeoutError
   */
  static firstReady(): Observable<boolean>{
    return AppStateStore.dbReady.pipe(first(ready => ready), timeout(5000));
  }

  /**
   * initial value is false unitl connection is established
   *
   * @returns true | false
   */
  static isReadyRawStream(): Observable<boolean>{
    return AppStateStore.dbReady.asObservable();
  }


}
