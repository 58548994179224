import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { getConnection } from 'typeorm';
import { Business } from '../entities/Taxonomy/Business';
import { EmployeeCount } from '../entities/Taxonomy/EmployeeCount';
import { EnergySource } from '../entities/Taxonomy/EnergySource';
import { Headquarters } from '../entities/Taxonomy/Headquarters';
import { IndustryBranch } from '../entities/Taxonomy/IndustryBranch';
import { LegalForm } from '../entities/Taxonomy/LegalForm';
import { Material } from '../entities/Taxonomy/Material';
import { TaxonomyContainer } from '../entities/Taxonomy/TaxonomyContainer';
import { Trade } from '../entities/Taxonomy/Trade';
import { Unit } from '../entities/Taxonomy/Unit';
import { WaterSource } from '../entities/Taxonomy/WaterSource';
import { TreeEntity } from '../entities/TreeEntity';
import { DBStateQuery } from '../store/query/db.state.query';

export class Taxonomy{};


export class TaxonomyRepository {


  static persistRemoteData(taxonomyContainer: TaxonomyContainer, inDB: boolean){
    if(inDB){
      return DBStateQuery.firstReady().pipe(
        switchMap( async _ => {
          const result: TaxonomyContainer = new TaxonomyContainer();
          for await (const key of Object.keys(taxonomyContainer)) {
            const values = taxonomyContainer[key];
            const container: Taxonomy[] = [];
            result[key] = container;
            for await (const element of values) {
              if(element instanceof TreeEntity){
                const entry = await element.saveRemoteTree();
                container.push(entry);
              }
            }
          }
          return result;
        })
      );
    }else{
      return of(taxonomyContainer);
    }
  }

  static retrieveOrLoadTaxonomy(): Observable<TaxonomyContainer>{
      return DBStateQuery.firstReady()
        .pipe(
          map( async () => {
            const taxonomy = new TaxonomyContainer();
            const manager = getConnection().createEntityManager();
            taxonomy.industryBranch = await manager.find(IndustryBranch, {relations: ['name']});
            taxonomy.business = await manager.find(Business, {relations: ['name']});
            taxonomy.trade = await manager.find(Trade, {relations: ['name']});
            taxonomy.employeeCount = await manager.find(EmployeeCount);
            taxonomy.legalForm = await manager.find(LegalForm);
            taxonomy.headquarters = await manager.find(Headquarters, {relations: ['name']});
            taxonomy.material = await manager.find(Material, {relations: ['name']});
            taxonomy.waterSource = await manager.find(WaterSource, {relations: ['name']});
            taxonomy.energySource = await manager.find(EnergySource, {relations: ['name']});
            taxonomy.unit = await manager.find(Unit, {relations: ['name']});
            return taxonomy;
          }),
          switchMap(promise => from(promise))
        );
  }


}
