import { Expose } from 'class-transformer';
import { Column } from 'typeorm';
import { TreeEntity } from '../TreeEntity';

/**
 * marker class
 */
export abstract class AbstractTaxonomy extends TreeEntity{

  /**
   * @var delegate_api id
   */
  @Expose({name: 'id',toClassOnly:true})
  @Column({nullable: true, unique: true})
  dId: number;

  @Expose({ name: 'id', toPlainOnly: true })
  getRemoteId(){
    return this.dId;
  }

  abstract toString(): string;
}
