import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { classToPlain, plainToClass } from 'class-transformer';
import { first, map, switchMap } from 'rxjs/operators';
import { DelegationStateTransition } from 'src/app/entities/State/DelegationStateTransition';
import { ZWHReport } from 'src/app/entities/ZWHReport';
import { TransitionRepository } from 'src/app/repositories/transition.repository';
import { DelegateRestService } from './delegate.rest.service';

@Injectable({
  providedIn: 'root'
})
export class RestDelegations{

  constructor(
    protected readonly rest: DelegateRestService,
    protected readonly platform: Platform
  ){}


  getDelegations(report?: ZWHReport){
    return this.rest.get<DelegationStateTransition[]>('/restapi/messages/delegation/report/'+report.dId,{auth: 'oauth2'}).pipe(
      first(),
      map( delegations => plainToClass(DelegationStateTransition, delegations, {groups: ['showState']}) ),
      switchMap(transitions => TransitionRepository.persistRemoteData(transitions, this.platform.is('capacitor')))
    );
  }

  updateDelegations(delegations: DelegationStateTransition[]){
  const plain =  classToPlain(delegations, {groups: ['showState'],exposeUnsetFields: true });
    return this.rest.post<DelegationStateTransition[]>(
      '/restapi/messages/delegation/update',
      plain
     ,
      {auth: 'oauth2'}).pipe(
      first(),
      map( states => plainToClass(DelegationStateTransition, states, {groups: ['showState']}) ),
      switchMap(transitions => TransitionRepository.persistRemoteData(transitions, this.platform.is('capacitor')))
    );
  }


}
