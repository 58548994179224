/* eslint-disable @typescript-eslint/ban-types */
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { exhaustMap, first, tap } from 'rxjs/operators';
import { isDefined } from 'src/app/util/util.function';
import { environment } from 'src/environments/environment';
import { DelegateHttpInterface, DELEGATE_HTTP_INTERFACE } from './http/delegate.http.interface';
import { DelegateRequestOptions } from './http/request/delegate.requestoptions';
import { TokenManagerInterface, TOKEN_MANAGER_INTERFACE } from './tokenmanager/token.manager.interface';

@Injectable({
  providedIn: 'root'
})
export class DelegateRestService {

  constructor(
    @Inject(DELEGATE_HTTP_INTERFACE) private readonly http: DelegateHttpInterface,
    @Inject(TOKEN_MANAGER_INTERFACE) private readonly tokenManager: TokenManagerInterface) { }

  get<T>(path: string, options: DelegateRequestOptions): Observable<T>{
    return this.request<T>(path, options, (url, opt, error?) => this.http.get<T>(url, opt));
  }

  post<T>(path: string, body: object, options: DelegateRequestOptions): Observable<T>{
    // console.log('TREE UPDATE OUT: ', body);
    return this.request<T>(path, options, (url, opt, error?) => this.http.post<T>(url,JSON.stringify(body), opt)).pipe(
      // tap( resp => console.log('TREE UPDATE IN: ', resp))
    );
  }

  private request<T>( path: string, options: DelegateRequestOptions,
    execute: (path: string, options: DelegateRequestOptions, error?: any) => Observable<T>){

    const url = `${environment.remoteBaseUrl}${path}`;
    const headers: Array<[string, string]> = [];

    if(isDefined(options.headers)){
      options.headers.forEach(h => headers.push(h));
    }

    if(isDefined(options.contentType)){
      headers.push(['Content-Type', options.contentType]);
    }else{
      headers.push(['Content-Type', 'application/json']);
    }

    if(isDefined(options.auth) && options.auth === 'oauth2'){
      return this.tokenManager.getAccessToken()
      .pipe(
        first(),
        tap( token => {
          headers.push(['Authorization', token]);
        }),
        exhaustMap( token => {
          const adjOpts = {...options, headers};
          return execute(url, adjOpts);
        })
      );
    }else{
      const adjOpts = {...options, headers};
      return execute(url, adjOpts);
    }


  }

}
