/* eslint-disable max-len */
import { Platform } from '@ionic/angular';
import { Observable, of, pipe, throwError, UnaryFunction } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Syncdirection } from 'src/app/entities/Sync/SyncEntry';
import { TreeEntity } from '../../entities/TreeEntity';
import { RefreshTokenExpired } from '../../services/security/exceptions/security.error';
import { AbstractSynchronizer } from '../synchronization/abstract.synchronizer';



export abstract class AbstractStateManager<T extends TreeEntity> extends AbstractSynchronizer<T>{

  protected constructor(
    protected readonly platform: Platform
  ) {
    super(platform);
  }

/**
 *
 * @param defaultValue to return if error occurs
 */
  protected handleErrorsPlatformDependent<U>(ctor: abstract new (...args: any[]) => U, direction: Syncdirection, defaultValue): UnaryFunction<Observable<unknown>, Observable<any>>;
  protected handleErrorsPlatformDependent<U>(ctor: new (...args: any[]) => U, direction: Syncdirection, defaultValue: any = null): UnaryFunction<Observable<unknown>, Observable<any>>{
     return pipe(
      catchError(e => {
        if(this.platform.is('capacitor')){
          this.notifySyncFailure(ctor, e, direction);
          if(e instanceof RefreshTokenExpired){
            return throwError(e);
          }
          return of(defaultValue);
        }else{
          return throwError(e);
        }
      })
     );
 }



 protected verifySuccess<U>(ctor: abstract new (...args: any[]) => U, expect: T[]): UnaryFunction<Observable<T[]>, Observable<T[]>>;
 protected verifySuccess<U>(ctor: new (...args: any[]) => U, expect: T[]): UnaryFunction<Observable<T[]>, Observable<T[]>>{
  return pipe(
    switchMap((updates: T[]) => {
      if(updates.length > 0 && updates.length === expect.length){
        this.notifySyncSuccess(ctor, updates, 'up');
        return of(updates);
      }else{
        const error = new Error('expected response count to be same size as given updates');
        this.notifySyncFailure(ctor, error, 'up');
        return throwError( error);
      }
    }),
  );
}





 /**
  * Gets an entity from the server and persists entity in in db
  *
  * @param dId serverside id
  */
protected abstract pull(dId: number): Observable<T>;


/**
 * Gets all entities from the server and persists entities concurrently in db
 */
 protected abstract pullAll(): Observable<T[]>;


 /**
  * updates single entity by first committing pending changes,
  * then persisting server response
  */
protected abstract  pushAndPull(entity: T): Observable<T>;


/**
 * Pushes all pending changes to the server,
 * then gets all entities from server and persists entities concurrently in db
 * needs to notify sync success or failure (e.g. by calling commit)
 */
protected abstract  pushAndPullAll(): Observable<T[]>;



}
