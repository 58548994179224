
import {Exclude, Expose, Type } from 'class-transformer';
import {Column, Entity, PrimaryGeneratedColumn, OneToOne, JoinColumn, ManyToOne, BeforeInsert} from 'typeorm';
import { isDefined } from '../util/util.function';
import { Token } from './Access/Token';
import { Account } from './Account';
import { TreeEntity } from './TreeEntity';

@Entity({name: 'user'})
export class User extends TreeEntity{

  @Exclude({toPlainOnly: true})
  @PrimaryGeneratedColumn()
  id: number;

  @Column({ nullable: true})
  tick: number;

  /**
   * @var delegate_api id
   */
  @Expose({name: 'id',toClassOnly:true})
  @Column({unique: true})
  dId: number;

  @Column({ nullable: false, unique: true})
  uuid: string;

  @Expose( {groups: ['showUser']})
  @Type(() => Account)
  @ManyToOne(type => Account, {cascade: true, eager: true, onDelete: 'CASCADE'})
  @JoinColumn()
  account: Account;

  @Expose( {groups: ['showUser']})
  @Exclude({ toPlainOnly: true })
  @Column()
  accountId: number;

  @Expose( {groups: ['showUser']})
  @Column({nullable: true})
  firstname: string;

  @Expose( {groups: ['showUser']})
  @Column({nullable: true})
  lastname: string;

  @Expose( {groups: ['showUser']})
  @Column()
  username: string;

  @Expose( {groups: ['showUser']})
  @Column({nullable: false})
  email: string;

  @Expose( {groups: ['showUser']})
  @Column()
  confirmedEmail: boolean;

  @Expose( {groups: ['showUser']})
  @Column({type: 'simple-array'})
  roles: Array<string>;

  @Expose( {groups: ['showUser']})
  @Column()
  createdAt: number;

  @Expose( {groups: ['showUser']})
  @Column()
  updatedAt: number;

  @Expose( {groups: ['showUserToken'], toClassOnly: true})
  @Type(() => Token)
  @OneToOne(type => Token, {nullable: true, cascade:['insert','update'],onDelete: 'SET NULL', orphanedRowAction: 'delete'})
  @JoinColumn()
  accessToken: Token;

  @Expose( {groups: ['showUserToken'],  toClassOnly: true})
  @Type(() => Token)
  @OneToOne(type => Token, {nullable: true,cascade:['insert','update'],onDelete: 'SET NULL', orphanedRowAction: 'delete'})
  @JoinColumn()
  refreshToken: Token;

  @Expose({ name: 'id', toPlainOnly: true })
  getRemoteId(){
    return this.dId;
  }


  @BeforeInsert()
  setDates(){
    if(!isDefined(this.updatedAt)){
      this.updatedAt = Math.round(Date.now()/1000);
    }
    if(!isDefined(this.createdAt)){
      this.createdAt = Math.round(Date.now()/1000);
    }
  }


}


