import { Exclude, Expose, Type } from 'class-transformer';
import { isDefined } from 'src/app/util/util.function';
import {BeforeInsert, BeforeUpdate, Column, ManyToOne} from 'typeorm';
import { TreeEntity } from '../TreeEntity';
import { AccountRole } from '../Access/AccountRole';
import {v4 as uuidv4} from 'uuid';
import { Meta } from './Meta';
import { ZWHReport } from '../ZWHReport';



export abstract class AbstractQuestion extends TreeEntity {

   /**
    * @var delegate_api id
    */
  @Expose({ name: 'id', toClassOnly:true})
  @Column({ unique: true , nullable: true})
  dId: number;

  @Column({ nullable: true})
  tick: number;

  @Expose( {groups: ['showQuestion']})
  @Type(() => Meta)
  @ManyToOne(() => Meta, { eager: true})
  meta: Meta;

  @Expose({toClassOnly: true})
  @Column({nullable: true})
  title: string;

  @Exclude({ toPlainOnly: true })
  @Column()
  metaId: number;

  @Column({ nullable: false})
  uuid: string;

  @Expose( {groups: ['showQuestion']})
  @Type(() => AccountRole)
  @ManyToOne(type => AccountRole, {nullable: true, eager: true})
  accountRole: AccountRole;

  @Expose( { groups: ['showQuestion']})
  @Type(() => ZWHReport)
  @ManyToOne(type => ZWHReport, { eager: true, onDelete: 'CASCADE'})
  zwhReport: ZWHReport;

  @Expose( {groups: ['showQuestion']})
  @Exclude({ toPlainOnly: true })
  @Column()
  zwhReportId: number;

  // @Transform( ({ value }) => value.id, { toClassOnly: true } )
  // @Expose({ name: 'revisions', toClassOnly: true })
  @Column({nullable: true})
  revision: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true})
  deadlineDelegation: number;

  @Expose( {groups: ['showQuestion']})
  @Column({nullable: true})
  progressState: string;

  @Expose( {groups: ['showQuestion']})
  @Column()
  createdAt: number;

  @Expose( {groups: ['showQuestion']})
  @Column()
  updatedAt: number;

  @Expose( {groups: ['showQuestion']})
  @Column({default: false})
  deleted: boolean;


  @Expose({ name: 'id', toPlainOnly: true })
  getRemoteId(){
    return this.dId;
  }

  @BeforeInsert()
  setDates(){
    if(!isDefined(this.updatedAt)){
      this.updatedAt = Math.round(Date.now()/1000);
    }
    if(!isDefined(this.createdAt)){
      this.createdAt = Math.round(Date.now()/1000);
    }
  }

  @BeforeInsert()
  setUuid(){
    if (!isDefined(this.uuid)) {
      this.uuid = uuidv4();
    }
  }

  @BeforeUpdate()
  setUpdated(){
    this.updatedAt = Math.round(Date.now()/1000);
  }



}
